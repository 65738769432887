import axios from "axios";
/**
 * @typedef {Object} EventPromiseObject
 * @property {number} id
 * @property {string} nome
 * @property {number} agenciaId
 */

/**
 * * O retorno de uma pesquisa que retorna uma lista de eventos ou uma lista vazia.
 * @typedef {Promise<EventPromiseObject[]> | Promise<any>} SearchReturn
 */

export default {
  /**
   * Retorna uma lista de eventos
   * @returns {Promise} {data: any[]}
   */
  getList() {
    return axios.get("/v1/evento");
  },
  /**
   * Cria um novo evento
   * @typedef {Object} evento
   * @property {number} id
   * @property {string} nome
   * @property {number} agenciaId
   * @returns {Promise}
   * observação: o nome do evento deve ser unico e usuario deve ser agencia
   */
  createEvent(evento) {
    return axios.post("/v1/evento", evento);
  },
  /**
   * function to search an event
   * @param {string} searchText
   * @returns {SearchReturn} - O resultado da pesquisa.
   */
  searchEvent(searchText) {
    return axios.post("/v1/evento/search", { texto: searchText });
  }
};
