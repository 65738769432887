import axios from "axios";

export default {
  getPacotes() {
    return axios.get("/v1/pacote");
  },
  getPacoteId(id) {
    return axios.get(`/v1/pacote/${id}`);
  },
  setPacote(pacote) {
    return axios.post(`/v1/pacote`, { ...pacote });
  },
  definePacoteParaTurma(pacoteID, turmaID) {
    return axios.post(`/v1/turma/define-pacote/${turmaID}/${pacoteID}`);
  },
  desativaPacote(pacoteId) {
    return axios.post(`/v1/pacote/${pacoteId}/desativar`);
  },
  ativarPacote(pacoteId) {
    return axios.post(`/v1/pacote/${pacoteId}/ativar`);
  }
};
