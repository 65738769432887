export const rolesParticipante = {
  COMISSAO: "COMISSAO",
  ADMIN: "ADMIN",
  PARTICIPANTE: "PARTICIPANTE"
};

export const rolesName = {
  COMISSAO: "Comissão",
  ADMIN: "Administrador",
  PARTICIPANTE: "Participante"
};

export function getListRoles(searchMode = false) {
  let list = [
    { text: rolesName.PARTICIPANTE, id: rolesParticipante.PARTICIPANTE },
    { text: rolesName.ADMIN, id: rolesParticipante.ADMIN },
    { text: rolesName.COMISSAO, id: rolesParticipante.COMISSAO }
  ];
  if (searchMode === true) {
    list.push({ name: "Todos", id: null });
  }
  return list;
}
