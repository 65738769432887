export default {
  /*SET_AUTH(state, payload) {
    if (payload.status) {
      state.auth.tokenType = payload.tokenType;
      state.auth.accessToken = payload.accessToken;
    }
    state.auth.status = payload.status;
  },*/
  setUser(state, payload) {
    state.user = payload;
  },
  setLoader(state, payload) {
    state.dashLoader = payload;
  },
  setRolesManager(state, payload) {
    state.rolesManager = payload;
  },
  setDataTemp(state, payload) {
    state.dataTemp = payload;
  }
};
