import axios from "axios";

export default {
  getBoletos(pageSize, pageNo, sortBy, filtros) {
    return axios.post("/v1/boleto/pagination", {
      pageSize,
      pageNo,
      sortBy,
      filtros: {
        boletoPago: filtros.boletoPago,
        id: null,
        vencimentoAte: filtros.vencimentoAte,
        vencimentoDe: filtros.vencimentoDe,
        nomeAluno: filtros.nomeAluno,
        nomeTurma: filtros.nomeTurma,
        turmaId: filtros.turmaId,
        usuarioId: filtros.usuarioId
      }
    });
  }
};
