<template>
  <div>
    <vReturnToolbar @close="close()" />
    <v-card class="pa-4 mx-2 mx-sm-4 lowShadow">
      <vTitulo>
        Extrato FCZ por Aluno
      </vTitulo>
      <v-row>
        <v-col cols="12" sm="12" class="text-sm-left text-center">
          <v-row class="font-weight-medium">
            <v-col cols="12" sm="12">
              <v-select
                v-model="concursoId"
                :items="concursos"
                label="Concurso *"
                item-text="nome"
                item-value="id"
                return-object
                hide-details
                required
                :loading="loadingConcurso"
                @change="onChangeConcurso"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="font-weight-medium">
            <v-col cols="12" sm="6">
              <div
                class="radioCustom"
                :class="{ ativo: tipoRel == 'PDF' }"
                @click="tipoRel = 'PDF'"
              >
                <input
                  v-model="tipoRel"
                  class="mr-3"
                  type="radio"
                  value="PDF"
                />
                PDF
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <div
                class="radioCustom"
                :class="{ ativo: tipoRel == 'XLS' }"
                @click="tipoRel = 'XLS'"
              >
                <input
                  v-model="tipoRel"
                  class="mr-3"
                  type="radio"
                  value="XLS"
                />
                EXCEL
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <div
            style="justify-items: center; align-items: center; text-align: right;"
          >
            <v-btn
              :loading="loading"
              color="primary"
              depressed
              @click="RequestContabilidade()"
            >
              ENVIAR
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import serviceRelatorio from "@/services/relatorios";
import serviceConcurso from "@/services/concurso";

export default {
  data() {
    return {
      tipoRel: "PDF",
      concursoId: null,
      loading: false,
      loadingConcurso: false,
      concursos: []
    };
  },
  beforeMount() {
    this.loadingConcurso = true;
    serviceConcurso
      .getConcursos()
      .then(response => {
        this.concursos = response.data;
      })
      .finally(() => {
        this.loadingConcurso = false;
      });
  },
  methods: {
    close() {
      this.$emit("close");
    },
    onChangeConcurso(item) {
      this.concursoId = item.id;
    },
    RequestContabilidade() {
      this.loading = false;
      serviceRelatorio
        .extratoFCZporAluno(this.tipoRel, this.concursoId)
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            response.headers["content-disposition"].split(";")[1].split("=")[1]
          );
          document.body.appendChild(link);
          link.click();
          this.$root.showInfo("Relatório gerado com sucesso!");
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          this.$root.showError(err.data);
          console.error(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.containID {
  font-size: 15px;
  font-weight: 400;
  padding: 5px 10px;
  background-color: rgb(200, 200, 200, 0.5);
  display: inline-block;
  border-radius: 6px;
}
.radioCustom {
  //teste
  cursor: pointer;
  padding: 6px 12px;
  border: 1px solid rgba(150, 150, 150, 0.3);
  border-radius: 5px;
  margin: 0 2px;
  &.ativo {
    border: 1px solid rgb(10, 85, 170);
  }
}
</style>
