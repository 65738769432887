<template>
  <div>
    <div>
      <vTitulo>Bilhetes FCZ</vTitulo>
    </div>
    <div v-if="getPermissaoAdmin" class="pa-4 text-right">
      <v-btn color="primary" depressed @click="abrirModalDoacao()">Doar</v-btn>
    </div>
    <div class="pa-4">
      <v-card
        class="pa-4 white elevation-1 rounded-md mx-auto"
        style="width: 100%"
      >
        <!-- :headers="headers"
        :items="turmas"
        :options.sync="options"
        :server-items-length="totalTurmas"
        :loading="loading"
        class="lowShadow"
        loading-text="Carregando..."
        :footer-props="{
          itemsPerPageOptions: [20, 50, 100]
        }" -->
        <v-data-table
          :headers="headers"
          :items="listaBilhetes"
          :loading="loading"
          :options.sync="options"
          :server-items-length="totalElements"
          loading-text="Carregando..."
          :footer-props="{
            itemsPerPageOptions: [20, 50, 100]
          }"
          class="elevation-0"
        >
          <template v-slot:no-data>
            Não existe bilhetes registrados
          </template>
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="(item, index) in items" :key="index">
                <td>{{ convertDate(item.dataCompra) }}</td>
                <td>{{ item.cpfComprador }}</td>
                <td>{{ item.nomeComprador }}</td>
                <td>{{ item.qtdBilhete }}</td>
                <td>{{ convertCurrency(item.valor) }}</td>
                <td>{{ item.doacao }}</td>
                <td>
                  <v-btn
                    small
                    color="primary"
                    depressed
                    text
                    @click="abrirModalVerBilhetes(item)"
                  >
                    <v-icon>mdi-text-box-multiple</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <!-- DOACAO SOMENTE PARA ADMIN -->
    <vModalDoacao
      v-if="modalDoacao"
      :concurso="concursoAtual"
      @close="closeModalDoacao"
    />
    <vModalVerBilhetes
      v-if="modalVerBilhetes"
      :compra="compraPayload"
      @close="closeModalVerBilhetes"
    />
  </div>
</template>

<script>
import ServiceVendaBilhetes from "@/services/vendabilhete";
import vModalDoacao from "./vModalDoacao.vue";
import moment from "moment";
import vModalVerBilhetes from "./vModalVerBilhetes.vue";

export default {
  components: {
    vModalDoacao: vModalDoacao,
    vModalVerBilhetes: vModalVerBilhetes
  },
  data() {
    return {
      page: 1,
      maxPage: 10,
      totalElements: 0,
      modalDoacao: false,
      modalVerBilhetes: false,
      listaBilhetes: [],
      compraPayload: {},
      loading: false,
      concursoAtual: null,
      options: {},
      headers: [
        {
          text: "Data de compra",
          align: "start",
          value: "dataCompra"
        },
        { text: "CPF", value: "cpfComprador" },
        { text: "Beneficiário", value: "nomeComprador" },
        { text: "Qtd. Bilhetes", value: "qtdBilhete" },
        { text: "Valor", value: "valor" },
        { text: "Doação", value: "doacao" },
        { text: "Opções", value: "" }
      ]
    };
  },
  computed: {
    getPermissaoAdmin() {
      return this.$store.getters["gestao/getUser"].role === "ADMIN";
    }
  },
  watch: {
    options: {
      handler(d) {
        this.page = d.page;
        this.maxPage = d.itemsPerPage;
        this.buscarComprasBilhetes();
      },
      deep: true
    }
  },
  mounted() {
    this.buscarComprasBilhetes();
  },
  methods: {
    abrirModalVerBilhetes(compra) {
      this.compraPayload = compra;
      this.modalVerBilhetes = true;
    },
    closeModalVerBilhetes() {
      this.modalVerBilhetes = false;
      this.compraPayload = null;
    },
    convertDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    convertCurrency(value) {
      return value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL"
      });
    },
    async buscarComprasBilhetes() {
      this.loading = true;
      const filtros = {
        cpfComprador: null,
        nomeComprador: null
      };
      ServiceVendaBilhetes.pagination(filtros, this.page - 1, this.maxPage)
        .then(resp => {
          this.listaBilhetes = resp.data.content;
          this.totalElements = resp.data.totalElements;
        })
        .catch(err => {
          this.$root.showError(err.data.message || err.data || err);
          this.listaBilhetes = [];
          this.totalElements = 0;
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    abrirModalDoacao() {
      this.modalDoacao = true;
    },
    closeModalDoacao() {
      this.modalDoacao = false;
    }
  }
};
</script>

<style></style>
