function createBase() {
  if (process.env.NODE_ENV === "development") {
    return {
      URL_BASE: "http://localhost:7003",
      URL_APP: "http://localhost:8081",
      URL_CUSTO_ZERO: "http://localhost:8082"
    };
  } else {
    return {
      URL_BASE: "https://backend.appdaturma.com.br",
      URL_APP: "https://app.appdaturma.com.br",
      URL_CUSTO_ZERO: "https://app.formaturacustozero.com.br"
    };
  }
}

export const { URL_BASE, URL_APP, URL_CUSTO_ZERO } = createBase();
