<template>
  <div>
    <div>
      <iframe
        no-cache
        class="screenmax"
        title="metabase"
        :src="iframeUrl"
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      iframeUrl: null
    };
  },
  mounted() {
    this.iframeUrl = null;
    this.configMETABASE();
  },
  methods: {
    getDashboardId() {
      let userRole = this.$store.getters["gestao/getUser"].role;
      if (userRole == "AGENCIA") {
        return 15;
      } else {
        return 11;
      }
    },
    getURLMetabase(metasite, token) {
      return (
        metasite + "/embed/dashboard/" + token + "#bordered=true&titled=true"
      );
    },
    getParamsMetabase() {
      let user = this.$store.getters["gestao/getUser"];
      if (user.role == "AGENCIA") {
        return {
          agencia_id: user.agenciaId
        };
      } else {
        return {};
      }
    },
    configMETABASE() {
      var jwt = require("jsonwebtoken");

      var METABASE_SITE_URL = "https://metabase.appdaturma.com.br";
      var METABASE_SECRET_KEY =
        "8cde899bc34c99ab742f6ffdb39d796171faadae91aa704a62eb9df84000a791";

      var payload = {
        resource: { dashboard: this.getDashboardId() },
        params: this.getParamsMetabase(),
        exp: Math.round(Date.now() / 1000) + 10 * 60 // 10 minute expiration
      };

      var token = jwt.sign(payload, METABASE_SECRET_KEY);

      let mountURL = this.getURLMetabase(METABASE_SITE_URL, token);
      this.iframeUrl = mountURL;
    }
  }
};
</script>

<style lang="scss" scoped>
.screenmax {
  display: block;
  width: 100%;
  border: none;
  margin: 0px auto;
  height: 100vh;
  background-color: white;
}
</style>
