export default {
  getDataTemp(state) {
    return state.dataTemp;
  },
  getLoading(state) {
    return state.dashLoader;
  },
  getRolesManager(state) {
    return state.rolesManager;
  },
  getUser(state) {
    return state.user;
  },
  getUserRole(state) {
    return state.user.role;
  },
  getUserFoto(state) {
    return state.user.fotoUrl;
  },
  getAgencia(state) {
    return {
      agenciaNome: state.user.agenciaNome,
      agenciaId: state.user.agenciaId
    };
  },
  GET_AUTH_STATUS(state) {
    return state.auth.status;
  },
  GET_TOKENS(state) {
    return {
      tokenType: state.auth.tokenType,
      accessToken: state.auth.accessToken
    };
  }
};
