<template>
  <div>
    <v-dialog
      v-model="getDialogNovo"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="700px"
      transition="dialog-transition"
    >
      <v-card class="mx-auto pa-5 pt-3">
        <div class="d-grid grid-col-1-auto pa-2 pr-0">
          <div class="titleHeaderModal">
            Nova turma
          </div>
          <div>
            <v-btn icon @click="close">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </div>
        <vStepCustom :count="step" :count-max="stepMax" />
        <div v-if="pagina == null" class="pa-2 text-center">
          <div class="d-inline-block mx-auto">
            <v-select
              v-model="selectedMount"
              :items="listaCategorias"
              item-text="name"
              item-value="code"
              label="Tipo de cadastro"
            ></v-select>
          </div>
          <div class="text-center pa-4">
            <v-btn color="primary" @click="avancar()">Próxima etapa</v-btn>
          </div>
        </div>
        <div class="contain">
          <vCadastroFormatura
            v-if="pagina == getCode.FORMATURA"
            :step="step"
            @setStep="updateStep"
            @setMaxStep="updateMaxStep"
            @close="close"
            @concluido="openCongratulationsAndCopy"
          />
          <vCadastroAssociacao
            v-if="pagina == getCode.ASSOCIACAO"
            :step="step"
            @setStep="updateStep"
            @setMaxStep="updateMaxStep"
            @close="close"
            @concluido="openCongratulationsAndCopy"
          />
          <vCadastroCasamento
            v-if="pagina == getCode.CASAMENTO"
            :step="step"
            @setStep="updateStep"
            @setMaxStep="updateMaxStep"
            @close="close"
            @concluido="openCongratulationsAndCopy"
          />
          <vCadastroOutro
            v-if="pagina == getCode.OUTRO"
            :step="step"
            @setStep="updateStep"
            @setMaxStep="updateMaxStep"
            @close="close"
            @concluido="openCongratulationsAndCopy"
          />
          <vCadastroCorporativo
            v-if="pagina == getCode.CORPORATIVO"
            :step="step"
            @setStep="updateStep"
            @setMaxStep="updateMaxStep"
            @close="close"
            @concluido="openCongratulationsAndCopy"
          />
        </div>
      </v-card>
    </v-dialog>
    <vConcluido
      v-if="dialogConclusao"
      :code="code"
      @close="closeCongratulations"
    />
  </div>
</template>

<script>
import vStepCustom from "@/components/customStep.vue";
import codeID, { getArray } from "@/constantes/EventosType";
import vConcluido from "./concluido.vue";
import vCadastroFormatura from "./formatura.vue";
import vCadastroAssociacao from "./associacao.vue";
import vCadastroCasamento from "./casamento.vue";
import vCadastroOutro from "./outro.vue";
import vCadastroCorporativo from "./corporativo.vue";

export default {
  components: {
    vStepCustom,
    vConcluido,
    vCadastroFormatura,
    vCadastroAssociacao,
    vCadastroCasamento,
    vCadastroOutro,
    vCadastroCorporativo
  },
  data() {
    return {
      descricaoCustom: "",
      code: null,
      dialogConclusao: false,
      step: 1,
      stepMax: 3,
      pagina: null,
      selectedMount: "",
      listaCategorias: []
    };
  },
  computed: {
    getDialogNovo() {
      return true;
    },
    getCode() {
      return codeID;
    }
  },
  beforeDestroy() {
    this.resetConfig();
  },
  beforeMount() {
    this.resetConfig();
  },
  mounted() {
    this.resetConfig();
  },
  methods: {
    resetConfig() {
      this.listaCategorias = getArray;
      this.step = 1;
      this.stepMax = 3;
      this.pagina = null;
      this.selectedMount = "";
      this.dialogConclusao = false;
      this.descricaoCustom = "";
      this.code = null;
    },
    openCongratulationsAndCopy(codigo) {
      this.resetConfig();
      this.step = 1;
      this.dialogConclusao = true;
      this.code = codigo;
    },
    closeCongratulations() {
      this.dialogConclusao = false;
      this.$emit("update");
      this.$emit("close");
    },

    updateMaxStep(qtd) {
      this.stepMax = qtd;
    },
    updateStep(qtd) {
      this.step = qtd;
    },
    avancar() {
      this.pagina = this.selectedMount;
    },
    close() {
      this.step = 1;
      this.stepMax = 3;
      this.pagina = null;
      this.resetConfig();
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.titleHeaderModal {
  font-size: 1.2rem;
  font-weight: 400;
  font-family: "Roboto";
  color: rgb(30, 30, 30);
}
</style>
