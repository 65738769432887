<template>
  <div>
    <!-- <div v-if="tipo === 'FORNECEDOR'" class="chiptype fornecedor">
      Fornecedor
    </div>
    <div v-if="tipo === 'TRANSFERENCIA_INTERNA'" class="chiptype transferencia">
      Transferência
    </div> -->
    <div class="chiptype" :class="getClass()">{{ getName() }}</div>
  </div>
</template>

<script>
export default {
  props: {
    tipo: {
      type: Object,
      required: true
    }
  },
  methods: {
    getName() {
      if (this.tipo === "FORNECEDOR") {
        return "Fornecedor";
      } else if (this.tipo === "TRANSFERENCIA_INTERNA") {
        return "Transferência";
      } else if (this.tipo === "SAQUE_FCZ") {
        return "Saque FCZ";
      } else {
        return this.tipo;
      }
    },
    getClass() {
      if (this.tipo === "FORNECEDOR") {
        return "fornecedor";
      } else if (this.tipo === "TRANSFERENCIA_INTERNA") {
        return "transferencia";
      } else if (this.tipo === "SAQUE_FCZ") {
        return "saque";
      } else {
        console.error("Tipo inválido", this.tipo);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.chiptype {
  padding: 4px 8px;
  font-size: 12px;
  border-radius: 4px;
  margin-top: 2px;
  margin-bottom: 2px;
  text-align: center;
  color: white;
  font-weight: 400;
  letter-spacing: 0.5px;
  border-width: 1px;
  border-style: solid;

  &.fornecedor {
    background-color: rgb(227, 252, 239);
    border-color: rgba(0, 0, 0, 0.2);
    color: rgb(0, 102, 68);
  }

  &.saque {
    background-color: rgb(243, 217, 255);
    border-color: rgba(0, 0, 0, 0.2);
    color: rgba(150, 0, 196, 0.8);
  }

  &.transferencia {
    background-color: rgb(217, 221, 255);
    border-color: rgba(0, 0, 0, 0.2);
    color: rgba(3, 0, 196, 0.8);
  }
}
</style>
