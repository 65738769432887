<template>
  <div>
    <vTitulo>
      Boletos
    </vTitulo>

    <v-card class="pa-3 mx-3 lowShadow">
      <div class="pa-2">
        <div class="pb-2 text-h6 font-weight-light">
          Filtros para busca
        </div>
        <v-row>
          <v-col sm="5" cols="12">
            <v-text-field
              v-model="filtros.vencimentoDe"
              type="date"
              label="Data de vencimento inicial"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col sm="5" cols="8">
            <v-text-field
              v-model="filtros.vencimentoAte"
              type="date"
              name="name"
              label="Data de vencimento final"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col sm="2" cols="4" class="text-center">
            <v-checkbox
              v-model="filtros.boletoPago"
              label="Boleto Pago"
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row class="text-center mt-n5 justify-center align-center">
          <v-col cols="12" sm="6">
            <div>
              <v-text-field
                v-if="!toggleUserForId"
                v-model="filtros.nomeAluno"
                label="Nome do usuário"
                hide-details
              ></v-text-field>
              <v-text-field
                v-if="toggleUserForId"
                v-model="filtros.usuarioId"
                label="ID do usuario"
                placeholder="Apenas o número"
                hide-details
              ></v-text-field>
            </div>
            <div class="py-2 text-left">
              <v-btn
                v-if="!toggleUserForId"
                depressed
                color="secondary"
                small
                @click="setFormUserToId(true)"
                >Usuário por ID</v-btn
              >
              <v-btn
                v-if="toggleUserForId"
                depressed
                color="secondary"
                small
                @click="setFormUserToId(false)"
                >Usuário por Nome</v-btn
              >
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div>
              <v-text-field
                v-if="!toggleEventForId"
                v-model="filtros.turmaId"
                label="Nome do Evento"
                hide-details
              ></v-text-field>
              <v-text-field
                v-if="toggleEventForId"
                v-model="filtros.turmaId"
                label="ID do evento"
                placeholder="Apenas o número"
                hide-details
              ></v-text-field>
            </div>
            <div class="py-2 text-left">
              <v-btn
                v-if="!toggleEventForId"
                depressed
                color="secondary"
                small
                @click="setFormEventToId(true)"
                >Evento por ID</v-btn
              >
              <v-btn
                v-if="toggleEventForId"
                depressed
                color="secondary"
                small
                @click="setFormEventToId(false)"
                >Evento por Nome</v-btn
              >
            </div>
          </v-col>
        </v-row>
        <div class="text-center text-sm-right py-3">
          <v-btn color="primary" @click="paginar()">BUSCAR</v-btn>
        </div>
      </div>
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="itemsPerPage"
        :options.sync="options"
        :server-items-length="totalBoletos"
        :loading="loading"
        class="elevation-0 d-none d-sm-block"
        :footer-props="{
          itemsPerPageOptions: [100, 300, 500]
        }"
      >
        <template v-slot:no-data>
          Não existe faturas registradas
        </template>
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="(item, index) in items" :key="index">
              <td>USR-{{ item.usuarioId }}</td>
              <td>EVT-{{ item.turmaId }}</td>
              <td>{{ item.valor | currency }}</td>
              <td>{{ item.dataVencimento | filtraData }}</td>
              <td>{{ item.dataPagamento | filtraData }}</td>
              <td>{{ item.status | filtraStatus }}</td>
              <td>
                <v-avatar
                  class="mx-1 clique"
                  size="35"
                  color="primary"
                  @click="abrirBoleto(item.urlAcesso)"
                >
                  <v-icon color="white" size="20">
                    mdi-file-document
                  </v-icon>
                </v-avatar>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import serviceBoleto from "@/services/boletos";
import { URL_BASE } from "@/constantes/base.js";
import moment from "moment";

export default {
  filters: {
    filtraData(dt) {
      if (dt) {
        return moment(dt).format("DD/MM/YYYY");
      } else {
        return "S/REGISTRO";
      }
    },
    filtraStatus(status) {
      if (status == "NAO_PAGO") {
        return "Não pago";
      } else if (status == "PAGO") {
        return "Pago";
      } else if (status == "CANCELADO") {
        return "Cancelado";
      } else {
        return "INDEFINIDO";
      }
    }
  },
  data() {
    return {
      page: 30,
      itemsPerPage: 100,
      pageCount: 0,
      totalBoletos: 0,
      options: {
        itemsPerPage: 100
      },
      loading: false,
      toggleUserForId: false,
      toggleEventForId: false,
      filtros: {
        vencimentoAte: "",
        vencimentoDe: "",
        boletoPago: false,
        nomeAluno: "",
        nomeTurma: "",
        turmaId: "",
        usuarioId: ""
      },
      items: [],
      headers: [
        {
          text: "Participante ID",
          align: "start",
          value: "usuarioId"
        },
        { text: "Evento ID", value: "turmaId" },
        { text: "Valor título", value: "valor" },
        { text: "Data Vencimento", value: "dataVencimento" },
        { text: "Data Pagamento", value: "dataPagamento" },
        { text: "Situação", value: "status" },
        { text: "Ações", value: "urlAcesso" }
      ]
    };
  },
  watch: {
    options: {
      handler() {
        this.paginar();
      },
      deep: true
    }
  },
  mounted() {
    this.inicializa();
  },
  methods: {
    setFormUserToId(toggle) {
      this.toggleUserForId = toggle;
      this.filtros.nomeAluno = "";
      this.filtros.usuarioId = "";
      if (toggle == true) this.filtros.usuarioId = "";
    },
    setFormEventToId(toggle) {
      this.toggleEventForId = toggle;
      this.filtros.nomeTurma = "";
      this.filtros.turmaId = "";
      if (toggle == true) this.filtros.turmaId = "";
    },
    inicializa() {
      this.filtros.vencimentoAte = this.getMomentHoje();
      this.filtros.vencimentoDe = this.getMomentHoje();
      this.paginar();
    },
    getMomentHoje() {
      return moment().format("YYYY-MM-DD");
    },
    abrirBoleto(url, index) {
      let url_base = URL_BASE + "/v1/public/boleto/" + url;
      window.open(url_base, `boletoAberto_ind${index}`);
    },
    tratamentos() {
      this.filtros.turmaId = this.filtros.turmaId
        ? Number(this.filtros.turmaId)
        : null;
      this.filtros.usuarioId = this.filtros.usuarioId
        ? Number(this.filtros.usuarioId)
        : null;
    },
    paginar() {
      this.loading = true;
      this.tratamentos();
      const { page, itemsPerPage } = this.options;
      serviceBoleto
        .getBoletos(
          itemsPerPage,
          page ? page - 1 : 0,
          "data_vencimento",
          this.filtros
        )
        .then(response => {
          this.items = response.data.content;
          this.totalBoletos = response.data.totalElements;
        })
        .catch(err => {
          this.$root.showError(err.data.message);
          console.log(err.data);
        })
        .finally(() => {
          //this.loadingPesquisar = false;
          this.loading = false;
        });
    }
  }
};
</script>
