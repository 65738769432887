<template>
  <div>
    <vTitulo>Atendimento</vTitulo>
    <div v-if="mount == 'rooms'">
      <div class="ma-2">
        <v-card class="pa-2 transparent elevation-0">
          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="filtros.titulo"
                label="Titulo"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-select
                v-model="filtros.status"
                :items="statusLista"
                outlined
                item-value="code"
                item-text="text"
                :value="null"
                label="Status"
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-select
                v-model="filtros.setor"
                :items="listaSetores"
                outlined
                item-value="code"
                item-text="text"
                :value="null"
                label="Setor"
              ></v-select>
            </v-col>
            <v-col cols="2">
              <div class="text-center">
                <v-btn color="primary" x-large @click="paginar()">BUSCAR</v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </div>
      <div class="mx-2 mb-5">
        <div class="text-subtitle-2 pa-2">
          Guias
        </div>
        <v-row>
          <v-col
            v-for="(staticItem, index) in statusListStaticHtml"
            :key="index"
            cols="3"
          >
            <v-card class="pa-4 lowShadow">
              <div class="d-grid grid-col-auto-1">
                <v-icon
                  size="20"
                  class="mx-2"
                  :color="getColorStatus(staticItem.code)"
                  >mdi-circle</v-icon
                >
                <div class="text-body-1">{{ staticItem.text }}</div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div class="ma-2">
        <v-data-table
          :headers="headers"
          :items="items"
          class="lowShadow white"
          :options.sync="options"
          :items-per-page="pageMax"
          :server-items-length="totalElements"
          :footer-props="{
            itemsPerPageOptions: [20, 50, 100]
          }"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="(item, index) in items"
                :key="index"
                class="white my-1 elevation-0"
              >
                <td>
                  <div class="d-grid grid-col-1-1">
                    <div class="mr-3">
                      <v-avatar size="20" :title="statusNominal(item.status)">
                        <v-icon size="20" :color="getColorStatus(item.status)"
                          >mdi-circle</v-icon
                        >
                      </v-avatar>
                    </div>
                    <div v-if="item.novaMsg" id="novaMensagem">
                      <v-icon color="indigo">mdi-message</v-icon>
                    </div>
                    <div v-if="!item.novaMsg" id="novaMensagem">
                      <v-icon color="grey lighten-1">mdi-message</v-icon>
                    </div>
                  </div>
                </td>
                <td class="text-center">
                  {{ item.usuarioId }}
                </td>
                <td>{{ item.setor }}</td>
                <td>{{ item.titulo }}</td>
                <td>
                  <v-btn color="primary" @click="abrirChatRoom(item.id)">
                    Abrir
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </div>
    </div>
    <vChatRoom
      v-if="mount == 'chatroom' && selectedRoom"
      :uid="selectedRoom"
      @close="closeChatRoom"
    />
  </div>
</template>

<script>
import serviceAtendimento from "@/services/atendimento";
import vChatRoom from "./chatroom";

export default {
  components: {
    vChatRoom
  },
  data() {
    return {
      mount: "rooms",
      headers: [
        { text: "", value: "" },
        { text: "Usuário ID", value: "usuarioId", align: "center" },
        { text: "Setor", value: "setor" },
        { text: "Título", value: "titulo" },
        { text: "Opções", value: "" }
      ],
      items: [],
      page: 1,
      pageMax: 20,
      totalElements: 0,
      selectedRoom: null,
      filtros: {
        setor: null,
        status: null,
        titulo: ""
      },
      options: {},
      listaSetores: [
        { code: null, text: "Todos" },
        { code: "Financeiro", text: "Financeiro" },
        { code: "Ouvidoria", text: "Ouvidoria" },
        { code: "Suporte", text: "Suporte" },
        { code: "Técnico", text: "Técnico" }
      ],
      statusLista: [
        { code: null, text: "Todos" },
        { code: "EM_ANDAMENTO", text: "Em andamento" },
        { code: "CONCLUIDO", text: "Concluído" },
        { code: "CANCELADO", text: "Cancelado" },
        { code: "PENDENTE", text: "Pendente" }
      ],
      statusListStaticHtml: [
        { code: "EM_ANDAMENTO", text: "Em andamento" },
        { code: "PENDENTE", text: "Pendente" },
        { code: "CONCLUIDO", text: "Concluído" },
        { code: "CANCELADO", text: "Cancelado" }
      ]
    };
  },
  watch: {
    options: {
      handler(d) {
        this.page = d.page;
        this.pageMax = d.itemsPerPage;
        this.paginar();
      },
      deep: true
    }
  },
  mounted() {
    this.paginar();
  },
  methods: {
    closeChatRoom() {
      this.selectedRoom = "";
      this.mount = "rooms";
    },
    setMount(named) {
      this.mount = named;
    },
    abrirChatRoom(uid) {
      this.selectedRoom = uid;
      this.mount = "chatroom";
    },
    statusNominal(val) {
      if (val == "EM_ANDAMENTO") {
        return "Em andamento";
      } else if (val == "CONCLUIDO") {
        return "Concluído";
      } else if (val == "CANCELADO") {
        return "Cancelado";
      } else if (val == "PENDENTE") {
        return "Pendente";
      } else {
        return val;
      }
    },
    getColorStatus(val) {
      if (val == "EM_ANDAMENTO") {
        return "purple darken-2";
      } else if (val == "CONCLUIDO") {
        return "green darken-2";
      } else if (val == "CANCELADO") {
        return "red darken-4";
      } else if (val == "PENDENTE") {
        return "amber accent-4";
      } else {
        return "rgb(0,0,0,1)";
      }
    },
    paginar() {
      serviceAtendimento
        .getPagination(this.page - 1, this.pageMax, this.filtros)
        .then(resp => {
          console.log(resp.data.body);
          this.totalElements = resp.data.body.totalElements;
          this.items = resp.data.body.content;
        });
    }
  }
};
</script>

<style></style>
