export const tiposDespesas = {
  MENSALIDADE: "MENSALIDADE",
  TARIFA_POR_TRANSACAO: "TARIFA_POR_TRANSACAO",
  SOLICITACAO_PAGAMENTO: "SOLICITACAO_PAGAMENTO",
  TARIFA_PAGAMENTO_FORNECEDOR: "TARIFA_PAGAMENTO_FORNECEDOR",
  TRANSFERENCIA_INTERNA: "TRANSFERENCIA_INTERNA",
  CONTAS_A_RECEBER_SAQUE: "CONTAS_A_RECEBER_SAQUE",
  ASSESSORIA: "ASSESSORIA"
};

export const getNames = {
  MENSALIDADE: "Mensalidade",
  TARIFA_POR_TRANSACAO: "Tarifa por transação",
  SOLICITACAO_PAGAMENTO: "Solicitação de pagamento",
  TARIFA_PAGAMENTO_FORNECEDOR: "Tarifa de Pagamento para Fornecedores",
  TRANSFERENCIA_INTERNA: "Transferência interna",
  CONTAS_A_RECEBER_SAQUE: "Contas a receber e saques",
  ASSESSORIA: "Assessoria"
};

export const ListaTiposDespesas = [
  { text: getNames.MENSALIDADE, value: tiposDespesas.MENSALIDADE },
  {
    text: getNames.TARIFA_POR_TRANSACAO,
    value: tiposDespesas.TARIFA_POR_TRANSACAO
  },
  {
    text: getNames.SOLICITACAO_PAGAMENTO,
    value: tiposDespesas.SOLICITACAO_PAGAMENTO
  },
  {
    text: getNames.TARIFA_PAGAMENTO_FORNECEDOR,
    value: tiposDespesas.TARIFA_PAGAMENTO_FORNECEDOR
  },
  {
    text: getNames.TRANSFERENCIA_INTERNA,
    value: tiposDespesas.TRANSFERENCIA_INTERNA
  },
  {
    text: getNames.CONTAS_A_RECEBER_SAQUE,
    value: tiposDespesas.CONTAS_A_RECEBER_SAQUE
  },
  { text: getNames.ASSESSORIA, value: tiposDespesas.ASSESSORIA }
];
