import Vue from "vue";
import Vuex from "vuex";
import StoreGestao from "./gestao/index";
//import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    gestao: StoreGestao
  }
  //plugins: [createPersistedState()]
});
