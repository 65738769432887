import axios from "axios";

export default {
  getInstituicoes() {
    return axios.get("/v1/instituicao");
  },
  getInstituicaoId(id) {
    return axios.get(`/v1/instituicao/${id}`);
  },
  getPagination(payload) {
    return axios.post("/v1/instituicao/pagination", {
      descending: false,
      direction: "ASC", //ASC
      filtros: {
        id: null,
        nome: payload.nome
      },
      pageNo: payload.page,
      pageSize: payload.pageSize,
      sortBy: "nome"
    });
  },
  setInstituicao(payload) {
    return axios.post("/v1/instituicao", {
      id: payload.id,
      nome: payload.nome,
      sigla: payload.sigla
    });
  },
  buscaInstituicaoPorNome(texto) {
    return axios.post("/v1/instituicao/search", texto);
  }
};
