export default {
  menu: {
    toggle: false
  },
  modal: {
    tipo: "info",
    toggle: false,
    mensagem: ""
  },
  auth: {
    status: false,
    tokenType: null,
    accessToken: null
  },
  dashLoader: true,
  rolesManager: [],
  user: {
    nome: "",
    id: "",
    email: "",
    role: "",
    senha: "",
    fotoUrl: ""
  },
  dataTemp: null
};
