import axios from "axios";

function tratamentoDeNull(value) {
  if (value) return value;
  else return null;
}

export default {
  getParticipantes(page, pageSize, filtros) {
    return axios.post("/v1/usuario/pagination-alunos", {
      descending: true,
      filtros: {
        alunoStatus: filtros.alunoStatus,
        conviteStatus: filtros.conviteStatus,
        email: filtros.email,
        nome: filtros.nome,
        alunoId: filtros.alunoId,
        nomeTurma: filtros.nomeTurma,
        permissao: filtros.permissao,
        cpf: filtros.cpf,
        rg: filtros.rg,
        telefone: filtros.telefone,
        pai: filtros.pai,
        mae: filtros.mae,
        endereco: {
          cidade: tratamentoDeNull(filtros.endereco.cidade),
          logradouro: tratamentoDeNull(filtros.endereco.logradouro),
          uf: tratamentoDeNull(filtros.endereco.uf),
          bairro: tratamentoDeNull(filtros.endereco.bairro),
          cep: tratamentoDeNull(filtros.endereco.cep)
        }
      },
      pageNo: page,
      pageSize: pageSize,
      sortBy: "string"
    });
  }
};
