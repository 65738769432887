<template>
  <div class="step">
    <div
      v-for="(item, index) in steps"
      :key="index"
      :class="{ par: item.type == 'par', line: item.type == 'line' }"
    >
      <div
        v-if="item.type == 'par'"
        class="cicle"
        :class="{ ativo: count >= item.indice }"
      >
        <v-icon v-if="count > item.indice" color="white">
          mdi-check
        </v-icon>
        <span v-else> {{ item.indice }} </span>
      </div>
      <div>
        <hr
          v-if="item.type == 'line'"
          :class="{ ativo: count + 1 > index && count !== 1 }"
        />
      </div>
    </div>
  </div>
</template>

<script>
// count >
export default {
  name: "CustomStep",
  props: {
    count: {
      type: Number,
      required: true
    },
    countMax: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      steps: []
    };
  },
  watch: {
    countMax: function() {
      this.gerarArray(1);
    }
  },
  beforeMount() {
    this.gerarArray(1);
  },
  methods: {
    gerarArray(min) {
      let max = this.countMax;
      let countMe = 0;
      let countMax = max + max;
      this.steps = [];
      for (let i = min; i < countMax; i++) {
        let its = i % 2 == 0 ? true : false;
        if (!its) {
          countMe++;
          this.steps.push({
            type: "par",
            indice: countMe
          });
        } else {
          this.steps.push({
            type: "line",
            indice: undefined
          });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.step {
  padding: 10px;
  display: flex;
  align-content: center;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  width: 90%;
  margin: 0 auto;
  /*.stepContain {
    flex: 1;
    display: flex;
    justify-content: space-between;
    &.singleComp {
      text-align: left;
      justify-content: left;
      justify-items: left;
      justify-self: left;
      .par {
        flex: 2;
        text-align: left;
      }
      .line {
        flex: 0;
        display: none;
      }
    }
  }*/
  .par {
    flex: 1;
    text-align: center;
    padding: 10px;
    .cicle {
      display: inline-grid;
      text-align: center;
      align-content: center;
      align-self: center;
      align-items: center;
      vertical-align: center;
      border-radius: 100px;
      width: 32px;
      height: 32px;
      background-color: rgb(255, 255, 255, 1);
      border: 1px solid #37447e;
      color: #37447e;
      font-size: 14px;
      span {
        font-size: initial;
      }
      &.ativo {
        background-color: #37447e;
        span {
          color: white;
        }
      }
    }
  }
  .line {
    padding: 0px;
    flex: 3;
    vertical-align: center;
    align-content: center;
    align-items: center;
    align-self: center;
    hr {
      border: 1px solid rgba(50, 50, 50, 0.1);
      &.ativo {
        border: 1px solid rgba(50, 50, 50, 1);
      }
    }
  }
}
</style>
