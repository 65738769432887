<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <div style="text-align:right;">
          <v-btn white icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
        </div>
      </template>
      <v-list class="pa-0 ma-0 text-body-2">
        <v-list-item class="itemMenu ma-0 pa-0">
          <v-list-item-action class="ma-0 pa-0 ">
            <v-list class="pa-0 ma-0">
              <v-list-item @click="cancelarContrato()">
                Cancelar contrato
              </v-list-item>
              <v-list-item @click="$emit('abrirContribuicoes')">
                Contribuições
              </v-list-item>
              <v-list-item @click="reenviarEmail()">
                Reenviar convite (email)
              </v-list-item>
              <v-list-item
                v-if="getParticipanteRole != getRoleType.COMISSAO"
                @click="setComissao()"
                >Adicionar comissário</v-list-item
              >
              <v-list-item @click="setAdmin()"
                >Adicionar administrador</v-list-item
              >
              <v-list-item
                v-if="getParticipanteRole == getRoleType.COMISSAO"
                @click="removeComissao()"
                >Remover comissário</v-list-item
              >

              <v-list-item @click="desativarAluno()"
                >Desativar usuário</v-list-item
              >

              <v-list-item @click="excluirParticipante()">
                Excluir Participante
              </v-list-item>

              <v-list-item
                v-if="
                  getParticipanteConvite == 'NAO_AUTORIZADO' ||
                    getParticipanteConvite == 'PENDENTE'
                "
                @click="aceitarAluno()"
                >Ativar Participante</v-list-item
              >
            </v-list>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import serviceTurma from "@/services/turma";
import { rolesParticipante } from "@/constantes/ParticipanteRole";

export default {
  props: {
    participante: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      user: null,
      eventoId: null
    };
  },
  computed: {
    getRoleType() {
      return rolesParticipante;
    },
    getTurmaAtiva() {
      return this.participante.turmaId;
    },
    getParticipanteRole() {
      return this.participante.permissaoTurma;
    },
    getParticipanteConvite() {
      return this.participante.conviteStatus;
    }
  },
  beforeMount() {
    this.user = { ...this.participante };
    this.eventoId = this.participante.turmaId;
    if (!this.user.usuarioId || !this.eventoId) {
    }
  },
  methods: {
    reenviarEmail() {
      serviceTurma
        .reenviarConvite(this.user.email)
        .then(resp => {
          this.$root.showInfo("Convite reenviado com sucesso");
          console.log(resp.data);
        })
        .catch(err => {
          console.error(err.data);
          this.$root.showError(err.data);
        });
    },
    excluirParticipante() {
      const userId = this.participante.usuarioId;
      this.$emit("confirmarExclusaoParticipante", userId);
    },
    atualizaParticipantesDaLista() {
      this.$emit("update-users");
    },
    aceitarAluno() {
      const userId = this.participante.usuarioId;
      serviceTurma
        .aceitaAluno(this.getTurmaAtiva, userId)
        .then(() => {
          this.$root.showInfo("Aluno aceito com sucesso.");
          this.atualizaParticipantesDaLista();
        })
        .catch(err => {
          this.$root.showError(err.data);
        });
    },
    desativarAluno() {
      serviceTurma
        .desativaAluno(this.getTurmaAtiva, this.user.usuarioId)
        .then(() => {
          this.$root.showInfo("Aluno bloqueado com sucesso.");
          this.atualizaParticipantesDaLista();
        })
        .catch(err => {
          this.$root.showError(err.data);
        });
    },
    setAdmin() {
      serviceTurma
        .nomearAlunoAdmin(this.getTurmaAtiva, this.user.usuarioId)
        .then(() => {
          this.$root.showInfo("Alteração feita com sucesso");
          this.atualizaParticipantesDaLista();
        })
        .catch(err => {
          this.$root.showError(err.data);
        });
    },
    removeComissao() {
      serviceTurma
        .removerAlunoComissao(this.getTurmaAtiva, this.user.usuarioId)
        .then(() => {
          this.$root.showInfo("Alterado com sucesso");
          this.atualizaParticipantesDaLista();
        })
        .catch(err => {
          this.$root.showError(err.data);
        });
    },
    setComissao() {
      serviceTurma
        .addAlunoComissao(this.getTurmaAtiva, this.user.usuarioId)
        .then(() => {
          this.$root.showInfo("Alteração feita com sucesso");
          this.atualizaParticipantesDaLista();
        })
        .catch(err => {
          this.$root.showError(err.data);
          console.log(err);
        });
    },
    cancelarContrato() {
      serviceTurma
        .cancelarContrato(this.user.usuarioId)
        .then(() => {
          this.$root.showInfo("Contrato cancelado com sucesso");
          this.atualizaParticipantesDaLista();
        })
        .catch(err => {
          this.$root.showError(err.data);
        });
    }
  }
};
</script>

<style></style>
