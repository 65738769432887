<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="850px"
      transition="dialog-transition"
    >
      <v-card class="pa-4 pt-3">
        <div v-if="loadingContent" class="text-center pa-4">
          <div class="text-subtitle-1 grey--text font-weight-normal">
            Carregando...
          </div>
          <v-progress-circular
            size="45"
            indeterminate
            class="ma-5"
          ></v-progress-circular>
        </div>
        <div v-if="!loadingContent">
          <div class="grid grid-col-1-auto pb-1 justify-center align-center">
            <div class="tituloHeader">
              Detalhes do Pagamento
            </div>
            <div>
              <v-btn icon color="rgb(50,50,50)" @click="$emit('close')">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
          <div v-if="dados.status == 'AUTORIZADO'" class="py-4 mb-2">
            <v-row
              v-if="!confirma_pagamento_toggle && !recusa_pagamento_toggle"
              class="text-center"
            >
              <v-col cols="6">
                <v-btn
                  color="red darken-1"
                  depressed
                  dark
                  @click="recusarPagamento()"
                  >Recusar pagamento</v-btn
                >
              </v-col>
              <v-col cols="6">
                <v-btn
                  depressed
                  dark
                  color="primary"
                  @click="confirmPagamento()"
                  >Confirmar pagamento</v-btn
                >
              </v-col>
            </v-row>
          </div>
          <div
            v-if="recusa_pagamento_toggle"
            class="pa-3 red lighten-5 rounded-lg"
          >
            <div class="pa-3 text-center">
              Tem certeza que deseja <strong>recusar</strong> esse pagamento ?
            </div>
            <div class="pa-3">
              <v-row class="text-center">
                <v-col cols="6">
                  <v-btn text color="red" @click="closeConfirms()"
                    >CANCELAR</v-btn
                  >
                </v-col>
                <v-col cols="6">
                  <v-btn text color="primary" @click="recusarPagamentoFinal()"
                    >CONFIRMAR</v-btn
                  >
                </v-col>
              </v-row>
            </div>
          </div>
          <div
            v-if="confirma_pagamento_toggle"
            class="pa-3 red lighten-5 rounded-lg"
          >
            <div class="pa-3 text-center">
              Tem certeza que deseja <strong>confirmar</strong> esse pagamento ?
            </div>
            <div class="pa-3">
              <v-row class="text-center">
                <v-col cols="6">
                  <v-btn text color="red" @click="closeConfirms()"
                    >CANCELAR</v-btn
                  >
                </v-col>
                <v-col cols="6">
                  <v-btn text color="primary" @click="confirmPagamentoFinal()"
                    >CONFIRMAR</v-btn
                  >
                </v-col>
              </v-row>
            </div>
          </div>
          <div>
            <div>
              <div class="subtitulo">
                Solicitação
              </div>
              <div class="grid grid-col-1-1">
                <div class="itemGrid">
                  <div class="label">NOME DO EVENTO:</div>
                  <div class="value">{{ dados.nomeTurma }}</div>
                </div>
                <div class="itemGrid">
                  <div class="label">ID DO EVENTO:</div>
                  <div class="value">{{ dados.turmaId }}</div>
                </div>
              </div>
              <div class="itemGrid">
                <div class="label">
                  Descrição
                </div>
                <div class="value">
                  {{ dados.descricao | temRegistro }}
                </div>
              </div>
            </div>

            <div v-if="dados.fornecedor">
              <div class="subtitulo">
                Fornecedor
              </div>
              <div class="py-1">
                <div class="grid grid-col-1-1">
                  <div class="itemGrid">
                    <div class="label">
                      PRESTADOR / EMPRESA
                    </div>
                    <div class="value">
                      {{ dados.fornecedor.nome }}
                    </div>
                  </div>
                  <div class="itemGrid">
                    <div class="label">Telefone</div>
                    <div class="value">
                      {{ dados.fornecedor.telefone | temRegistro }}
                    </div>
                  </div>
                  <div class="itemGrid">
                    <div class="label">Email</div>
                    <div class="value">
                      {{ dados.fornecedor.email | temRegistro }}
                    </div>
                  </div>
                  <div class="itemGrid">
                    <div class="label">CPF/CNPJ</div>
                    <div class="value">
                      {{ dados.fornecedor.cpfCnpj }}
                    </div>
                  </div>
                </div>
                <div>
                  <div class="itemGrid">
                    <div class="label">
                      Descrição do Fornecedor
                    </div>
                    <div class="value">
                      {{ dados.fornecedor.descricao | temRegistro }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="dados.fornecedor">
              <div class="subtitulo">
                Dados bancários
              </div>
              <div class=" py-2">
                <div class="grid grid-col-1-1">
                  <div class="itemGrid">
                    <div class="label">Banco</div>
                    <div class="value">
                      {{ dados.fornecedor.banco | temRegistro }}
                    </div>
                  </div>
                  <div class="itemGrid">
                    <div class="label">Cod.Banco</div>
                    <div class="value">
                      {{ dados.fornecedor.codigoBanco | temRegistro }}
                    </div>
                  </div>
                  <div class="itemGrid">
                    <div class="label">Agência</div>
                    <div class="value">
                      {{ dados.fornecedor.agencia | temRegistro }}
                    </div>
                  </div>
                  <div class="itemGrid">
                    <div class="label">
                      Conta - digito
                    </div>
                    <div class="value">
                      {{ dados.fornecedor.conta | temRegistro }} -
                      {{ dados.fornecedor.contaDigito | temRegistro }}
                    </div>
                  </div>
                  <div class="itemGrid">
                    <div class="label">
                      Titular da Conta
                    </div>
                    <div class="value">
                      {{ dados.fornecedor.titularConta }}
                    </div>
                  </div>
                  <div class="itemGrid">
                    <div class="label">
                      Chave PIX
                    </div>
                    <div class="value">
                      {{ dados.fornecedor.chavePix }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pa-2">
            <div class="subtitulo">
              Anexos
            </div>
            <v-btn
              :loading="loadingAnexo"
              color="grey lighten-3"
              depressed
              @click="selectInputFile()"
            >
              <v-icon size="25">
                mdi-paperclip
              </v-icon>
              Enviar Anexo
            </v-btn>
            <input
              v-show="false"
              id="anexoFile"
              type="file"
              @change="selectDoc()"
            />
          </div>
          <div id="anexos" class="pa-2">
            <v-card v-if="confirmDeleteAnexo" class="cardConfirmDelete">
              <div>
                <div class="py-3">
                  Tem certeza que deseja DELETAR o arquivo?
                </div>
                <v-row>
                  <v-col cols="6">
                    <v-btn
                      text
                      color="primary"
                      @click="confirmDeleteAnexo = false"
                      >Cancelar</v-btn
                    >
                  </v-col>
                  <v-col cols="6">
                    <v-btn text color="red" @click="requestDeleteAnexo()"
                      >Sim, deletar arquivo</v-btn
                    >
                  </v-col>
                </v-row>
              </div>
            </v-card>
            <v-card
              v-for="(file, index) in dados.anexos"
              :key="index"
              class="pa-2 my-1 justify-center align-center verticalCenter elevation-0 borderSafe"
            >
              <div class="d-grid grid-col-auto-1-auto">
                <div class="text-center">
                  <v-btn icon color="primary" @click="openFile(file)">
                    <v-icon size="25">
                      mdi-download-box
                    </v-icon>
                  </v-btn>
                </div>
                <div
                  class="text-body-2 pa-2 text-left text-truncate"
                  :title="file.nome"
                >
                  {{ file.nome }}
                </div>
                <div>
                  <v-btn icon color="red darken-1" @click="deleteFile(file.id)">
                    <v-icon size="25">
                      mdi-delete-outline
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </v-card>
          </div>
          <div class="comentarios pa-1">
            <div class="subtitulo">
              Comentários
            </div>
            <div class="py-2">
              <div class="d-grid grid-col-1-auto text-center ">
                <v-row class="align-center">
                  <v-col cols="12" sm="9" md="10">
                    <input
                      v-model="mensagemPayload"
                      class="pa-3 grey lighten-4 rounded full-width"
                      placeholder="Novo comentário"
                      @keyup.enter="enviarMensagem()"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="2"
                    class="text-center text-sm-left"
                  >
                    <v-btn
                      depressed
                      large
                      color="primary"
                      @click="enviarMensagem()"
                      >Enviar</v-btn
                    >
                  </v-col>
                </v-row>
              </div>
            </div>
            <div id="listaComments">
              <div
                v-for="(msg, index) in dados.comentarios"
                :key="index"
                class="pa-3 grey lighten-5 mt-1 rounded-lg"
              >
                <v-row justify="center" class="align-center text-center">
                  <v-col cols="2">
                    <v-avatar>
                      <v-img :src="msg.fotoUrl"></v-img>
                    </v-avatar>
                  </v-col>
                  <v-col cols="10" class="text-left">
                    <div class="font-weight-bold text-subtitle-2">
                      {{ msg.autor }}
                      <div
                        class="font-weight-light text-body-2 text-uppercase d-inline-block"
                      >
                        {{ msg.dataComentario | momentFilter }}
                      </div>
                    </div>

                    <div>{{ msg.comentario }}</div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import serviceSolicitacoes from "@/services/solicitacoes";
import moment from "moment";

export default {
  filters: {
    momentFilter(val) {
      return moment(val).format("DD/MM/YYYY - hh:mm:ss ");
    },
    temRegistro(val) {
      if (val) return val;
      else return "S/ REGISTRO";
    }
  },
  props: {
    uid: {
      type: Number,
      required: true
    },
    directUpload: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      confirma_pagamento_toggle: false,
      recusa_pagamento_toggle: false,
      loadingContent: true,
      loadingAnexo: false,
      fileAnexo: "",
      dialog: true,
      mensagemPayload: "",
      idToDeleteAnexo: "",
      confirmDeleteAnexo: false,
      dados: {}
    };
  },
  mounted() {
    this.getSolicitacaoById();
  },
  methods: {
    requestDeleteAnexo() {
      serviceSolicitacoes
        .removerAnexo(this.dados.id, this.idToDeleteAnexo)
        .then(resp => {
          this.$root.showInfo(resp.data);
          this.getSolicitacaoById();
        })
        .catch(err => {
          this.$root.showError(err);
        })
        .finally(() => {
          this.confirmDeleteAnexo = false;
        });
    },
    deleteFile(idAnexo) {
      this.idToDeleteAnexo = idAnexo;
      this.confirmDeleteAnexo = true;
    },
    enviarMensagem() {
      //beta-tester
      if (this.mensagemPayload) {
        let mensagem = this.mensagemPayload;
        serviceSolicitacoes
          .setComentario(this.dados.id, mensagem)
          .then(() => {
            this.getSolicitacaoById();
          })
          .catch(err => {
            this.$root.showError(err.data);
          })
          .finally(() => {
            this.mensagemPayload = "";
          });
      } else {
        return false;
      }
    },
    openFile(file) {
      window.open(file.url, file.nome);
    },
    getSolicitacaoById() {
      serviceSolicitacoes
        .getSolicitacaoPorID(this.uid)
        .then(resp => {
          this.dados = resp.data;
          this.loadingContent = false;
        })
        .catch(err => {
          console.error(err);
        });
    },
    selectInputFile() {
      document.querySelector("#anexoFile").click();
    },
    selectDoc() {
      let makefile = document.querySelector("#anexoFile").files[0];
      let formData = new FormData();
      formData.append("file", makefile);
      formData.append("nome", makefile.name);
      this.fileAnexo = formData;
      this.uploadFile();
    },
    uploadFile() {
      let solicitacaoID = this.dados.id;
      let file = this.fileAnexo;
      serviceSolicitacoes
        .enviarAnexo(solicitacaoID, file)
        .then(resp => {
          console.log(resp);
          this.getSolicitacaoById();
          this.$root.showInfo("Enviado com sucesso!");
          this.$emit("atualiza");
        })
        .catch(err => {
          console.log(err);
        });
    },
    closeConfirms() {
      this.confirma_pagamento_toggle = false;
      this.recusa_pagamento_toggle = false;
    },
    recusarPagamentoFinal() {
      serviceSolicitacoes.setSolicitacaoRecusada(this.dados.id).then(resp => {
        console.log(resp);
        this.$root.showInfo(resp.data.body);
        this.$emit("atualiza");
        this.$emit("close");
        this.recusa_pagamento_toggle = false;
      });
    },
    recusarPagamento() {
      this.recusa_pagamento_toggle = true;
    },
    confirmPagamentoFinal() {
      serviceSolicitacoes
        .setSolicitacaoPaga(this.dados.id)
        .then(resp => {
          this.$root.showInfo(resp.data.body);
          this.$emit("atualiza");
          this.$emit("close");
        })
        .catch(err => {
          this.$root.showInfo(err.data);
          console.log("erro" + err.data);
          this.closeConfirms();
        });
    },
    confirmPagamento() {
      this.confirma_pagamento_toggle = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.cardConfirmDelete {
  padding: 8px;
  background-color: rgba(255, 0, 0, 0.02);
  border: 1px solid rgba(255, 0, 0, 0.05);
  text-align: center;
  box-shadow: none !important;
  border-radius: 4px;
}
.tituloHeader {
  font-size: 20px;
  font-weight: 400;
  padding-left: 5px;
  font-family: "Roboto", sans-serif;
  color: rgb(70, 70, 70);
}
.subtitulo {
  padding: 12px 0px;
  line-height: 1.5rem;
  font-size: 18px;
  font-weight: 300;
  color: #607d8b;
}
.itemGrid {
  margin: 8px;
}
.label {
  font-size: 12px;
  color: #7e7e7e;
  line-height: 1.2rem;
}
.value {
  font-size: 14px;
  color: #111b47;
  line-height: 1.2rem;
}
.heightTextArea {
  background-color: rgba(150, 150, 150, 0.1);
  border: none;
  border-radius: 4px;
  height: 200px;
}
</style>
