<template>
  <div class="contentComponent">
    <div class="titleComponent">{{ title }}</div>
    <div>
      <v-btn v-if="hasClose" icon color="red" @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    hasClose: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.contentComponent {
  padding: 10px 0px;
  padding-top: 15px;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin-bottom: 15px;
}
.titleComponent {
  font-size: 1.6rem;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  user-select: none;
}
</style>
