<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card class="pa-3 text-center">
        <div class="close text-right">
          <v-btn text @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div>
          <xAvatar large :avatar-url="user.fotoUrl"></xAvatar>
        </div>
        <div class="py-3 text-h6 font-weight-bold">
          {{ user.nomeFormando }}
        </div>
        <v-divider></v-divider>
        <div class="text-body-1 font-weight-normal">
          <div class="text-h6 font-weight-bold">
            Dados financeiros
          </div>
          <div class="py-1">USR-{{ user.usuarioId }}</div>
          <div class="py-1">Nome: {{ user.nome }}</div>
          <div class="py-1">Email: {{ user.email }}</div>
          <div class="py-1">Telefone: {{ user.telefone }}</div>
          <div class="py-1">Cadastro: {{ user.situacaoCadastro }}</div>
        </div>
        <v-divider></v-divider>
        <div class="py-2">
          <div class="text-h6 font-weight-bold">
            Detalhes da turma
          </div>
          <div class="py-1">Nome: {{ user.turmaNome }}</div>
          <div class="py-1">
            Permissão: {{ traduzPermissao(user.permissaoTurma) }}
          </div>
          <div class="py-1">
            Convite: {{ traduzConvite(user.conviteStatus) }}
          </div>
          <div class="py-1">
            Meta de contribuição: {{ user.metaContribuicao | currency }}
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import xAvatar from "@/components/avatar.vue";
export default {
  components: {
    xAvatar
  },
  props: {
    user: {
      default: () => {
        return Object;
      },
      required: true,
      type: Object
    }
  },
  data() {
    return {
      dialog: true
    };
  },
  methods: {
    traduzConvite(name) {
      if (name == "AUTORIZADO") return "Autorizado";
      else if (name == "NAO_AUTORIZADO") return "Não autorização";
      else return name;
    },
    traduzPermissao(name) {
      if (name == "COMISSAO") return "Comissão";
      else if (name == "PARTICIPANTE") return "Participante";
      else if (name == "ADMIN") return "Administrador";
      else return name;
    }
  }
};
</script>

<style></style>
