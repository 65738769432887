import userService from "@/services/userController";
import typesService from "@/services/tiposController";
import router from "@/router/index";
export default {
  REQUEST_TYPES_USER({ commit }) {
    return new Promise((resolve, reject) => {
      typesService
        .rolesManager()
        .then(resp => {
          commit("setRolesManager", resp.data);
          resolve();
        })
        .catch(err => {
          console.log(err.data);
          reject(err);
        });
    });
  },
  REQUEST_USER({ commit }) {
    return new Promise((resolve, reject) => {
      userService
        .getUser()
        .then(resp => {
          commit("setUser", resp.data);
          resolve();
        })
        .catch(err => {
          console.error(err.data);
          router.push("/login");
          reject();
        });
    });
  },
  START_SYSTEM({ dispatch, commit }) {
    dispatch("REQUEST_USER").then(() => {
      commit("setLoader", false);
    });
  }
};
