<template>
  <div class="cardFilter">
    <div class="text-slate-600">
      Filtros
    </div>
    <v-row>
      <v-col>
        <v-autocomplete
          :items="listaDeTurmas"
          :search-input.sync="searchTurma"
          :value="searchTurmaObject"
          color="primary"
          label="Turma/Evento"
          item-text="nome"
          item-value="id"
          placeholder=""
          prepend-inner-icon="mdi-school"
          return-object
          hide-details
          class="text-truncate"
          :disabled="loading"
          @change="changeTurma"
        >
          <template v-slot:selection="data">
            <div>
              <v-chip>
                {{ data.item.nome }}
              </v-chip>
            </div>
          </template>
          <template v-slot:item="data">
            <template>
              <v-list-item-icon>
                <div>
                  <v-icon color="rgba(50,50,50,0.2)">mdi-school</v-icon>
                </div>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.item.nome }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
          <template slot="no-data">
            <div class="px-4 py-1 text-slate-400" style="user-select: none;">
              Sem dados referente a pesquisa
            </div>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col> </v-col>
    </v-row>

    <v-divider class="my-1 mt-2"></v-divider>
    <div class="mt-2">
      <v-row>
        <v-col>
          <v-btn color="white" depressed @click="limparCampos()">Limpar</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn text color="primary" depressed @click="consultaLista()"
            >Consultar</v-btn
          >
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import serviceTurma from "@/services/turma";
export default {
  name: "VFiltrosDeBusca",
  data() {
    return {
      listaDeTurmas: [],
      searchTurma: null,
      searchTurmaObject: null,
      filtros: {
        nome: "",
        nomeTurma: null,
        alunoStatus: null,
        conviteStatus: null,
        email: null,
        permissao: null,
        alunoId: null,
        cpf: null,
        rg: null,
        telefone: null,
        pai: null,
        mae: null,
        endereco: {
          cidade: null,
          logradouro: null,
          uf: null,
          bairro: null,
          cep: null
        }
      },
      loading: false,
      timeSearch: 300,
      clockSearch: null
    };
  },
  watch: {
    searchTurma: function(value) {
      if (value && value.length > 0) {
        this.debounceSearchEvent();
      }
    }
  },
  methods: {
    consultaLista() {
      const filtros = { ...this.filtros };
      this.$emit("onSearch", filtros);
    },
    limparCampos() {
      this.searchTurma = null;
      this.filtros.nomeTurma = null;
      this.searchTurmaObject = null;
      this.filtros = {
        nome: "",
        nomeTurma: null,
        alunoStatus: null,
        conviteStatus: null,
        email: null,
        permissao: null,
        alunoId: null,
        cpf: null,
        rg: null,
        id: null,
        telefone: null,
        pai: null,
        mae: null,
        endereco: {
          cidade: null,
          logradouro: null,
          uf: null,
          bairro: null,
          cep: null
        }
      };
      this.$emit("clearSeach", this.filtros);
    },
    buscarTurma(name) {
      serviceTurma
        .buscarTurma(name)
        .then(resp => {
          this.listaDeTurmas = resp.data;
        })
        .catch(error => {
          this.$root.showError(error);
        });
    },
    debounceSearchEvent() {
      clearTimeout(this.clockSearch);
      const search = this.searchTurma;
      const self = this;
      this.clockSearch = setTimeout(() => {
        if (!search || search === "" || search.length <= 2) {
          return false;
        }
        self.buscarTurma(search);
      }, self.timeSearch);
    },
    changeTurma(turma) {
      if (turma) {
        this.searchTurma = turma.nome;
        this.filtros.nomeTurma = turma.nome;
      } else {
        this.searchTurma = null;
        this.filtros.nomeTurma = null;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.cardFilter {
  padding: 12px 16px;
  background-color: white;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 1px 1px 2px rgba(50, 50, 50, 0.2);
}
</style>
