import axios from "axios";

export default {
  getUltimoRetorno() {
    return axios.get("/v1/retorno-banco/ultimo-retorno");
  },

  uploadNovaRemessa(file) {
    return axios.post("/v1/retorno-banco/upload", file, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }
};
