<template>
  <div class="pa-8">
    <div>
      <v-text-field
        v-model="include.nome"
        label="Nome da associação *"
      ></v-text-field>
      <v-text-field
        v-model="include.cnpj"
        v-mask="['##.###.###/####-##']"
        label="CNPJ *"
      ></v-text-field>
    </div>
    <div>
      <v-row justify="center" align="center">
        <v-col cols="12" sm="8">
          <v-text-field
            v-model="inputCEP"
            v-mask="['#####-###']"
            hide-details
            label="CEP"
            :loading="loadingCEP"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" class="text-center">
          <v-btn
            v-if="!getUserRoleEdit"
            :loading="loadingCEP"
            color="#9B51E0"
            depressed
            dark
            large
            @click="buscarCEP()"
            >Buscar CEP</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-select
            v-model="include.estadoId"
            hide-details
            :items="listaEstados"
            label="Estado *"
            item-text="nome"
            item-value="id"
            :loading="loadingEstado"
            no-data-text="Carregando"
            @change="onChangeEstado()"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            v-model="include.cidadeId"
            hide-details
            :items="listaCidades"
            label="Cidade * "
            item-text="descricao"
            item-value="id"
            no-data-text="Pesquise o estado primeiro"
            :loading="loadingCidades"
            @change="onChangeCidade()"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="include.enderecoCompleto.logradouro"
            hide-details
            label="logradouro"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="include.enderecoCompleto.bairro"
            hide-details
            label="Bairro"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="include.enderecoCompleto.numero"
            hide-details
            label="Número"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="include.enderecoCompleto.complemento"
            hide-details
            label="complemento"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col order-sm-1 order-2 cols="12" sm="6">
          <div class="text-left pt-5">
            <v-btn
              depressed
              color="red darken-2"
              dark
              :loading="loading"
              @click="abrirModalArquivarTurma()"
            >
              Arquivar
            </v-btn>
          </div>
        </v-col>
        <v-col order-sm-2 order-1 cols="12" sm="6">
          <div class="text-right pt-5">
            <v-btn
              depressed
              color="primary"
              :loading="loading"
              @click="salvarAlteracao()"
            >
              Salvar
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
    <ConfirmarArquivarTurma
      v-if="modalConfirmarArquivar"
      :turma-id="turma.id"
      @confirm="concluirProcessoArquivarTurma()"
      @close="closeModalArquivarTurma()"
    />
  </div>
</template>

<script>
import serviceLocal from "@/services/localidade";
import EVENTOS from "@/constantes/EventosType";
import serviceTurma from "@/services/turma";
import ConfirmarArquivarTurma from "./ConfirmarArquivarTurma.vue";
import mixinArchive from "@/mixins/turma/EventoDetalhadoArquivar";

export default {
  components: {
    ConfirmarArquivarTurma: ConfirmarArquivarTurma
  },
  mixins: [mixinArchive],
  props: ["turma"],
  data() {
    return {
      inputCEP: "",
      loadingCidades: false,
      loadingEstado: false,
      loadingCEP: false,
      loading: false,
      prioridadeMounted: true,
      listaEstados: [],
      listaCidades: [],
      include: {
        nome: "",
        cnpj: "",
        estadoId: "",
        cidadeId: "",
        tipoEvento: EVENTOS.ASSOCIACAO,
        enderecoCompleto: {
          logradouro: "",
          cidadeNome: "",
          bairro: "",
          estado: "",
          numero: "",
          complemento: "",
          cep: "",
          cidadeIbge: 0
        }
      }
    };
  },
  computed: {
    getUserRoleEdit() {
      return this.$store.getters["aluno/getPermissaoTurmaAtiva"] !== "ADMIN";
    },
    getEstadoSetadoToggle() {
      return this.include.estadoId ? false : true;
    }
  },
  beforeMount() {
    this.buscaEstado();
  },
  mounted() {
    this.include = { ...this.turma };
    this.inputCEP = this.include.enderecoCompleto.cep;
    this.buscaCidade(this.include.estadoId);
    this.prioridadeMounted = false;
  },
  methods: {
    salvarAlteracao() {
      if (!this.verificarDados()) return false;
      let CEPTratamento = this.inputCEP;
      if (CEPTratamento.includes("-")) {
        let tratamento1 = CEPTratamento.replace("-", "");
        this.include.enderecoCompleto.cep = tratamento1;
      }
      serviceTurma
        .alterarEvento(this.include)
        .then(() => {
          this.$root.showInfo("Alterado com sucesso!");
          this.loading = false;
          this.$emit("close");
        })
        .catch(err => {
          this.$root.showError(err);
          this.loading = false;
        });
    },
    verificarDados() {
      if (!this.include.nome && this.include.nome.length < 3) {
        this.$root.showError("Nome inválido");
        return false;
      }
      if (!this.$root.validaCNPJ(this.include.cnpj)) {
        this.$root.showError("CNPJ inválido");
        return false;
      }
      if (
        this.inputCEP === "" ||
        !this.inputCEP ||
        this.inputCEP.length !== 9
      ) {
        this.$root.showError("CEP inválido");
        this.loadingStep = false;
        return false;
      }
      if (
        !this.include.enderecoCompleto.logradouro ||
        this.include.enderecoCompleto.logradouro.length < 3
      ) {
        this.$root.showError("Logradouro inválido");
        this.loadingStep = false;
        return false;
      }
      if (!this.include.cidadeId) {
        this.$root.showError(
          "Você deve selecionar a cidade para alterar o cadastro"
        );
        return false;
      }
      if (
        !this.include.enderecoCompleto.bairro ||
        this.include.enderecoCompleto.bairro.length < 3
      ) {
        this.$root.showError("Bairro inválido");
        this.loadingStep = false;
        return false;
      }
      return true;
    },
    buscaEstado() {
      this.loadingEstado = true;
      serviceLocal
        .getEstado()
        .then(resp => {
          this.listaEstados = resp.data;
          this.loadingEstado = false;
        })
        .catch(err => {
          this.$root.showError(err.data);
          this.loadingEstado = false;
        });
    },
    buscaCidade() {
      serviceLocal
        .getCidadeForEstado(this.include.estadoId)
        .then(resp => {
          this.loadingCidades = false;
          this.listaCidades = resp.data;
        })
        .catch(err => {
          this.$root.showError(err.data);
          this.loadingCidades = false;
        });
    },
    onChangeEstado() {
      this.loadingCidades = false;
      this.include.enderecoCompleto.estado = String(this.include.estadoId);
      this.buscaCidade();
      if (!this.prioridadeMounted) {
        this.include.cidadeId = null;
        this.include.enderecoCompleto.cidadeNome = null;
      }
    },
    onChangeCidade() {
      this.include.enderecoCompleto.cidadeNome = String(this.include.cidadeId);
    },
    buscaCidadeAndFind(payload) {
      serviceLocal
        .getCidadeForEstado(this.include.estadoId)
        .then(resp => {
          this.loadingCidades = false;
          this.listaCidades = resp.data;
          let payloadCidade = this.listaCidades.find(
            item =>
              item.descricao.toUpperCase() === payload.localidade.toUpperCase()
          );
          this.include.cidadeId = payloadCidade.id;
          this.include.enderecoCompleto.cidadeNome = String(payloadCidade.id);
          this.loadingCEP = false;
        })
        .catch(err => {
          this.$root.showError(err.data);
          this.loadingCidades = false;
          this.loadingCEP = false;
        });
    },
    proccessCityAndState(dados) {
      if (this.listaEstados.length > 0) {
        let payloadEstado = this.listaEstados.find(item => item.uf == dados.uf);
        this.include.estadoId = payloadEstado.id;
        this.include.enderecoCompleto.estado = String(payloadEstado.id);
        this.buscaCidadeAndFind(dados);
      } else {
        this.buscaEstado();
        setTimeout(() => {
          this.proccessCityAndState(dados);
        }, 500);
      }
    },
    processarDadosDoCEP(dados) {
      this.loadingCEP = true;
      this.include.enderecoCompleto.bairro = dados.bairro;
      this.include.enderecoCompleto.logradouro = dados.logradouro;
      this.include.enderecoCompleto.cidadeIbge = Number(dados.ibge);
      this.include.enderecoCompleto.bairro = dados.bairro;
      this.proccessCityAndState(dados);
    },
    buscarCEP() {
      let CEPToSearch = this.inputCEP;
      if (CEPToSearch.length !== 9) {
        this.$root.showError("CEP inválido");
        return false;
      }
      if (CEPToSearch.includes("-")) {
        CEPToSearch = CEPToSearch.replace("-", "");
      }
      serviceLocal
        .getCep(CEPToSearch)
        .then(resp => {
          this.processarDadosDoCEP(resp.data);
        })
        .catch(err => {
          console.log(err);
          this.$root.showError(err.data);
        });
    }
  }
};
</script>
