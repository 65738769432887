<template>
  <div class="tag" :class="getTagContainer">
    <div class="titulo" :class="getStyleTitulo">
      {{ getName }}
    </div>
  </div>
</template>

<script>
export default {
  name: "VTagSituacaoFCZ",
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    getName() {
      if (!this.value) {
        return "Não";
      }
      return "Sim";
    },
    getTagContainer() {
      if (!this.value) {
        return "tag-negativo";
      }
      return "tag-positivo";
    },
    getStyleTitulo() {
      if (!this.value) {
        return "tag-negativo-titulo";
      }
      return "tag-positivo-titulo";
    }
  }
};
</script>

<style lang="scss" scoped>
.tag {
  display: inline-block;
  padding: 5px 10px;
  min-width: 60px;
  text-align: center;
  border-radius: 4px;
  &.tag-negativo {
    //background-color: rgb(170, 0, 0, 0.1);
  }
  &.tag-positivo {
    //background-color: rgb(0, 100, 0, 0.1);
  }
}
.titulo {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  &.tag-negativo-titulo {
    color: rgb(170, 0, 0);
  }
  &.tag-positivo-titulo {
    color: rgb(0, 100, 0);
  }
}
</style>
