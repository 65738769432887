import axios from "axios";

export default {
  getItensDoPlano() {
    return axios.get("/v1/servico");
  },
  setItemPlano(item) {
    return axios.post("/v1/servico", item);
  }
};
