<template>
  <div style="height: 100%">
    <v-titulo>Pacotes</v-titulo>
    <div class="mx-5">
      <v-row justify="center" align-center>
        <v-col cols="12" sm="6">
          <div class=" text-left">
            <v-btn depressed color="primary" @click="modalNovoPacote = true"
              >Novo Pacote</v-btn
            >
          </div>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            v-model="filterItemAtivo"
            depressed
            solo
            :items="filtrosAtivos"
          />
        </v-col>
      </v-row>
    </div>

    <div class="ma-4 d-flex flex-wrap justify-start">
      <v-data-table
        :headers="headers"
        :items="GET_LIST"
        class="elevation-1 full-width"
        item-key="id"
      >
        <template v-slot:body="{ items }" ma-5>
          <tbody>
            <tr v-for="item in items" :key="item.name">
              <td style="min-width: 20px;">
                <v-avatar size="30">
                  <v-icon size="30" :color="item.color">mdi-square</v-icon>
                </v-avatar>
              </td>
              <td style="min-width: 150px;">{{ item.titulo }}</td>
              <td style="min-width: 250px;">{{ item.descricao }}</td>
              <td style="min-width: 100px;">{{ item.valor | currency }}</td>
              <td style="min-width: 150px;">
                {{ item.tipoCobranca | normalizeTipoCobranca }}
              </td>
              <td>{{ item.diasCarencia }}</td>
              <td>
                <div v-if="item.ativo">
                  <v-btn
                    depressed
                    color="rgba(0,100,150,0.1)"
                    title="Ativo"
                    @click="desativarPacote(item)"
                  >
                    <v-icon color="primary">mdi-eye</v-icon>
                  </v-btn>
                </div>
                <div v-else>
                  <v-btn
                    title="Desativado"
                    depressed
                    color="rgb(50,50,50,0.1)"
                    @click="ativarPacote(item)"
                  >
                    <v-icon color="rgb(50,50,50,0.5)">mdi-eye-off</v-icon>
                  </v-btn>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>
    <vNovoPacote
      v-if="modalNovoPacote"
      @update="requestPacotes"
      @close="modalNovoPacote = false"
    />
  </div>
</template>

<script>
import servicePacotes from "@/services/pacotes";
import vNovoPacote from "@/views/dashboard/pacotes/novoPacote.vue";
export default {
  components: {
    vNovoPacote
  },
  filters: {
    normalizeTipoCobranca(cobranca) {
      if (cobranca === null) {
        return "Indefinido";
      } else if (cobranca === "MENSAL") {
        return "Mensal";
      } else if (cobranca === "POR_TRANSACAO") {
        return "Por Transação";
      } else {
        return cobranca;
      }
    },
    statusName(name) {
      if (name) {
        return "Ativo";
      } else {
        return "Desabilitado";
      }
    }
  },
  data() {
    return {
      filterItemAtivo: "ATIVOS",
      modalNovoPacote: false,
      tipoDeConfirm: null,
      modalConfirm: false,
      itemToConfirm: "",
      pacoteSelecionadoNome: "",
      filtrosAtivos: [
        { text: "Todos", value: "TODOS" },
        { text: "Ativos", value: "ATIVOS" },
        { text: "Desativados", value: "DESATIVADOS" }
      ],
      headers: [
        { text: "Cor", value: "color", align: "left", sortable: false },
        {
          text: "Nome do Plano",
          value: "titulo",
          align: "left",
          sortable: false
        },
        {
          text: "Descrição",
          value: "descricao",
          align: "left",
          sortable: false
        },
        { text: "Valor", value: "valor", align: "left", sortable: false },
        {
          text: "Tipo Cobrança",
          value: "tipoCobranca",
          align: "left",
          sortable: false
        },
        {
          text: "Dias Carencia",
          value: "diasCarencia",
          align: "left",
          sortable: false
        },
        { text: "Status", value: "ativo", align: "left", sortable: false }
      ],
      listaPacotes: [],
      loading: true
    };
  },

  computed: {
    GET_LIST() {
      return this.listaPacotes.filter(item => {
        if (this.filterItemAtivo === "TODOS") {
          return true;
        } else if (this.filterItemAtivo === "ATIVOS") {
          return item.ativo === true;
        } else if (this.filterItemAtivo === "DESATIVADOS") {
          return item.ativo === false;
        }
      });
    }
  },
  mounted() {
    this.requestPacotes();
  },
  methods: {
    changeStatusItem(s) {
      console.log("change", s);
    },
    acaoConfirmada() {
      if (this.tipoDeConfirm === "DESATIVAR") {
        this.confirmadoDesativaPacote();
      } else if (this.tipoDeConfirm === "ATIVAR") {
        this.ativarPacote();
      }
    },
    ativarPacote(item) {
      servicePacotes
        .ativarPacote(item.id)
        .then(resp => {
          this.$root.showInfo(resp.data);
          this.requestPacotes();
        })
        .catch(err => {
          this.$root.showError(err.data);
          console.error(err);
          this.requestPacotes();
        });
    },
    desativarPacote(item) {
      servicePacotes
        .desativaPacote(item.id)
        .then(resp => {
          this.$root.showInfo(resp.data);
          this.requestPacotes();
        })
        .catch(err => {
          this.$root.showError(err.data);
          console.error(err);
          this.requestPacotes();
        });
      this.itemToConfirm = "";
      this.pacoteSelecionadoNome = "";
      this.modalConfirm = false;
    },
    requestPacotes() {
      this.loading = true;
      servicePacotes
        .getPacotes()
        .then(resp => {
          this.loading = false;
          this.listaPacotes = resp.data;
        })
        .catch(err => {
          console.log(err.data);
          this.$root.showError(err.data);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.minimenu {
  display: flex;
  flex-direction: row;
}
</style>
