<template>
  <div>
    <div v-if="step == 2" class="pa-3">
      <div>
        <v-text-field
          v-model="include.nomeNoivo"
          label="Nome do noivo *"
          name="noivo"
        ></v-text-field>
      </div>
      <div>
        <v-text-field
          v-model="include.nomeNoiva"
          label="Nome da noiva *"
          name="noiva"
        ></v-text-field>
      </div>
      <div>
        <v-text-field
          v-model="include.dataFormatura"
          v-mask="'##/##/####'"
          label="Data do casamento *"
          placeholder="DD/MM/AAAA"
        />
      </div>
      <v-row>
        <v-col cols="12" sm="6">
          <v-select
            v-model="include.estadoId"
            :items="listaEstados"
            label="Estado *"
            item-text="nome"
            item-value="id"
            @change="onChangeEstado()"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            v-model="include.cidadeId"
            :items="listaCidades"
            label="Cidade * "
            item-text="descricao"
            item-value="id"
            :loading="loadingCidades"
          ></v-select>
        </v-col>
      </v-row>
      <div class="text-right pt-3 mt-3">
        <v-btn
          color="secondaryDash"
          class="rounded-lg px-8"
          dark
          @click="validaEtapa2()"
          >PRÓXIMO</v-btn
        >
      </div>
    </div>
    <div v-if="step == 3">
      <vAdapterConfigNotification
        @setConfigAndRegister="processRegisterAndConfig"
      />
    </div>
  </div>
</template>

<script>
import EVENTOS from "@/constantes/EventosType";
import serviceLocal from "@/services/localidade";
import serviceTurma from "@/services/turma";
import moment from "moment";
import vAdapterConfigNotification from "./adapterConfigNotification.vue";

export default {
  components: {
    vAdapterConfigNotification
  },
  props: {
    step: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loadingStep: false,
      loadingCidades: false,
      loading: false,
      listaEstados: [],
      listaCidades: [],
      formatDate: "DD/MM/YYYY",
      include: {
        nomeNoivo: "",
        nomeNoiva: "",
        cidadeId: "",
        estadoId: "",
        dataFormatura: "",
        tipoEvento: EVENTOS.CASAMENTO,
        config: {}
      }
    };
  },
  mounted() {
    this.$emit("setStep", 2);
    this.$emit("setMaxStep", 3);
  },
  beforeMount() {
    this.buscaEstado();
  },
  methods: {
    processRegisterAndConfig(config) {
      this.include.config = { ...config };
      this.cadastrarEvento();
    },
    verificadoresDeDados() {
      if (!this.include.nomeNoivo || this.include.nomeNoivo.length < 3) {
        this.$root.showError(
          "Nome do noivo não está muito pequeno, coloque o nome completo"
        );
        return false;
      }
      if (!this.include.nomeNoiva || this.include.nomeNoiva.length < 3) {
        this.$root.showError(
          "Nome do noiva não está muito pequeno, coloque o nome completo"
        );
        return false;
      }
      if (!this.include.estadoId || typeof this.include.estadoId !== "number") {
        this.$root.showError("É necessario selecionar o estado atual");
        return false;
      }
      if (!this.include.cidadeId || typeof this.include.cidadeId !== "number") {
        this.$root.showError("É necessario selecionar a cidade atual");
        return false;
      }
      return true;
    },
    validateData() {
      var dataValue = this.include.dataFormatura;
      if (!dataValue || dataValue.length != 10) {
        this.$root.showError(
          "O formato da data está inválido. modelo DD/MM/AAAA"
        );
        return false;
      }
      let dt = moment(dataValue, this.formatDate);
      if (!dt.isValid()) {
        this.$root.showError("A data do casamento está inválida");
        return false;
      }
      if (!moment().isBefore(dt)) {
        this.$root.showError("A data deve ser maior que o dia atual");
        return false;
      }
      return true;
    },
    tratamentoDeDados() {
      let dataPayload = moment(this.include.dataFormatura, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      );
      return {
        nomeNoivo: this.include.nomeNoivo,
        nomeNoiva: this.include.nomeNoiva,
        cidadeId: this.include.cidadeId,
        estadoId: this.include.estadoId,
        nome: this.gerarTagName(),
        dataFormatura: dataPayload,
        tipoEvento: this.include.tipoEvento,
        config: this.include.config
      };
    },
    gerarTagName() {
      let completo = this.include.nomeNoivo + " " + this.include.nomeNoiva;
      let noivoNumber = this.include.nomeNoivo.length * 13;
      let noivaNumber = this.include.nomeNoiva.length * 17;
      let padraoNumber = 1000;
      let tagNumber = noivoNumber + noivaNumber + padraoNumber;
      let arr = completo.split(" ");
      let coderesult = "";

      if (arr.length >= 3) {
        arr.forEach(el => {
          coderesult += el[0].toUpperCase();
        });
      } else {
        arr.forEach(el => {
          coderesult += el[0].toUpperCase() + el[1].toUpperCase();
        });
      }
      return coderesult + "-" + tagNumber;
    },
    validaEtapa2() {
      if (!this.verificadoresDeDados()) return false;
      if (!this.validateData()) return false;
      let stepPayload = this.step + 1;
      this.$emit("setStep", stepPayload);
    },
    cadastrarEvento() {
      let POST_DATA = this.tratamentoDeDados();
      this.loading = true;
      serviceTurma
        .novoEvento(POST_DATA)
        .then(resp => {
          this.loading = false;
          this.$emit("concluido", resp.data.codigoConvite);
        })
        .catch(err => {
          this.$root.showError(err.data);
          this.loading = false;
        });
    },
    buscaEstado() {
      serviceLocal.getEstado().then(resp => {
        this.listaEstados = resp.data;
      });
    },
    buscaCidade(estadoId) {
      serviceLocal
        .getCidadeForEstado(estadoId)
        .then(resp => {
          this.loadingCidades = false;
          this.listaCidades = resp.data;
        })
        .catch(err => {
          this.$root.showError(err.data);
          this.loadingCidades = false;
        });
    },
    onChangeEstado() {
      this.loadingCidades = true;
      this.buscaCidade(this.include.estadoId);
    }
  }
};
</script>
