//[ PAGO, PAGO_GERANDO_BILHETES, NAO_PAGO, EXPIRADO, CANCELADO ]
export const statusType = {
  PAGO: "PAGO",
  PAGO_GERANDO_BILHETES: "PAGO_GERANDO_BILHETES",
  NAO_PAGO: "NAO_PAGO",
  EXPIRADO: "EXPIRADO",
  CANCELADO: "CANCELADO"
};

export const statusName = {
  PAGO: "Pago",
  PAGO_GERANDO_BILHETES: "Gerando Bilhetes",
  NAO_PAGO: "Não Pago",
  EXPIRADO: "Expirado",
  CANCELADO: "Cancelado"
};

export const getList = [
  {
    text: statusName.PAGO,
    value: statusType.PAGO
  },
  {
    text: statusName.PAGO_GERANDO_BILHETES,
    value: statusType.PAGO_GERANDO_BILHETES
  },
  {
    text: statusName.NAO_PAGO,
    value: statusType.NAO_PAGO
  },
  {
    text: statusName.EXPIRADO,
    value: statusType.EXPIRADO
  },
  {
    text: statusName.CANCELADO,
    value: statusType.CANCELADO
  }
];
