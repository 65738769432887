import axios from "axios";

export default {
  getConfiguracoes() {
    return axios.get(`/v1/configuracao-geral`);
  },
  update(configuracao) {
    return axios.post(`/v1/configuracao-geral`, configuracao);
  }
};
