<template>
  <v-dialog v-model="dialog" persistent max-width="700px">
    <v-card class="pa-4">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="concluirEdicao"
      >
        <v-card-title>
          <span class="headline">Editando Usuário</span>
        </v-card-title>
        <div class="pa-2">
          <v-row>
            <v-col align="center" cols="12">
              <avatar
                large
                :avatar-url="include.fotoUrl"
                :loading="loadingFoto"
              />
              <br />
              <v-form enctype="multipart/form-data">
                <label
                  for="foto"
                  style="cursor: pointer"
                  class="caption"
                  title="avatar"
                >
                  {{ formData ? formData.get("name") : "Troque o avatar" }}
                  <v-icon color="dark" for="foto">mdi-camera</v-icon>
                  <input
                    id="foto"
                    style="display: none"
                    type="file"
                    accept=".jpg,.png,.jpeg"
                    @change="uploadAvatar($event)"
                  />
                </label>
              </v-form>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="include.nome"
                label="Nome*"
                required
                hide-details
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <div class="text-left">
                <div class="labelField">Tipo de usuário</div>
                <div>{{ translateTypeUser(include.role) }}</div>
              </div>
            </v-col>
            <v-col v-if="user.role === 'AGENCIA'" cols="12" class="text-left">
              <div>
                <div class="labelField">Nome da Agência</div>
                <div>{{ nomeAgencia }}</div>
              </div>
            </v-col>
            <v-col cols="12" class="text-left">
              <div class="labelField">Email</div>
              <div>{{ include.email }}</div>
            </v-col>
          </v-row>
        </div>
        <div class="pa-2 mt-5">
          <v-row justify="center" align="center">
            <v-col cols="12" sm="6" class="text-center">
              <v-btn text color="red" @click="close()">
                Cancelar
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" class="text-center">
              <v-btn
                :disabled="!valid"
                color="primary"
                type="submit"
                text
                :loading="loading"
              >
                SALVAR
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
//TODO: SERÁ REFATORADO DEVIDO AO ENDPOINT,
import userService from "@/services/userController";
import avatar from "@/components/avatar";

export default {
  name: "EditarUser",
  components: {
    avatar
  },
  props: {
    user: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      loading: false,
      loadingFoto: false,
      valid: false,
      dialog: true,
      roles: [],
      nomeAgencia: "",
      include: {
        email: "",
        fotoUrl: "",
        id: "",
        nome: "",
        role: "",
        senha: ""
      },
      formData: "",
      rules: {
        required: value => !!value || "Campo obrigatório.",
        confirmDelete: value =>
          (!!value && this.include.email === value) ||
          "O endereço de email precisa ser igual!"
      }
    };
  },
  computed: {
    getTiposDeUser() {
      return this.$store.getters["gestao/getRolesManager"];
    }
  },
  mounted() {
    this.include = { ...this.user };
    this.nomeAgencia = this.user.agenciaNome;
  },
  methods: {
    translateTypeUser(name) {
      let result = this.$store.getters["gestao/getRolesManager"].find(
        x => x.id === name
      );
      if (result) {
        return result.descricao;
      } else {
        return name;
      }
    },
    close() {
      this.$emit("close");
    },
    uploadAvatar(event) {
      if (event && event.target && event.target.files.length > 0) {
        this.file = event.target.files[0];
        this.formData = new FormData();
        this.formData.append("file", this.file);
        this.formData.append("name", this.file.name);
        this.loadingFoto = true;
        userService
          .uploadImagem(this.formData)
          .then(resp => {
            this.loadingFoto = false;
            this.include.fotoUrl = resp.data;
          })
          .finally(() => {
            this.loadingFoto = false;
          })
          .catch(err => {
            this.$root.showError(err.data);
          });
      }
    },
    concluirEdicao() {
      if (this.valid) {
        let payload = this.include;
        userService
          .updateUsuarioAdministrativo(payload)
          .then(resp => {
            this.$root.showSucesso(resp.data);
            this.loading = false;
            this.$emit("update");
            this.$emit("close");
          })
          .catch(err => {
            this.$root.showError(err.data);
            console.error(err);
            this.$emit("close");
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.labelField {
  font-size: 12px;
  color: #7e7e7e;
}
</style>
