<template>
  <div>
    <v-titulo>Itens do Plano</v-titulo>
    <div class="ui pa-0 px-2">
      <div class="px-4 py-2">
        <v-row justify="center" align="center">
          <v-col cols="6">
            <v-text-field
              v-model="buscaNome"
              label="Buscar nome"
              outlined
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="statusSelecionado"
              outlined
              hide-details
              :items="listaSelected"
              item-text="nome"
              item-value="code"
              :value="true"
            ></v-select>
          </v-col>
          <v-col cols="3">
            <div class="text-center">
              <v-btn color="primary" large depressed @click="openNovoItem()"
                >Novo item</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="lista">
      <div v-if="loading" class="loading pa-5 mt-10 text-center">
        <v-progress-circular size="60" indeterminate></v-progress-circular>
      </div>
      <div v-else>
        <div v-for="(item, index) in getItens" :key="index" class="text-body-2">
          <v-card class="pa-2 ma-2 lowShadow">
            <v-row class="text-center">
              <v-col cols="4">
                <div class="pa-1">
                  <div class="font-weight-bold">Nome</div>
                  <div class="font-weight-light">{{ item.nome }}</div>
                </div>
                <div class="pa-1">
                  <div class="font-weight-bold">Valor</div>
                  <div class="font-weight-light">
                    {{ item.valor | currency }}
                  </div>
                </div>
              </v-col>
              <v-col cols="4">
                <div class="pa-1">
                  <div class="font-weight-bold">Sigla</div>
                  <div class="font-weight-light">{{ item.sigla }}</div>
                </div>
                <div class="pa-1">
                  <div class="font-weight-bold">Quantidade</div>
                  <div class="font-weight-light">
                    {{ item.qtdItem }}
                  </div>
                </div>
              </v-col>
              <v-col cols="4">
                <div class="pa-1">
                  <div class="font-weight-bold">
                    Calculado por Participante
                  </div>
                  <div class="font-weight-light">
                    {{ item.calcularPorFormando | filtraBoolean }}
                  </div>
                </div>
                <div class="pa-1">
                  <div class="font-weight-bold">
                    Status do Item
                  </div>
                  <div class="font-weight-light">
                    {{ item.ativo | statusActiveName }}
                  </div>
                </div>
              </v-col>
            </v-row>
            <div class="pa-1 text-center">
              <div class="font-weight-bold">
                Descrição
              </div>
              <div class="font-weight-light">
                <div>
                  {{ item.descricao | filtraExistencia }}
                </div>
              </div>
            </div>
            <div>
              <v-row justify="center" align="center" class="text-center">
                <v-col cols="6">
                  <v-btn
                    v-if="item.ativo"
                    text
                    color="red"
                    :disabled="acoesOcupadas"
                    @click="desativarItem(item)"
                    >Desativar item</v-btn
                  >
                  <v-btn
                    v-if="!item.ativo"
                    text
                    color="green"
                    :disabled="acoesOcupadas"
                    @click="ativarItem(item)"
                    >Ativar item</v-btn
                  >
                </v-col>
                <v-col cols="6">
                  <v-btn
                    :disabled="acoesOcupadas"
                    text
                    color="primary"
                    @click="openEditItem(item)"
                    >Editar item</v-btn
                  >
                </v-col>
              </v-row>
            </div>
          </v-card>
        </div>
      </div>
    </div>
    <vNovoItem
      v-if="modalNovoItem"
      @update="getTipo"
      @close="modalNovoItem = false"
    />
    <vEditItem
      v-if="modalEditarItem"
      :item="itemEdit"
      @update="getTipo"
      @close="modalEditarItem = false"
    />
  </div>
</template>

<script>
import serviceServices from "@/services/servicoController";
import vNovoItem from "./vNovoItemDoPlano.vue";
import vEditItem from "./editar.vue";
export default {
  components: {
    vNovoItem,
    vEditItem
  },
  filters: {
    filtraExistencia(val) {
      if (val) {
        return val;
      } else {
        return "S/ DESCRIÇÃO";
      }
    },
    statusActiveName(val) {
      if (val) {
        return "Ativo";
      } else {
        return "Desativado";
      }
    },
    filtraBoolean(val) {
      if (val) {
        return "Sim";
      } else {
        return "Não";
      }
    }
  },
  data() {
    return {
      listaSelected: [
        { code: "", nome: "Todos" },
        { code: false, nome: "Desativado" },
        { code: true, nome: "Ativo" }
      ],
      statusSelecionado: "",
      modalNovoItem: false,
      modalEditarItem: false,
      acoesOcupadas: false,
      loading: true,
      itemEdit: {},
      buscaNome: "",
      itensDoPlano: []
    };
  },
  computed: {
    getItens() {
      return this.itensDoPlano.filter(item => {
        let name = item.nome.toUpperCase();
        let search = this.buscaNome.toUpperCase();
        let status = String(item.ativo);
        let selectStatus = String(this.statusSelecionado);
        return name.includes(search) && status.includes(selectStatus);
      });
    }
  },
  mounted() {
    this.getTipo();
  },
  methods: {
    ativarItem(item) {
      this.acoesOcupadas = true;
      let copyItem = { ...item };
      copyItem.ativo = true;
      serviceServices
        .setItemPlano(copyItem)
        .then(() => {
          this.$root.showInfo("Item ativo com sucesso");
        })
        .finally(() => {
          this.getTipo();
          this.acoesOcupadas = false;
        })
        .catch(err => {
          this.$root.showInfo("Erro: " + err.data);
        });
    },
    desativarItem(item) {
      this.acoesOcupadas = true;
      let copyItem = { ...item };
      copyItem.ativo = false;
      serviceServices
        .setItemPlano(copyItem)
        .then(() => {
          this.$root.showInfo("Item ativo com sucesso");
        })
        .finally(() => {
          this.getTipo();
          this.acoesOcupadas = false;
        })
        .catch(err => {
          this.$root.showInfo("Erro: " + err.data);
        });
    },
    openEditItem(item) {
      this.itemEdit = item;
      this.modalEditarItem = true;
    },
    openNovoItem() {
      this.modalNovoItem = true;
    },
    getTipo() {
      this.acoesOcupadas = true;
      serviceServices
        .getItensDoPlano()
        .then(resp => {
          this.itensDoPlano = resp.data;
        })
        .finally(() => {
          this.loading = false;
          this.acoesOcupadas = false;
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style></style>
