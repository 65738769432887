<template>
  <div>
    <v-titulo>
      Pagamentos
    </v-titulo>
    <div v-if="loadingContent" class="pa-5 ma-5 text-center">
      <v-progress-circular
        color="primary"
        indeterminate
        size="70"
      ></v-progress-circular>
    </div>
    <div v-else class="noload">
      <div class="containCards ma-2">
        <v-card
          class="lowShadow pa-3 ma-2 rounded-lg cardSelect align-center text-center"
        >
          <div>
            <v-avatar rounded color="blue darken-1" class="white--text">
              <v-icon color="white" size="28">
                mdi-clock
              </v-icon>
            </v-avatar>
          </div>
          <div class="pl-4">
            <div class="text-h4 font-weight-bold">
              {{ contadores.qtdeAutorizado }}
            </div>
            <div class="text-body-2">
              Aguardando Pagamento
            </div>
          </div>
        </v-card>
        <v-card class="lowShadow  rounded-lg cardSelect">
          <div>
            <v-avatar rounded color="orange darken-2" class="white--text">
              <v-icon color="white" size="28">
                mdi-timeline-remove
              </v-icon>
            </v-avatar>
          </div>
          <div class="pl-4">
            <div class="text-h4 font-weight-bold">
              {{ contadores.qtdeRecusado }}
            </div>
            <div class="text-body-2">
              Recusado
            </div>
          </div>
        </v-card>
        <v-card
          class="lowShadow rounded-lg cardSelect align-center text-center"
        >
          <v-avatar
            rounded
            color="green darken-2"
            class="white--text font-weight-bold"
          >
            <v-icon color="white" size="28">mdi-check</v-icon>
          </v-avatar>
          <div class="pl-4">
            <div class="text-h4 font-weight-bold">
              {{ contadores.qtdePago }}
            </div>
            <div class="text-body-2">
              Pago
            </div>
          </div>
        </v-card>
        <v-card
          class="lowShadow rounded-lg cardSelect align-center text-center"
        >
          <div>
            <v-avatar
              rounded
              color="red darken-2"
              class="white--text font-weight-bold"
            >
              <v-icon color="white">mdi-close</v-icon>
            </v-avatar>
          </div>
          <div class="pl-4">
            <div class="text-h4 font-weight-bold">
              {{ contadores.qtdeCancelado }}
            </div>
            <div class="text-body-2">
              Cancelado
            </div>
          </div>
        </v-card>
      </div>
      <div class="Containfilters">
        <div class="ContainForms">
          <div>
            <v-text-field
              v-model="filtros.turmaId"
              dense
              label="Evento ID"
            ></v-text-field>
          </div>
          <div>
            <v-text-field
              v-model="filtros.nomeTurma"
              dense
              label="Nome do Evento/Turma"
            ></v-text-field>
          </div>
          <div>
            <v-text-field
              v-model="filtros.dataPagamento"
              dense
              type="date"
              label="Data Pagamento"
            ></v-text-field>
          </div>
          <div>
            <v-select
              v-model="filtros.status"
              :items="listaStatusPagamento"
              dense
              label="Status"
              item-text="text"
              item-value="value"
            ></v-select>
          </div>
        </div>
        <div class="ContainActions">
          <span class="mr-2">
            <v-btn color="primary" outlined @click="clearFilters()"
              >Limpar filtros</v-btn
            >
          </span>
          <v-btn color="primary" depressed @click="novaBuscaSolicitacoes()"
            >Buscar</v-btn
          >
        </div>
      </div>
      <div class="lista mx-3 mb-3">
        <div>
          <v-data-table
            :headers="headers"
            :items="solicitacoes"
            :options.sync="options"
            :items-per-page="maxPage"
            :server-items-length="totalSolicitacoes"
            :loading="loadingList"
            :footer-props="{
              itemsPerPageOptions: [20, 50, 100]
            }"
            class="lowShadow"
            style="width: 100%; overflow-x: auto; border: 1px solid rgba(50,50,50,0.2)"
          >
            <template v-slot:body="{ items }">
              <tbody style="width: 100%; overflow-x: auto;">
                <tr v-for="item in items" :key="item.name">
                  <td>
                    <div title="ID da Solicitação" class="fsize-3 text-center">
                      {{ item.id }}
                    </div>
                  </td>
                  <td>
                    <div title="ID do Evento" class="fsize-3 text-center">
                      {{ item.turmaId }}
                    </div>
                  </td>
                  <td>
                    <div class="fsize-3 text-left" style="width: 300px">
                      {{ item.nomeTurma }}
                    </div>
                  </td>
                  <td>
                    <div class="fsize-3">
                      {{ getStatusName(item.status) }}
                    </div>
                  </td>
                  <td>
                    <vTipoPagamento :tipo="item.tipo" />
                  </td>
                  <td>
                    <div class="fsize-3" style="min-width: 80px">
                      {{ item.valor | currency }}
                    </div>
                  </td>
                  <td>
                    <div class="fsize-3">{{ item.dataPagamento | moment }}</div>
                  </td>
                  <td>
                    <div class="fsize-3">
                      {{ item.dataPagamentoEfetuado | moment }}
                    </div>
                  </td>
                  <td>
                    <div class="fsize-3" style="min-width: 80px">
                      {{ item.saldoTurma | currency }}
                    </div>
                  </td>
                  <td>
                    <div class="fsize-3" style="width: 250px">
                      {{ item.descricao }}
                    </div>
                  </td>
                  <td>
                    <div class="fsize-3" style="width: 150px">
                      {{ item.nomeFornecedor || item.destino || "S/ REGISTRO" }}
                    </div>
                  </td>
                  <td class="text-center">
                    <v-avatar
                      size="30"
                      class="mr-1 clique"
                      color="primary"
                      @click="selecionaItem(item.id)"
                    >
                      <v-icon size="20" color="white" title="Detalhes"
                        >mdi-card-text</v-icon
                      >
                    </v-avatar>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
    <vDetalhes
      v-if="modalDetalhes"
      :uid="itemSelecionado"
      @atualiza="atualizarContadoresESolicitacoes"
      @close="modalDetalhes = false"
    >
    </vDetalhes>
  </div>
</template>

<script>
import {
  getListStatusSolicitacaoPagto,
  getStatusNameSolicitacaoPagto
} from "@/constantes/statusSolicitacaoPagamento";
import serviceSolicitacoes from "@/services/solicitacoes";
import moment from "moment";
import vDetalhes from "./vCardDetalhes.vue";
import vTipoPagamento from "./vTipoPagamento.vue";

export default {
  components: {
    vDetalhes,
    vTipoPagamento
  },
  filters: {
    moment: function(date) {
      if (date) {
        return moment(date).format("DD/MM/YYYY");
      }
      return "S/DATA";
    }
  },
  data() {
    return {
      itemSelecionado: 0,
      modalDetalhes: false,
      loadingContent: true,
      loadingList: true,
      solicitacoes: [],
      listaTipoPagamento: [
        { text: "Todos", value: null },
        { text: "Fornecedores", value: "FORNECEDOR" },
        { text: "Transferências Internas", value: "TRANSFERENCIA_INTERNA" }
      ],
      listaStatusPagamento: [],
      options: {},
      headers: [
        { text: "Solicitação ID", value: "id", align: "left" },
        { text: "Evento ID", value: "turmaId", align: "center" },
        { text: "Evento", value: "nomeTurma", align: "center" },
        { text: "Status", value: "status", align: "left" },
        { text: "Tipo de Pagto", value: "tipo", align: "center" },
        { text: "Valor", value: "valor", align: "left" },
        {
          text: "Data de Lançamento",
          value: "dataPagamento",
          align: "left"
        },
        {
          text: "Data do Pagto",
          value: "dataPagamentoEfetuado",
          align: "left"
        },
        { text: "Saldo da Turma", value: "saldoTurma", align: "left" },
        { text: "Descrição", value: "descricao", align: "left" },
        { text: "Prestador", value: "descricao", align: "left" },
        { text: "Ações", align: "center", value: "" }
      ],
      contadores: {
        qtdeAutorizado: 0,
        qtdeCancelado: 0,
        qtdePago: 0,
        qtdeRecusado: 0,
        qtdeSemSaldo: 0
      },
      page: 1,
      maxPage: 10,
      totalPage: 0,
      filtros: {
        dataPagamento: null,
        turmaId: null,
        nomeTurma: null,
        status: null
      }
    };
  },
  watch: {
    options: {
      handler(d) {
        this.page = d.page;
        this.maxPage = d.itemsPerPage;
        this.receberContadores();
        this.novaBuscaSolicitacoes();
      },
      deep: true
    }
  },
  mounted() {
    this.listaStatusPagamento = getListStatusSolicitacaoPagto();
    this.startList();
  },
  methods: {
    clearFilters() {
      this.filtros = {
        dataPagamento: null,
        nomeTurma: null,
        status: null,
        turmaId: null
      };
    },
    atualizarContadoresESolicitacoes() {
      this.receberContadores();
      this.novaBuscaSolicitacoes();
    },
    selecionaItem(uid) {
      this.itemSelecionado = uid;
      this.modalDetalhes = true;
    },
    getStatusName(code) {
      return getStatusNameSolicitacaoPagto(code);
    },
    tratamentoFiltros() {
      let filtrosPayload = { ...this.filtros };
      if (filtrosPayload.id) {
        filtrosPayload.id = Number(filtrosPayload.id);
      }
      if (filtrosPayload.turmaId) {
        filtrosPayload.turmaId = Number(filtrosPayload.turmaId);
      }
      if (!moment(filtrosPayload.dataPagamento).isValid()) {
        this.filtros.dataPagamento = null;
        filtrosPayload.dataPagamento = null;
      }
      if (
        filtrosPayload.dataPagamentoEfetuado &&
        !moment(filtrosPayload.dataPagamentoEfetuado).isValid()
      ) {
        this.filtros.dataPagamentoEfetuado = null;
        filtrosPayload.dataPagamentoEfetuado = null;
      }
      return filtrosPayload;
    },
    receberContadores() {
      serviceSolicitacoes
        .getContadoresSolicitacoes()
        .then(resp => {
          this.contadores = resp.data;
        })
        .catch(err => {
          this.$root.showError(err);
        });
    },
    startList() {
      this.getServiceListSolicitacoes(
        this.page - 1,
        this.maxPage,
        this.filtros
      );
    },
    novaBuscaSolicitacoes(validate = false) {
      if (validate) {
        this.tratamentoFiltros();
      }
      this.getServiceListSolicitacoes(
        this.page - 1,
        this.maxPage,
        this.filtros
      );
    },
    getServiceListSolicitacoes(page, maxPage, filtros) {
      this.loadingList = true;
      serviceSolicitacoes
        .getSolicitacoesPagination(page, maxPage, filtros)
        .then(resp => {
          this.solicitacoes = resp.data.content;
          this.totalSolicitacoes = resp.data.totalElements;
        })
        .finally(() => {
          this.loadingContent = false;
          this.loadingList = false;
        })
        .catch(err => {
          console.log(err);
          this.$root.showError(err.data);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.ContainActions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.ContainForms {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}
.Containfilters {
  background-color: rgb(255, 255, 255);
  padding: 10px 20px;
  padding-top: 25px;
  border: 1px solid rgba(50, 50, 50, 0.2);
  border-radius: 6px;
  margin: 10px;
}

.customPaginationSize {
  font-size: 10px;
}

.containCards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  vertical-align: center;

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 512px) {
    grid-template-columns: 1fr;
  }
}

.cardSelect {
  border: 3px solid rgba(120, 37, 153, 0);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  min-height: 120px;
  text-align: center;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  vertical-align: center;
  margin: 6px;
  @media screen and (max-width: 512px) {
    grid-template-columns: 1fr;
  }
}
</style>
