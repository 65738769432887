import axios from "axios";

// function entityAgencia(payload, edit) {
//   let agencia = {
//     cnpj: payload.cnpj,
//     enderecoCompleto: {
//       bairro: payload.enderecoCompleto.bairro,
//       cep: payload.enderecoCompleto.cep,
//       cidadeIbge: payload.enderecoCompleto.cidadeIbge,
//       cidadeNome: payload.enderecoCompleto.cidadeNome,
//       complemento: payload.enderecoCompleto.complemento,
//       estado: payload.enderecoCompleto.estado,
//       logradouro: payload.enderecoCompleto.logradouro,
//       numero: payload.enderecoCompleto.numero
//     },
//     nome: payload.nome,
//     responsavel: payload.responsavel,
//     telefone: payload.telefone
//   };
//   if (edit) {
//     if (!payload.id) {
//       return false;
//     }
//     agencia.id = payload.id;
//   }
//   return agencia;
// }

export default {
  contasAReceber(agencia_id) {
    return axios.get(`/v1/agencia/contas-a-receber/${agencia_id}`);
  },
  alterarAgencia(payload) {
    return axios.post("/v1/agencia", payload);
  },
  cadastrarAgencia(payload) {
    return axios.post("/v1/agencia", payload);
  },
  getAgencias() {
    return axios.get("/v1/agencia");
  },
  getAgenciaById(agenciaId) {
    return axios.get(`/v1/agencia/${agenciaId}`);
  },
  searchAgencia(payload) {
    return axios.post("/v1/agencia/search", { texto: payload });
  }
};
