<template>
  <div>
    <div v-if="step == 2" class="pa-3 px-6">
      <v-text-field
        v-model="include.nome"
        label="Nome do evento *"
      ></v-text-field>
      <v-text-field
        v-model="include.tipoEventoDescricao"
        label="Descrição  *"
      ></v-text-field>
      <div class="pt-2 text-right">
        <v-btn
          depressed
          color="primary"
          :loading="loadingStep"
          @click="proximaEtapa()"
          >PRÓXIMA ETAPA</v-btn
        >
      </div>
    </div>
    <div v-if="step == 3" class="pa-3">
      <v-row>
        <v-col cols="12" sm="8">
          <v-text-field
            v-model="include.enderecoCompleto.cep"
            v-mask="['#####-###']"
            hide-details
            label="CEP"
            :loading="loadingCEP"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" class="text-center">
          <v-btn
            :loading="loadingCEP"
            color="#9B51E0"
            depressed
            dark
            large
            @click="buscarCEP()"
            >Buscar CEP</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-select
            v-model="include.estadoId"
            hide-details
            :items="listaEstados"
            label="Estado *"
            item-text="nome"
            item-value="id"
            :loading="loadingEstado"
            no-data-text="Carregando"
            @change="onChangeEstado()"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            v-model="include.cidadeId"
            hide-details
            :items="listaCidades"
            label="Cidade * "
            item-text="descricao"
            item-value="id"
            no-data-text="Pesquise o estado primeiro"
            :loading="loadingCidades"
            @change="onChangeCidade()"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="include.enderecoCompleto.logradouro"
            hide-details
            label="logradouro"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="include.enderecoCompleto.bairro"
            hide-details
            label="Bairro"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="include.enderecoCompleto.numero"
            hide-details
            label="Número"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="include.enderecoCompleto.complemento"
            hide-details
            label="complemento"
          />
        </v-col>
      </v-row>
      <div class="text-right pt-3 mt-3">
        <v-btn
          color="primary"
          dark
          large
          :loading="loadingStep"
          @click="proximaEtapa()"
          >Próximo</v-btn
        >
      </div>
    </div>
    <div v-if="step == 4">
      <vAdapterConfigNotification
        @setConfigAndRegister="processRegisterAndConfig"
      />
    </div>
  </div>
</template>

<script>
import EVENTOS from "@/constantes/EventosType";
import serviceLocal from "@/services/localidade";
import serviceTurma from "@/services/turma";
import vAdapterConfigNotification from "./adapterConfigNotification.vue";

export default {
  components: {
    vAdapterConfigNotification
  },
  props: {
    step: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loadingStep: false,
      loadingCidades: false,
      loadingEstado: false,
      loadingCEP: false,
      listaEstados: [],
      listaCidades: [],
      include: {
        nome: "",
        tipoEventoDescricao: "",
        estadoId: "",
        cidadeId: "",
        tipoEvento: EVENTOS.OUTRO,
        enderecoCompleto: {
          logradouro: "",
          cidadeNome: "",
          bairro: "",
          estado: "",
          numero: "",
          complemento: "",
          cep: "",
          cidadeIbge: 0
        }
      }
    };
  },
  computed: {
    getEstadoSetadoToggle() {
      return this.include.estadoId ? false : true;
    }
  },
  mounted() {
    this.$emit("setStep", 2);
    this.$emit("setMaxStep", 4);
  },
  beforeMount() {
    this.buscaEstado();
  },
  methods: {
    processRegisterAndConfig(config) {
      this.include.config = { ...config };
      this.cadastrar();
    },
    cadastrar() {
      let CEPTratamento = this.include.enderecoCompleto.cep;
      if (CEPTratamento.includes("-")) {
        let tratamento1 = CEPTratamento.replace("-", "");
        this.include.enderecoCompleto.cep = tratamento1;
      }
      serviceTurma
        .novoEvento(this.include)
        .then(resp => {
          this.loadingStep = false;
          this.$emit("concluido", resp.data.codigoConvite);
        })
        .catch(err => {
          this.$root.showError(err.data);
        });
    },
    verificaEtapa_1() {
      if (!this.include.nome && this.include.nome.length < 3) {
        this.$root.showError("Nome inválido");
        this.loadingStep = false;
        return false;
      }
      if (
        !this.include.tipoEventoDescricao ||
        this.include.tipoEventoDescricao.length < 5
      ) {
        this.$root.showError("Descrição do evento muito pequena");
        this.loadingStep = false;
        return false;
      }
      return true;
    },
    verificaEtapa_2() {
      if (this.include.enderecoCompleto.cep.length !== 9) {
        this.$root.showError("CEP inválido");
        this.loadingStep = false;
        return false;
      }
      if (
        !this.include.enderecoCompleto.logradouro ||
        this.include.enderecoCompleto.logradouro.length < 3
      ) {
        this.$root.showError("Logradouro inválido");
        this.loadingStep = false;
        return false;
      }
      if (
        !this.include.enderecoCompleto.bairro ||
        this.include.enderecoCompleto.bairro.length < 3
      ) {
        this.$root.showError("Bairro inválido");
        this.loadingStep = false;
        return false;
      }
      if (
        !this.include.enderecoCompleto.cep ||
        this.include.enderecoCompleto.cep.length !== 9
      ) {
        this.$root.showError("CEP inválido");
        this.loadingStep = false;
        return false;
      }
      return true;
    },
    proximaEtapa() {
      this.loadingStep = true;
      if (this.step == 2) {
        if (!this.verificaEtapa_1()) return false;
        this.loadingStep = false;
        this.$emit("setStep", this.step + 1);
      }

      if (this.step == 3) {
        if (!this.verificaEtapa_2()) return false;
        this.$emit("setStep", this.step + 1);
      }
      this.loadingStep = false;
    },
    buscaEstado() {
      this.loadingEstado = true;
      serviceLocal
        .getEstado()
        .then(resp => {
          this.listaEstados = resp.data;
          this.loadingEstado = false;
        })
        .catch(err => {
          this.$root.showError(err.data);
          this.loadingEstado = false;
        });
    },
    buscaCidade() {
      serviceLocal
        .getCidadeForEstado(this.include.estadoId)
        .then(resp => {
          this.loadingCidades = false;
          this.listaCidades = resp.data;
        })
        .catch(err => {
          this.$root.showError(err.data);
          this.loadingCidades = false;
        });
    },
    onChangeEstado() {
      this.loadingCidades = false;
      this.include.enderecoCompleto.estado = String(this.include.estadoId);
      this.buscaCidade();
    },
    onChangeCidade() {
      this.include.enderecoCompleto.cidadeNome = String(this.include.cidadeId);
    },
    buscaCidadeAndFind(payload) {
      serviceLocal
        .getCidadeForEstado(this.include.estadoId)
        .then(resp => {
          this.loadingCidades = false;
          this.listaCidades = resp.data;
          let payloadCidade = this.listaCidades.find(
            item =>
              item.descricao.toUpperCase() === payload.localidade.toUpperCase()
          );
          this.include.cidadeId = payloadCidade.id;
          this.include.enderecoCompleto.cidadeNome = String(payloadCidade.id);
          this.loadingCEP = false;
        })
        .catch(err => {
          this.$root.showError(err.data);
          this.loadingCidades = false;
          this.loadingCEP = false;
        });
    },
    proccessCityAndState(dados) {
      if (this.listaEstados.length > 0) {
        let payloadEstado = this.listaEstados.find(item => item.uf == dados.uf);
        this.include.estadoId = payloadEstado.id;
        this.include.enderecoCompleto.estado = String(payloadEstado.id);
        this.buscaCidadeAndFind(dados);
      } else {
        this.buscaEstado();
        setTimeout(() => {
          this.proccessCityAndState(dados);
        }, 500);
      }
    },
    processarDadosDoCEP(dados) {
      this.loadingCEP = true;
      this.include.enderecoCompleto.bairro = dados.bairro;
      this.include.enderecoCompleto.logradouro = dados.logradouro;
      this.include.enderecoCompleto.cidadeIbge = Number(dados.ibge);
      this.include.enderecoCompleto.bairro = dados.bairro;
      this.proccessCityAndState(dados);
    },
    buscarCEP() {
      let CEPToSearch = this.include.enderecoCompleto.cep;
      if (CEPToSearch.length !== 9) {
        this.$root.showError("CEP inválido");
        return false;
      }
      if (CEPToSearch.includes("-")) {
        CEPToSearch = CEPToSearch.replace("-", "");
      }
      serviceLocal
        .getCep(CEPToSearch)
        .then(resp => {
          this.processarDadosDoCEP(resp.data);
        })
        .catch(err => {
          console.log(err);
          this.$root.showError(err.data);
        });
    }
  }
};
</script>

<style></style>
