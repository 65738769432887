import axios from "axios";

export default {
  getTurmaConfig: () => {
    return axios.get("/turmaConfig");
  },
  updateTurmaConfig: turmaConfig => {
    return axios.put("/turmaConfig", turmaConfig);
  },
  getConfigNotification(turmaId) {
    return axios.get(`/v1/turma-config/${turmaId}/notificacao`);
  },
  updateConfigNotification(payload) {
    return axios.post(`/v1/turma-config/notificacao`, {
      formaEnvio: payload.formaEnvio,
      notificacoes: payload.notificacoes,
      turmaId: payload.turmaId
    });
  }
};
