<template>
  <div>
    <div>
      <vTitulo>Estatística FCZ</vTitulo>
    </div>
    <div>
      <iframe
        no-cache
        class="screenmax"
        title="metabase"
        :src="iframeUrl"
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      iframeUrl: null
    };
  },
  mounted() {
    this.iframeUrl = null;
    this.configMETABASE();
  },
  methods: {
    getParamsMetabase() {
      let user = this.$store.getters["gestao/getUser"];
      if (user.role == "AGENCIA") {
        return {
          agencia_id: user.agenciaId
        };
      } else {
        return {};
      }
    },
    configMETABASE() {
      var jwt = require("jsonwebtoken");

      var METABASE_SITE_URL = "https://metabase.appdaturma.com.br";
      var METABASE_SECRET_KEY =
        "8cde899bc34c99ab742f6ffdb39d796171faadae91aa704a62eb9df84000a791";

      var payload = {
        resource: { dashboard: 16 },
        params: {},
        exp: Math.round(Date.now() / 1000) + 10 * 60 // 10 minute expiration
      };

      var token = jwt.sign(payload, METABASE_SECRET_KEY);

      let mountURL =
        METABASE_SITE_URL +
        "/embed/dashboard/" +
        token +
        "#bordered=true&titled=true";
      this.iframeUrl = mountURL;
    }
  }
};
</script>

<style lang="scss" scoped>
.screenmax {
  display: block;
  width: 100%;
  border: none;
  margin: 0px auto;
  height: 100vh;
  background-color: white;
}
</style>
