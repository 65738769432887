<template>
  <div class="baseToolbar">
    <v-btn depressed @click="backMenu()">
      <v-icon size="14px">mdi-arrow-left</v-icon>
      <span style="margin-left: 5px">Voltar</span></v-btn
    >
  </div>
</template>

<script>
export default {
  methods: {
    backMenu() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.baseToolbar {
  padding: 10px;
  margin-top: 10;
}
</style>
