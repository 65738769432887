<template>
  <div class="contentSaldo">
    {{ getSaldo }}
  </div>
</template>

<script>
export default {
  name: "VTagSaldoFCZ",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    getSaldo() {
      if (this.item.aceiteFCZ) {
        return Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL"
        }).format(this.item.saldoBilhete);
      } else {
        return "-";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.contentSaldo {
  width: 100%;
  text-align: center;
}
</style>
