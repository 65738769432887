import axios from "axios";

export default {
  premiosPorConcurso(concursoId) {
    return axios.get(`/v1/premio/concurso/${concursoId}`);
  },
  sortearPremio(premioId, numerosSorteados) {
    return axios.post("/v1/premio/sorteio", {
      numerosSorteados: `${numerosSorteados}`,
      premioId: premioId
    });
  }
};
