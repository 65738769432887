<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="700px"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card class="white pa-5 pt-0">
        <vModalTitle
          title="Registrar sorteio"
          has-close
          @close="$emit('close')"
        />
        <div class="px-5 py-3">
          <v-row class="centralizaTudo">
            <v-col cols="12" sm="6">
              <div class="font-weight-light text-uppercase">Prêmio</div>
              <div class="font-weight-bold">
                {{ payload.descricao }}
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <div class="font-weight-light text-uppercase">Nº do Concurso</div>
              <div class="font-weight-bold">{{ payload.concursoId }}</div>
            </v-col>
          </v-row>
          <div>
            <div class="pa-2 d-inline mx-auto centralizaTudo">
              <div class="mt-3">
                <div class="font-weight-light">
                  Nº da Loteria Federal
                </div>
                <v-text-field
                  v-model="form.numLoteriaFederal"
                  v-mask="'######'"
                  style="max-width: 150px; margin: 0 auto;"
                  placeholder="6 dígitos"
                  class="centralizaTudo"
                  outlined
                ></v-text-field>
              </div>
              <div class="alertaNovoSorteio">
                <strong>Atenção:</strong> Após o lançamento não será mais
                possivel alterar o sorteio, certifique-se de que o Numero da
                Loteria Federal esteja correto
              </div>
              <div class="text-center mt-4">
                <v-btn
                  class="buttonCustom"
                  color="primary"
                  depressed
                  large
                  :loading="loadingSortear"
                  @click="abrirConfirmar()"
                  >Sortear</v-btn
                >
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <vModalConfirmVue
      v-if="dialogConfirmar"
      :num-loteria-federal="form.numLoteriaFederal"
      @close="dialogConfirmar = false"
      @confirmar="sortear()"
    />
  </div>
</template>

<script>
// NUMERO FEDERAL SORTEADO 6 NUMEROS
// CONFIRMAR O NUMERO SORTEADO (AVISAR QUE NÃO PODE SER ALTERADO APOS O LANÇAMENTO)
import ServicePremio from "@/services/premio";
import vModalConfirmVue from "./vConfirmSortear.vue";

export default {
  components: {
    vModalConfirmVue
  },
  props: {
    payload: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loadingSortear: false,
      dialogConfirmar: false,
      dialog: true,
      form: {
        concurso: null,
        premio: null,
        numLoteriaFederal: null
      }
    };
  },
  mounted() {
    if (this.payload) {
      this.form.concurso = this.payload.concursoId;
      this.form.premio = this.payload.id;
    } else {
      this.$emit("close");
    }
  },
  methods: {
    validacao() {
      if (!this.form.numLoteriaFederal) {
        this.$root.showError("Favor informar o Número da Loteria Federal");
        return false;
      }
      if (this.form.numLoteriaFederal.length !== 6) {
        this.$root.showError(
          "Favor informar o Número da Loteria Federal corretamente, deve conter 6 digitos"
        );
        return false;
      }
      return true;
    },
    abrirConfirmar() {
      if (!this.validacao()) {
        return false;
      }
      this.dialogConfirmar = true;
    },
    sortear() {
      this.loadingSortear = true;
      this.dialogConfirmar = false;
      ServicePremio.sortearPremio(this.form.premio, this.form.numLoteriaFederal)
        .then(resp => {
          this.loadingSortear = false;
          this.$root.showSucesso(resp.data ? resp.data : resp);
          this.$emit("update");
          this.$emit("close");
        })
        .catch(err => {
          this.loadingSortear = false;
          this.$root.showError(err);
        });
    },
    buscarPremios(concursoId) {
      //ServicePremio;
    }
  }
};
</script>
<style lang="scss" scoped>
.buttonCustom {
  width: 200px;
  @media screen and (max-width: 600px) {
    width: 90%;
  }
}
.alertaNovoSorteio {
  max-width: 100%;
  margin: 0 auto;
  font-size: 14px;
  letter-spacing: 0.4px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 5px;
  padding: 15px;
  background-color: rgb(255, 150, 0, 0.15);
  color: rgb(155, 90, 0, 1);
  //border: 1px solid rgb(255, 150, 0, 0.3);
  border-radius: 3px;
  text-align: left;
}
</style>
