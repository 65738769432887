import axios from "axios";

export default {
  setSolicitacaoRecusada(solicitacaoId) {
    return axios.post(
      `/v1/solicitacao-pagamento/${solicitacaoId}/recusar-pagamento`
    );
  },
  setSolicitacaoPaga(solicitacaoId) {
    return axios.post(
      `/v1/solicitacao-pagamento/${solicitacaoId}/confirmar-pagamento`
    );
  },
  enviarAnexo(solicitacaoId, formData) {
    return axios.post(
      `/v1/solicitacao-pagamento/${solicitacaoId}/anexo`,
      formData
    );
  },
  removerAnexo(solicitacaoId, anexoId) {
    return axios.delete(
      `/v1/solicitacao-pagamento/${solicitacaoId}/anexo/${anexoId}`
    );
  },
  getContadoresSolicitacoes() {
    return axios.post("/v1/solicitacao-pagamento/counters");
  },
  getSolicitacaoPorID(solicitacaoId) {
    return axios.get(`/v1/solicitacao-pagamento/${solicitacaoId}`);
  },
  getSolicitacoesPagination(page, pageSize, filtros) {
    return axios.post("/v1/solicitacao-pagamento/pagination", {
      pageNo: page,
      pageSize: pageSize,
      sortBy: "string",
      filtros: {
        dataPagamento: filtros.dataPagamento,
        nomeTurma: filtros.nomeTurma,
        status: filtros.status,
        turmaId: filtros.turmaId
      }
    });
  },
  setComentario(solicitacaoId, comentario) {
    return axios.post("/v1/solicitacao-pagamento/add-comentario", {
      comentario: comentario,
      solicitacaoId: solicitacaoId
    });
  },
  getComentarios() {}
};
