<template>
  <div>
    <div class="grid grid-col-1-auto align-center px-4 py-3">
      <div class="titleHeaderModal">Configuração de notificação</div>
      <div></div>
    </div>
    <v-divider class="mb-2"></v-divider>
    <div class="px-4 pb-4">
      <div>
        <div class="sectionTitle">
          De que forma as notificações serão enviadas
        </div>
        <div class="sectionSubtitle">
          Selecione abaixo a forma de envio das notificações.
        </div>
        <div class="listItens">
          <vDinamicChip
            :config-bol="configuracoes.formaEnvio.whatsapp"
            :icon="'mdi-whatsapp'"
            :text="'Whatsapp'"
            @action="toggleConfiguracao('whatsapp')"
          />
          <vDinamicChip
            :config-bol="configuracoes.formaEnvio.email"
            :icon="'mdi-email-outline'"
            :text="'Email'"
            @action="toggleConfiguracao('email')"
          />
          <vDinamicChip
            :config-bol="configuracoes.formaEnvio.sms"
            :icon="'mdi-cellphone'"
            :text="'SMS'"
            @action="toggleConfiguracao('sms')"
          />
        </div>
      </div>
      <div>
        <div class="sectionTitle">Quais notificações serão enviadas?</div>
        <div class="sectionSubtitle">
          Abaixo você tem todas as notificações que podem ser enviadas para seu
          cliente, escolha por onde seu cliente recebera as notificações.
        </div>

        <div>
          <div
            v-for="(item, index) in listaItens"
            :key="item.id"
            class="tableItem"
          >
            <div class="sectionSubtitle">
              {{ getDescriptionForId(item.notificacaoId) }}
            </div>
            <div class="listItens pt-0">
              <vDinamicChip
                :config-bol="item.whatsapp"
                :icon="'mdi-whatsapp'"
                :text="'Whatsapp'"
                :disabled="!configuracoes.formaEnvio.whatsapp"
                @action="toggleConfig(index, 'whatsapp')"
              />
              <vDinamicChip
                :config-bol="item.email"
                :icon="'mdi-email-outline'"
                :text="'Email'"
                :disabled="!configuracoes.formaEnvio.email"
                @action="toggleConfig(index, 'email')"
              />
              <vDinamicChip
                :config-bol="item.sms"
                :icon="'mdi-cellphone'"
                :text="'SMS'"
                :disabled="!configuracoes.formaEnvio.sms"
                @action="toggleConfig(index, 'sms')"
              />
            </div>
          </div>
        </div>
      </div>
      <div id="actions" class="pt-5 text-right">
        <v-btn
          color="primary"
          depressed
          :loading="loading"
          @click="finalizarCadastro()"
        >
          Finalizar cadastro
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceUser from "@/services/userController";
import vDinamicChip from "@/views/dashboard/config-notificacoes/vDinamicChip.vue";

export default {
  components: {
    vDinamicChip
  },
  data() {
    return {
      listaUsersId: [],
      loading: false,
      listaItens: [
        { notificacaoId: 1, whatsapp: false, email: false, sms: false },
        { notificacaoId: 2, whatsapp: false, email: false, sms: false },
        { notificacaoId: 3, whatsapp: false, email: false, sms: false },
        { notificacaoId: 4, whatsapp: false, email: false, sms: false },
        { notificacaoId: 5, whatsapp: false, email: false, sms: false },
        { notificacaoId: 6, whatsapp: false, email: false, sms: false }
      ],
      dialog: true,
      configuracoes: {
        formaEnvio: {
          whatsapp: false,
          email: false,
          sms: false
        }
      },
      listaDescription: [
        { id: 1, description: "Avisar com 5 dias antes do vencimento" },
        { id: 2, description: "Avisar no dia do vencimento" },
        { id: 3, description: "Quando um pagamento for efetuado" },
        { id: 4, description: "Avisar quando novas cobranças forem geradas." },
        { id: 5, description: "Lembrete de atraso" },
        { id: 6, description: "Avisar quando alguém adquirir um bilhete" }
      ]
    };
  },
  methods: {
    carregarInformacoes() {
      ServiceUser.getUserConfigNotifications(this.selecionados[0].usuarioId)
        .then(resp => {
          if (resp.data.formaEnvio) {
            this.configuracoes.formaEnvio = resp.data.formaEnvio;
          }
          if (resp.data.notificacoes.length > 0) {
            this.listaItens = resp.data.notificacoes;
          }
        })
        .catch(err => {
          console.log(err);
          this.$root.showError(err.data.message);
        });
    },
    getDescriptionForId(id) {
      return this.listaDescription.find(item => item.id === id).description;
    },
    finalizarCadastro() {
      const payload = {
        formaEnvio: this.configuracoes.formaEnvio,
        notificacoes: this.listaItens.map(item => {
          return {
            notificacaoId: item.notificacaoId,
            whatsapp: item.whatsapp,
            email: item.email,
            sms: item.sms
          };
        })
      };
      this.loading = true;
      this.$emit("setConfigAndRegister", payload);
    },
    toggleConfig(index, type) {
      this.listaItens[index][type] = !this.listaItens[index][type];
    },
    toggleConfiguracao(name) {
      this.configuracoes.formaEnvio[name] = !this.configuracoes.formaEnvio[
        name
      ];
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.tableItem {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 5px;
  align-items: center;
  border-bottom: 1px solid rgba(50, 50, 50, 0.2);
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    justify-items: center;
  }
}
.titleHeaderModal {
  font-size: 16px;
  font-weight: 600;
  color: rgb(55, 55, 55);
}
.sectionTitle {
  color: rgb(55, 55, 55);
  font-size: 16px;
  font-weight: 400;
}
.sectionSubtitle {
  color: rgb(120, 120, 120);
  font-size: 13px;
}
.listItens {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 7px 0px 15px 0px;

  & > *:nth-child(1n) {
    margin-right: 8px;
    margin-top: 8px;
  }
}
.contentChip {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .labelChip {
    color: rgb(40, 120, 200);
    &.active {
      color: white;
    }
  }
}
</style>
