<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card v-if="chapter == 'INITIAL'" class="pa-3">
        <div style="display: grid; grid-template-columns: 1fr auto">
          <div
            style="font-weight: 500;padding: 5px; font-size: 20px; color: rgb(30,30,30); font-family: 'Roboto';"
          >
            Cadastrar doação
          </div>
          <div></div>
        </div>
        <div class="pa-1">
          <div class="customCard">
            <div class="contentIcon">
              <v-icon color="rgba(0,70,120,1)">mdi-information</v-icon>
            </div>
            <div class="contentText">
              A doação será direcionada para o concurso atual.
            </div>
          </div>
        </div>
        <div>
          <div class="sectionLabel">
            Dados do beneficiário da doação
          </div>
          <div class="pa-2 px-3">
            <v-text-field
              v-model="include.nomeComprador"
              label="Nome completo"
              dense
            ></v-text-field>
            <v-text-field
              v-model="include.cpfComprador"
              v-mask="['###.###.###-##']"
              label="CPF"
              dense
            ></v-text-field>
            <v-text-field
              v-model="include.emailComprador"
              type="email"
              label="Email"
              dense
            ></v-text-field>
            <v-text-field
              v-model="include.qtdBilhete"
              label="Quantidade Bilhetes"
              type="number"
              dense
            ></v-text-field>
            <v-text-field
              v-model="include.telefoneComprador"
              v-mask="['(##) ####-#####']"
              label="Telefone"
              dense
            ></v-text-field>
          </div>
        </div>
        <div class="grid grid-cols-2 justify-center align-center">
          <div class="pa-2 text-left">
            <v-btn :loading="loading" depressed @click="$emit('close')"
              >Cancelar</v-btn
            >
          </div>
          <div class="pa-2 text-right">
            <v-btn
              :loading="loading"
              color="primary"
              depressed
              @click="efetuarDoacao()"
              >Efetuar doação</v-btn
            >
          </div>
        </div>
      </v-card>
      <v-card v-if="chapter == 'SUCESSO'" class="pa-3">
        <div>
          <div
            style="display: flex; flex-direction: row; justify-content: flex-end;"
          >
            <v-btn color="black" icon @click="close()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <div
            style="font-size: 24px; font-weight: 500; margin-bottom: 10px; text-align: center; color: #8c9eff;"
          >
            Parabéns doação concluída!
          </div>
          <div style="font-size: 16px; text-align: center;margin-bottom: 5px;">
            Aqui estão os bilhetes gerados
          </div>
          <div class="listaBilhetes">
            <div
              v-for="item in bilhetesGerados"
              :key="item"
              class="itemBilhetes"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ServiceFCZ from "@/services/fcz";

export default {
  data() {
    return {
      chapter: "INITIAL",
      dialog: true,
      loading: false,
      bilhetesGerados: [],
      include: {
        concursoId: null,
        cpfComprador: null,
        emailComprador: null,
        qtdBilhete: null,
        telefoneComprador: null,
        nomeComprador: null
      }
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    validaDoacao() {
      if (this.include.qtdBilhete <= 0) {
        this.$root.showError("Quantidade inválida");
        return false;
      }
      return true;
    },
    efetuarDoacao() {
      if (!this.validaDoacao()) {
        return false;
      }
      this.loading = true;
      ServiceFCZ.sendDonate(this.include)
        .then(resp => {
          this.bilhetesGerados = resp.data;
          this.chapter = "SUCESSO";
        })
        .catch(err => {
          console.error(err);
          this.$root.showError(err);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.itemBilhetes {
  background-color: rgba(10, 100, 200, 0.1);
  padding: 8px 12px;
  border-radius: 4px;
  margin: 5px;
  min-width: 100px;
  color: rgba(0, 70, 120, 1);
  font-size: 14px;
  text-align: center;
}
.listaBilhetes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5px;
  justify-content: space-between;
}
.customCard {
  background-color: rgba(10, 100, 200, 0.1);
  padding: 12px;
  border-radius: 4px;
  color: rgba(0, 70, 120, 1);
  font-size: 14px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  .contentIcon {
    flex: 0;
    padding-right: 10px;
  }
  .contentText {
    flex: 1;
  }
}
.sectionLabel {
  margin: 10px 0px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  color: #4d4d5f;
  padding: 10px;
}
.inputCustom {
  background-color: rgba(100, 100, 100, 0.1);
  padding: 8px 12px;
  max-width: 90%;
  border-radius: 4px;
  color: rgba(50, 50, 50, 1);
  font-size: 14px;
  width: 100%;
}
</style>
