<template>
  <v-card class="pa-4 mx-2 mx-sm-4 lowShadow">
    <div class="tituloFiltros">
      Filtros
    </div>
    <div class="pa-2">
      <v-row v-if="filtroEvento || filtroSituacao">
        <v-col v-if="filtroEvento" cols="12" md="6">
          <!-- style="display: flex; flex-direction: row;"-->
          <div>
            <v-autocomplete
              v-model="filtros.turma"
              no-filter
              :items="listTurma"
              :search-input.sync="searchEvento"
              item-text="nome"
              label="Buscar por nome ou id"
              name="Itens"
              item-value="id"
              chips
              small-chips
              :disabled="loading"
            >
              <template v-slot:append-outer> </template>
              <template v-slot:no-data>
                <div class="pa-2 px-4 text-body-2">
                  <div>Sem resultados, digite o nome ou id</div>
                </div>
              </template>
              <template v-slot:selection="data">
                <div>
                  <v-chip>
                    <v-avatar left>
                      <v-icon
                        color="rgba(50,50,50,0.2)"
                        v-text="'mdi-domain'"
                      ></v-icon>
                    </v-avatar>
                    <div class="text-truncate">
                      EVT-{{ data.item.id }} | {{ data.item.nome }}
                    </div>
                  </v-chip>
                </div>
              </template>
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-avatar>
                    <v-icon
                      color="rgba(50,50,50,0.2)"
                      v-text="'mdi-domain'"
                    ></v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="data.item.nome"
                    ></v-list-item-title>
                    <v-list-item-subtitle>
                      EVT-{{ data.item.id }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </div>
        </v-col>
        <v-col v-if="filtroSituacao" cols="12" md="6">
          <v-select
            v-model="filtros.situacao"
            :disabled="loading"
            :items="situacaoLista"
            item-text="text"
            item-value="text"
            label="Situação"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="filtros.dataInicial"
            type="date"
            label="Período Inicial"
            :disabled="loading"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="filtros.dataFinal"
            type="date"
            label="Período Final"
            :disabled="loading"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" class="text-sm-left text-center">
          <v-row class="font-weight-medium">
            <v-col cols="12" sm="6">
              <div
                class="radioCustom"
                :class="{ ativo: filtros.tipoRel == 'PDF' }"
                @click="filtros.tipoRel = 'PDF'"
              >
                <input
                  v-model="filtros.tipoRel"
                  class="mr-3"
                  type="radio"
                  value="PDF"
                />
                PDF
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <div
                class="radioCustom"
                :class="{ ativo: filtros.tipoRel == 'XLS' }"
                @click="filtros.tipoRel = 'XLS'"
              >
                <input
                  v-model="filtros.tipoRel"
                  class="mr-3"
                  type="radio"
                  value="XLS"
                />
                EXCEL
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <div
            style="justify-items: center; align-items: center; text-align: left;"
          >
            <v-btn
              :disabled="loading"
              color="primary"
              depressed
              @click="limparCampos()"
            >
              Limpar campos
            </v-btn>
          </div>
        </v-col>
        <v-col cols="12" sm="6">
          <div
            style="justify-items: center; align-items: center; text-align: right;"
          >
            <v-btn
              :loading="loading"
              color="primary"
              depressed
              @click="REQUEST_RELATORIO()"
            >
              ENVIAR
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import serviceTurma from "@/services/turma";
export default {
  name: "FiltroPesquisa",
  props: {
    loading: {
      default: false,
      type: Boolean,
      require: true
    },
    relatorio: {
      type: String,
      require: true,
      default: null
    }
  },
  data() {
    return {
      filtroEvento: true,
      filtroSituacao: true,
      filtros: {
        turma: null,
        dataInicial: null,
        dataFinal: null,
        situacao: "Todos",
        tipoRel: "PDF"
      },
      listTurma: [],
      situacaoLista: [
        { text: "Todos" },
        { text: "Inadimplente" },
        { text: "Aberto" }
      ],
      timeSearch: 600,
      searchEvento: ""
    };
  },
  watch: {
    searchEvento() {
      this.debounceEvento();
    }
  },
  mounted() {
    this.configureComponent();
  },
  methods: {
    configureComponent() {
      if (this.relatorio === "CONTABILIDADE") {
        this.filtroEvento = false;
        this.filtroSituacao = false;
        this.filtros.situacao = null;
      }
      if (this.relatorio === "EXTRATO-EVENTO") {
        this.filtroEvento = true;
        this.filtroSituacao = true;
      }
    },
    close() {
      this.$emit("close");
    },
    limparCampos() {
      this.filtros.turma = null;
      this.filtros.dataInicial = null;
      this.filtros.dataFinal = null;
      this.filtros.situacao = "Todos";
      this.filtros.tipoRel = "PDF";
      this.searchEvento = "";
    },
    validaPeriodo() {
      if (this.relatorio === "CONTABILIDADE") {
        if (!this.filtros.dataInicial || this.filtros.dataInicial == "") {
          this.$root.showInfo(
            "Por favor, informe o período de datas corretamente"
          );
          return false;
        }
        if (!this.filtros.dataFinal || this.filtros.dataFinal == "") {
          this.$root.showInfo(
            "Por favor, informe o período de datas corretamente"
          );
          return false;
        }
      }
      return true;
    },
    validaTurma() {
      if (this.relatorio === "EXTRATO-EVENTO") {
        let turma = Number(this.filtros.turma);
        if (turma == "" || !turma) {
          this.$root.showInfo("Digite um numero válido no id da turma");
          return false;
        }
        return true;
      } else {
        return true;
      }
    },
    validacaoForm() {
      if (!this.validaPeriodo()) {
        return false;
      }
      if (!this.validaTurma()) {
        return false;
      }
      return true;
    },
    debounceEvento() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        let search = this.searchEvento ? this.searchEvento : "";
        if (!search) {
          return false;
        }

        serviceTurma
          .buscar(search)
          .then(resp => {
            if (resp.status == 200) {
              this.listTurma = resp.data;
            }
          })
          .catch(err => {
            console.error(err);
          });
      }, this.timeSearch);
    },
    mountPayload() {
      return { ...this.filtros };
    },
    REQUEST_RELATORIO() {
      console.log("REQUEST_RELATORIO");
      if (!this.validacaoForm()) return false;
      const payload = this.mountPayload();
      this.$emit("request", payload);
    }
  }
};
</script>

<style lang="scss" scoped>
.tituloFiltros {
  font-weight: 600;
  padding: 5px 10px;
}
</style>
