<template>
  <div>
    <v-card class="pa-4 my-2 white middleShadow align-center lowShadow">
      <v-row justify="center" align="center">
        <v-col cols="12">
          <v-row>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="filtros.nome"
                dense
                outlined
                name="nome"
                label="Nome"
                :disabled="loading"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="filtros.cpf"
                v-mask="'###.###.###-##'"
                dense
                outlined
                label="CPF"
                hide-details
                :disabled="loading"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="filtros.email"
                dense
                outlined
                type="email"
                label="Email"
                hide-details
                :disabled="loading"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="filtros.alunoId"
                dense
                outlined
                prefix="USR-"
                type="number"
                name="id"
                label="Id do participante"
                hide-details
                :disabled="loading"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                v-model="filtros.alunoStatus"
                :items="statusDeCadastro"
                item-text="text"
                item-value="code"
                dense
                outlined
                :value="null"
                label="Status de cadastro"
                hide-details
                :disabled="loading"
              ></v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <div class="my-4">
        <div class="advancedHeader">
          <div
            style="display: flex;flex-direction: row; justify-items: center; align-items: center;"
          >
            <div style="padding: 5px 0px;">
              Opções avançadas
            </div>
            <div class="ml-3">
              <v-btn x-small text color="primary" @click="toggleOpcoes()">
                <div
                  v-if="!advancedSearch"
                  style="display: flex; flex-direction: row; justify-items: center; align-items: center;"
                >
                  <span class="mr-1">
                    mostrar
                  </span>
                  <v-icon size="16">mdi-eye</v-icon>
                </div>
                <div v-else>
                  <span class="mr-1">
                    Esconder
                  </span>
                  <v-icon size="16">mdi-eye-off</v-icon>
                </div>
              </v-btn>
            </div>
          </div>
        </div>
        <div v-if="advancedSearch" id="fields">
          <div class="subtitleSearch">
            Dados Pessoais
          </div>
          <v-row>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="filtros.rg"
                dense
                outlined
                label="RG"
                type="number"
                placeholder=""
                hide-details
                :disabled="loading"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="filtros.pai"
                dense
                outlined
                label="Nome do pai"
                hide-details
                :disabled="loading"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="filtros.mae"
                dense
                outlined
                label="Nome da mae"
                hide-details
                :disabled="loading"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="filtros.telefone"
                dense
                outlined
                label="Telefone"
                hide-details
                :disabled="loading"
              ></v-text-field>
            </v-col>
          </v-row>
          <div class="subtitleSearch">
            Endereço
          </div>
          <v-row>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="filtros.endereco.cep"
                dense
                outlined
                label="CEP"
                hide-details
                :mask="['#####-###']"
                :disabled="loading"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="filtros.endereco.uf"
                dense
                outlined
                label="UF"
                hide-details
                :disabled="loading"
                @change="changeFiltroUF"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="filtros.endereco.cidade"
                dense
                outlined
                label="Cidade"
                hide-details
                :disabled="loading"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="filtros.endereco.logradouro"
                dense
                outlined
                label="Logradouro"
                hide-details
                :disabled="loading"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="filtros.endereco.bairro"
                dense
                outlined
                label="Bairro"
                hide-details
                :disabled="loading"
              ></v-text-field>
            </v-col>
          </v-row>
          <div class="subtitleSearch">
            Referente a turma
          </div>
          <v-row>
            <v-col cols="12" sm="4">
              <v-autocomplete
                :items="listaDeTurmas"
                :search-input.sync="searchTurma"
                color="primary"
                label="Turma/Evento"
                item-text="nome"
                item-value="id"
                placeholder=""
                prepend-inner-icon="mdi-school"
                return-object
                outlined
                small
                dense
                class="text-truncate"
                :disabled="loading"
                @change="changeTurma"
              >
                <template v-slot:selection="data">
                  <div>
                    <v-chip>
                      {{ data.item.nome }}
                    </v-chip>
                  </div>
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-icon>
                      <div>
                        <v-icon color="rgba(50,50,50,0.2)">mdi-school</v-icon>
                      </div>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.nome }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
                <template slot="no-data">
                  <div class="px-2 py-1">
                    Sem dados referente a pesquisa
                  </div>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                v-model="filtros.alunoStatus"
                :items="itensStatusConvite"
                item-text="text"
                item-value="code"
                :value="null"
                dense
                outlined
                label="Convite Status"
                hide-details
                :disabled="loading"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                :items="listaDePermissao"
                item-text="text"
                item-value="code"
                :value="null"
                label="Permissão na Turma"
                dense
                outlined
                hide-details
                :disabled="loading"
                @change="setNivelFiltro"
              ></v-select>
            </v-col>
          </v-row>
        </div>
      </div>

      <!-- <code>{{ filtros }}</code> -->

      <div class="containPainel">
        <div style="flex: 1; text-align: left;">
          <v-btn
            :loading="loading"
            outlined
            color="primary"
            @click="limparCampos()"
          >
            Limpar campos
          </v-btn>
        </div>
        <div style="flex: 1; text-align: right;">
          <v-btn :loading="loading" color="primary" @click="consultaLista()">
            Buscar
          </v-btn>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import serviceTurma from "@/services/turma";
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      advancedSearch: false,
      clockSearch: null,
      searchTurma: null,
      timeSearch: 300,
      filtros: {
        nome: "",
        nomeTurma: null,
        alunoStatus: null,
        conviteStatus: null,
        email: null,
        permissao: null,
        alunoId: null,
        cpf: null,
        rg: null,
        telefone: null,
        pai: null,
        mae: null,
        endereco: {
          cidade: null,
          logradouro: null,
          uf: null,
          bairro: null,
          cep: null
        }
      },
      tipoSearch: "nome",
      itensTipoSearch: [
        { text: "Por nome", code: "nome" },
        { text: "Por email", code: "email" },
        { text: "Por id", code: "id" },
        { text: "Por CPF", code: "cpf" },
        { text: "Por RG", code: "rg" },
        { text: "Por Telefone", code: "telefone" },
        { text: "Por Pai", code: "pai" },
        { text: "Por Mãe", code: "mae" },
        { text: "Por Endereço", code: "endereco" }
      ],
      itensStatusConvite: [
        // { text: "Todos", code: null },
        // { text: "Autorizado", code: "AUTORIZADO" },
        // { text: "Não autorizado", code: "NAO_AUTORIZADO" }
        // > nova lista
        { text: "Todos", code: null },
        { text: "Completo", code: "COMPLETO" },
        { text: "Incompleto", code: "INCOMPLETO" },
        { text: "Bloqueado", code: "BLOQUEADO" }
        //BLOQUEADO, COMPLETO, INCOMPLETO
      ],
      listaDePermissao: [
        { text: "Todos", code: null },
        { text: "Admin", code: "ADMIN" },
        { text: "Comissão", code: "COMISSAO" },
        { text: "Participante", code: "PARTICIPANTE" }
      ],
      statusDeCadastro: [
        { text: "Todos", code: null },
        { text: "Incompleto", code: "INCOMPLETO" },
        { text: "Completo", code: "COMPLETO" }
      ],
      emailValid: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Endereço de email inválido.";
      },
      listaDeTurmas: []
    };
  },
  watch: {
    searchTurma: function(value) {
      if (value && value.length > 0) {
        this.debounceSearchEvent();
      }
    }
  },
  methods: {
    changeFiltroUF(event) {
      console.log("changefiltroUF", event);
      let uf = event;
      if (!uf) return;
      uf = uf.replace(/[0-9]/g, "");
      if (uf.length > 2) {
        uf = uf.substring(0, 2);
      }
      this.filtros.endereco.uf = uf.toUpperCase();
    },
    changeTurma(turma) {
      if (turma) {
        this.searchTurma = turma.nome;
        this.filtros.nomeTurma = turma.nome;
      } else {
        this.searchTurma = null;
        this.filtros.nomeTurma = null;
      }
    },
    debounceSearchEvent() {
      clearTimeout(this.clockSearch);
      const search = this.searchTurma;
      const self = this;
      this.clockSearch = setTimeout(() => {
        if (!search || search === "" || search.length <= 2) {
          return false;
        }
        self.buscarTurma(search);
      }, self.timeSearch);
    },
    buscarTurma(name) {
      serviceTurma
        .buscarTurma(name)
        .then(resp => {
          this.listaDeTurmas = resp.data;
        })
        .catch(error => {
          this.$root.showError(error);
        });
    },
    toggleOpcoes() {
      this.advancedSearch = !this.advancedSearch;
    },
    setCadastroStatus(status) {
      this.filtros.alunoStatus = status;
    },
    setNivelFiltro(nivel) {
      this.filtros.permissao = nivel;
    },
    limparCampos() {
      this.searchTurma = null;
      this.filtros = {
        nome: "",
        nomeTurma: null,
        alunoStatus: null,
        conviteStatus: null,
        email: null,
        permissao: null,
        alunoId: null,
        cpf: null,
        rg: null,
        id: null,
        telefone: null,
        pai: null,
        mae: null,
        endereco: {
          cidade: null,
          logradouro: null,
          uf: null,
          bairro: null,
          cep: null
        }
      };
      this.$emit("clearSeach", this.filtros);
    },
    consultaLista() {
      const filtros = { ...this.filtros };
      this.$emit("onSearch", filtros);
    }
  }
};
</script>

<style lang="scss" scoped>
.subtitleSearch {
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 5px;
  margin-top: 10px;
}

.advancedHeader {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
  justify-items: center;
  align-items: center;
}
.containPainel {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
}
.btnOpenAdvancedSearch {
  background-color: rgb(55, 68, 126);
  //color: rgb(70, 70, 70);
  color: white;
  padding: 6px 10px;
  display: inline-block;
  border-radius: 3px;
  cursor: pointer;
}
</style>
