<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card class="pa-3 pt-2">
      <div class="headerModal">
        <div class="tituloModal">
          Bilhetes do beneficiário
        </div>
        <div>
          <v-btn icon @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
      <div id="dadosCompra" class="grid grid-col-1-1 mt-3 text-center">
        <div>
          <div class="label">
            Beneficiário
          </div>
          <div class="value">
            {{ compra.nomeComprador }}
          </div>
        </div>
        <div>
          <div class="label">
            Data da compra
          </div>
          <div class="value">
            {{ formatDate(compra.dataCompra) }}
          </div>
        </div>
      </div>
      <div
        style="height: 1px; background: rgba(50,50,50,0.1);margin-top: 10px;"
      ></div>
      <div class="mt-3">
        <div>
          <div class="tituloLista text-left">
            Lista de bilhetes <span class="black--text">{{ totalItems }}</span>
          </div>
        </div>
        <div id="lista" class="listaBilhetes">
          <div v-for="item in bilhetes" :key="item.id" class="itemBilhetes">
            Nº {{ item.numero }}
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import ServiceVendaBilhetes from "@/services/vendabilhete";

export default {
  props: {
    compra: { type: Object, required: true }
  },
  data() {
    return {
      dialog: true,
      loading: true,
      totalItems: 0,
      bilhetes: []
    };
  },
  mounted() {
    console.log(this.compra);
    this.getBilhetes();
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    close() {
      this.$emit("close");
    },
    getBilhetes() {
      const options = {
        cpfComprador: this.compra.cpfComprador,
        id: "",
        nomeComprador: ""
      };
      ServiceVendaBilhetes.getBilhetesPorVenda(this.compra.id)
        .then(resp => {
          console.log(resp);
          this.bilhetes = resp.data;
          this.totalItems = resp.data.length;
          this.loading = false;
        })
        .catch(err => {
          this.$root.showError(err || err.data || err.data.message);
          this.close();
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.headerModal {
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
}
.listaBilhetes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5px;
  justify-content: space-between;
}
.itemBilhetes {
  background-color: rgba(10, 100, 200, 0.1);
  padding: 8px 12px;
  border-radius: 4px;
  margin: 5px;
  min-width: 100px;
  color: rgba(0, 70, 120, 1);
  font-size: 14px;
  text-align: center;
}

.tituloLista {
  font-size: 14px;
  margin-left: 10px;
  color: rgb(130, 130, 130);
}
.tituloModal {
  font-size: 17px;
  font-weight: 400;
  color: rgb(100, 100, 100);
}
.label {
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  color: black;
}
.value {
  font-weight: 300;
  color: black;
  font-size: 17px;
}
</style>
