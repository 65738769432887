import axios from "axios";
export default {
  /**
   * Description placeholder
   * @typedef {Object} donate
   * @param {number} donate.concursoId
   * @param {number} donate.cpfComprador
   * @param {string} donate.emailComprador
   * @param {number} donate.qtdBilhete
   * @param {string} donate.telefoneComprador
   * @returns {*}
   */
  getConcurso(donate) {
    return axios.get("/v1/formaturacustozero/dados-concurso-atual");
  },
  sendDonate(donate) {
    return axios.post("/v1/formaturacustozero/doacao", donate);
  }
};
