<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    :overlay="false"
    max-width="500px"
    transition="dialog-transition"
  >
    <v-card class="pa-4" max-width="500">
      <div class="primary--text text-h6">Redefinir Senha</div>
      <div class="pa-2 text-body-2">
        <v-text-field
          v-model="include.senha"
          label="Nova senha"
          :type="typePass"
          solo
          :disabled="loading"
        >
          <template v-slot:append>
            <v-btn v-if="typePass == 'password'" text @click="toggleTypePass()">
              <v-icon>mdi-eye</v-icon></v-btn
            >
            <v-btn v-if="typePass == 'text'" text @click="toggleTypePass()">
              <v-icon>mdi-eye-off</v-icon></v-btn
            >
          </template>
        </v-text-field>
      </div>
      <v-row justify="center" class="text-center">
        <v-col cols="6">
          <v-btn color="primary" :disabled="loading" outlined @click="close()"
            >Cancelar</v-btn
          >
        </v-col>
        <v-col cols="6">
          <v-btn color="primary" :loading="loading" @click="save()"
            >Salvar Senha</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import serviceUser from "@/services/userController";
export default {
  props: {
    user: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      dialog: true,
      typePass: "text",
      loading: false,
      include: {}
    };
  },
  mounted() {
    this.include = { ...this.user };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    save() {
      this.loading = true;
      let userPayload = this.include;
      serviceUser
        .updatePasswordUser(userPayload)
        .then(resp => {
          this.$root.showInfo(resp.data);
        })
        .catch(err => {
          this.$root.showError(err.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    toggleTypePass() {
      if (this.typePass == "text") this.typePass = "password";
      else this.typePass = "text";
    }
  }
};
</script>

<style></style>
