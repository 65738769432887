import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueCurrencyFilter from "vue-currency-filter";
import { VueMaskDirective } from "v-mask";

import "./components.js";
import { URL_BASE } from "@/constantes/base";
import money from "v-money";
import { ErrorTranslate } from "@/tools/toolsError";

Vue.use(money, {
  precision: 2,
  decimal: ",",
  thousands: ".",
  masked: false
});

Vue.config.productionTip = false;
Vue.directive("mask", VueMaskDirective);

Vue.use(VueCurrencyFilter, [
  {
    symbol: "R$",
    thousandsSeparator: ".",
    fractionCount: 2,
    fractionSeparator: ",",
    symbolPosition: "front",
    symbolSpacing: true,
    avoidEmptyDecimals: undefined
  },
  {
    name: "currencySemDecimal",
    symbol: "R$",
    thousandsSeparator: ".",
    fractionCount: 0,
    fractionSeparator: ",",
    symbolPosition: "front",
    symbolSpacing: true,
    avoidEmptyDecimals: undefined
  }
]);

Vue.config.errorHandler = function(err, vm, info) {
  window.console.error(err, vm, info);
};

Vue.config.productionTip = false;

var appvue = new Vue({
  router,
  store,
  vuetify,
  data() {
    return {
      money: {
        precision: 2,
        decimal: ",",
        thousands: ".",
        masked: false
        //prefix: "R$ "
      },
      type: "success",
      message: "",
      alert: false,
      icon: ""
    };
  },
  methods: {
    validaCNPJ(payload) {
      if (!payload) return false;
      let cnpj = payload.replace(/[^\d]+/g, "");
      if (cnpj == "") return false;
      if (cnpj.length != 14) return false;
      // Elimina CNPJs invalidos conhecidos
      if (
        cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999"
      )
        return false;

      // Valida DVs
      let tamanho = cnpj.length - 2;
      let numeros = cnpj.substring(0, tamanho);
      let digitos = cnpj.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado != digitos.charAt(0)) return false;

      tamanho = tamanho + 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado != digitos.charAt(1)) return false;
      return true;
    },
    converteValor(valorStr) {
      if (
        valorStr &&
        valorStr !== null &&
        valorStr !== undefined &&
        valorStr !== "" &&
        typeof valorStr === "string"
      ) {
        // return Number(valorStr.replace(',', '.').replace(/[^0-9.-]+/g, ''))
        return Number(
          valorStr
            .replaceAll(".", "")
            .replace(",", ".")
            .replace(/[^0-9.-]+/g, "")
        );
      }
      return valorStr;
    },
    showAlerta(msg) {
      this.type = "warning";
      this.message = msg;
      this.icon = "mdi-exclamation";
      this.exibirModal();
    },
    showInfo(msg) {
      this.type = "info";
      this.message = msg;
      this.icon = "mdi-information";
      this.exibirModal();
    },
    showSucesso(msg) {
      this.type = "success";
      this.message = msg;
      this.icon = "mdi-check-circle";
      this.exibirModal();
    },
    showError(msg, noTranslate) {
      this.type = "error";
      if (noTranslate) {
        this.message = msg;
      } else {
        this.message = ErrorTranslate(msg);
      }
      this.icon = "mdi-alert";
      this.exibirModal();
    },
    exibirModal() {
      this.alert = true;
      setTimeout(() => {
        this.reset();
      }, 5000);
    },
    reset() {
      this.type = "success";
      this.message = "";
      this.alert = false;
      this.icon = "";
    },
    validateCPF(payload) {
      var Soma;
      var Resto;
      let strCPF = String(payload);
      strCPF = strCPF.replaceAll(".", "");
      strCPF = strCPF.replaceAll("-", "");
      Soma = 0;
      if (strCPF == "00000000000") return false;

      for (let i = 1; i <= 9; i++)
        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
      Resto = (Soma * 10) % 11;

      if (Resto == 10 || Resto == 11) Resto = 0;
      if (Resto != parseInt(strCPF.substring(9, 10))) return false;

      Soma = 0;
      for (let i = 1; i <= 10; i++)
        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
      Resto = (Soma * 10) % 11;

      if (Resto == 10 || Resto == 11) Resto = 0;
      if (Resto != parseInt(strCPF.substring(10, 11))) return false;
      return true;
    }
  },
  render: h => h(App)
}).$mount("#app");

axios.defaults.baseURL = URL_BASE;

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    //let atualPath = appvue.$route.path;
    if (error.response.status === 401) {
      if (appvue.$route.path == "/login") {
        // nada
      } else {
        appvue.$router.push("/login");
      }
    }
    return Promise.reject(error.response);
  }
);

axios.interceptors.request.use(
  config => {
    if (
      config.baseURL === URL_BASE &&
      !config.headers.Authorization &&
      localStorage.getItem("APT-ADMTOKEN")
    ) {
      let tokenCompleto = localStorage.getItem("APT-ADMTOKEN");
      config.headers.Authorization = tokenCompleto;
    }
    return config;
  },
  error => Promise.reject(error)
);
