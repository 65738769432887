<template>
  <div>
    <vTitulo>Configurações de notificação</vTitulo>
    <div>
      <vFiltrosDeBusca :loading="loadingTable" @onSearch="pesquisaComFiltros" />
    </div>
    <div class="customCard">
      <div class="headerBarConfig">
        <div class="text-slate-500 pa-1">
          <div
            style="
              font-weight: 500;
              padding-left: 4px;
              display: inline-block;
              font-size: 20px;
            "
          >
            {{ listaUsuariosSelecionados.length }}
          </div>
          usuários selecionados
        </div>
        <div class="text-right">
          <v-btn
            :disabled="listaUsuariosSelecionados.length == 0"
            color="primary"
            dense
            depressed
            @click="configurarUsuarios()"
          >
            Configurar
          </v-btn>
        </div>
      </div>
      <v-data-table
        :headers="headers"
        :items="listaUsuarios"
        :options.sync="options"
        :items-per-page="maxPage"
        :server-items-length="totalParticipantes"
        :loading="loadingTable"
        :footer-props="{
          itemsPerPageOptions: [50, 100, 200]
        }"
        class="elevation-0"
        loading-text="Carregando..."
        dense
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.name">
              <td class="text-center">
                <v-checkbox
                  v-model="item.checked"
                  @change="selecionaUsuario(item.checked, item)"
                ></v-checkbox>
              </td>
              <td style="min-width: 40px">USR-{{ item.usuarioId }}</td>
              <td style="min-width: 150px">{{ item.nomeFormando }}</td>
              <td style="min-width: 150px">
                {{ item.nome + " " }} {{ item.sobreNome }}
              </td>
              <td>{{ item.email }}</td>
              <td style="min-width: 100px">EVT-{{ item.turmaId }}</td>
              <td style="min-width: 200px">{{ item.turmaNome }}</td>
              <td>{{ item.permissaoTurma }}</td>
              <td>{{ item.situacaoCadastro }}</td>
              <td
                alt="Participa do Formatura Custo Zero"
                title="Participa do Formatura Custo Zero"
              >
                <VTagSituacaoFCZ :value="dataTransformFCZ(item)" />
              </td>
              <td
                alt="Saldo do Formatura Custo Zero"
                title="Saldo do Formatura Custo Zero"
              >
                <VSaldoFCZ :item="item" />
                <!-- {{ item.saldoBilhete | normalizeNameSaldoBilhete }} -->
              </td>

              <td>
                <div class="d-flex flex-row">
                  <div
                    class="customChip"
                    :class="{ active: item.formaEnvio.whatsapp }"
                  >
                    <v-icon
                      size="20"
                      :color="item.formaEnvio.whatsapp ? 'white' : 'silver'"
                      >mdi-whatsapp</v-icon
                    >
                  </div>
                  <div
                    class="customChip"
                    :class="{ active: item.formaEnvio.email }"
                  >
                    <v-icon
                      size="20"
                      :color="item.formaEnvio.email ? 'white' : 'silver'"
                      >mdi-email-outline</v-icon
                    >
                  </div>
                  <div
                    class="customChip"
                    :class="{ active: item.formaEnvio.sms }"
                  >
                    <v-icon
                      size="20"
                      :color="item.formaEnvio.sms ? 'white' : 'silver'"
                      >mdi-cellphone</v-icon
                    >
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>
    <vModalConfiguracaoUser
      v-if="modalConfigUser"
      :selecionados="listaUsuariosSelecionados"
      @close="closeModalConfigUser"
      @update="atualizarLista"
    />
  </div>
</template>

<script>
import ServiceUser from "@/services/userController";
import VTagSituacaoFCZ from "../participantes/vTagSituacaoFCZ.vue";
import VSaldoFCZ from "../participantes/vSaldoFCZ.vue";
import vFiltrosDeBusca from "./vFiltroDeBusca.vue";
import vModalConfiguracaoUser from "./vModalConfiguracaoUser.vue";

export default {
  components: {
    VTagSituacaoFCZ,
    VSaldoFCZ,
    vFiltrosDeBusca,
    vModalConfiguracaoUser
  },
  data() {
    return {
      options: {},
      page: 1,
      maxPage: 50,
      totalParticipantes: 0,
      headers: [
        {
          text: "Selecione",
          value: "selecionado",
          align: "left",
          sortable: false
        },
        { text: "Código", value: "usuarioId", align: "left", sortable: false },
        {
          text: "Formando",
          value: "nomeFormando",
          align: "left",
          sortable: false
        },
        {
          text: "Nome completo",
          value: "nome",
          align: "left",
          sortable: false
        },
        { text: "Email", value: "email", align: "left", sortable: false },
        { text: "Evento ID", value: "turmaId", align: "left", sortable: false },
        {
          text: "Evento/Turma",
          value: "turmaNome",
          align: "left",
          sortable: false
        },
        {
          text: "Nível",
          value: "permissaoTurma",
          align: "left",
          sortable: false
        },
        {
          text: "Cadastro",
          value: "situacaoCadastro",
          align: "left",
          sortable: false
        },
        {
          text: "Participa do FCZ",
          value: "aceiteFCZ",
          align: "left",
          sortable: false
        },
        {
          text: "Saldo FCZ",
          value: "saldoBilhete",
          align: "left",
          sortable: false
        },
        { text: "Notificações", align: "center", sortable: false }
      ],
      filtros: {
        agenciaId: null,
        alunoId: null,
        alunoStatus: null,
        conviteStatus: null,
        cpf: null,
        email: null,
        endereco: {
          bairro: null,
          cep: null,
          cidade: null,
          complemento: null,
          ibge: null,
          localidade: null,
          logradouro: null,
          uf: null
        },
        id: null,
        mae: null,
        nome: null,
        nomeTurma: null,
        pai: null,
        permissao: null,
        rg: null,
        telefone: null
      },
      loadingTable: true,
      listaUsuarios: [],
      listaUsuariosSelecionados: [],
      modalConfigUser: false
    };
  },
  watch: {
    options: {
      handler(d) {
        this.page = d.page;
        this.maxPage = d.itemsPerPage;
        this.getUsuarios();
      },
      deep: true
    }
  },
  mounted() {
    this.filtros.agenciaId = this.$store.getters["gestao/getAgencia"].agenciaId;
  },
  methods: {
    atualizarLista() {
      this.getUsuarios();
    },
    closeModalConfigUser() {
      this.modalConfigUser = false;
    },
    configurarUsuarios() {
      if (this.listaUsuariosSelecionados.length === 0) {
        return;
      }
      this.modalConfigUser = true;
    },
    selecionaUsuario(event, user) {
      console.log(event, user);
      if (event) {
        this.listaUsuariosSelecionados.push({ ...user });
      } else {
        this.listaUsuariosSelecionados.splice(
          this.listaUsuariosSelecionados.indexOf(user),
          1
        );
      }
    },
    clearListUserSelected() {
      this.listaUsuariosSelecionados = [];
    },
    pesquisaComFiltros(eventPayload) {
      this.filtros = { ...eventPayload };
      this.getUsuarios();
    },
    dataTransformFCZ(item) {
      return item.aceiteFCZ ? item.aceiteFCZ : false;
    },
    getUsuarios() {
      this.loadingTable = true;
      this.clearListUserSelected();
      ServiceUser.paginationUserNotification(this.page - 1, this.maxPage, {
        ...this.filtros
      })
        .then(resp => {
          this.listaUsuarios = resp.data.content.map(user => {
            return { ...user, checked: false };
          });
          this.totalParticipantes = resp.data.totalElements;
        })
        .finally(() => {
          this.loadingTable = false;
        })
        .catch(err => {
          this.$root.showError(err);
          this.totalParticipantes = 0;
          this.listaUsuarios = [];
          console.error(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.customChip {
  display: inline-flex;
  flex-direction: row;
  width: 35px;
  height: 35px;
  margin: 3px;
  border-radius: 100%;
  background-color: rgba(150, 150, 150, 0.3);
  justify-content: center;
  align-items: center;
  text-align: center;
  &.active {
    background-color: #505f98;
  }
}
.headerBarConfig {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin-bottom: 8px;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    justify-items: center;
  }
}
.customCard {
  padding: 10px;
  background-color: white;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 1px 1px 2px rgba(50, 50, 50, 0.2);
}
</style>
