<template>
  <v-container fluid class="pa-0 px-2">
    <v-titulo>
      Usuários
    </v-titulo>
    <v-toolbar rounded class="mb-3 lowShadow">
      <form autocomplete="off">
        <v-text-field
          v-model="filtros.nome"
          hide-details
          label="Nome do usuario"
          placeholder="Nome do usuário"
        />
      </form>
      <span v-if="getUserRole != 'AGENCIA'">
        <v-spacer></v-spacer>
        <v-select
          v-model="filtros.role"
          :items="getTipos"
          item-text="descricao"
          item-value="id"
          label="Grupo"
          hide-details
        ></v-select>
      </span>
      <v-spacer></v-spacer>
      <v-btn color="primary" :loading="loadingPesquisar" @click="pesquisar">
        Pesquisar
      </v-btn>
    </v-toolbar>

    <v-card class="lowShadow">
      <v-toolbar flat color="white" class="lowShadow">
        <v-spacer />
        <v-btn color="primary" class="mb-2" outlined @click="novoUsuario()">
          Novo Usuário
        </v-btn>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="usuarios"
        :options.sync="options"
        :server-items-length="totalUsuarios"
        :loading="loading"
        loading-text="Carregando..."
        :footer-props="{
          itemsPerPageOptions: [20, 50, 100]
        }"
      >
        <template slot="headerCell" slot-scope="{ header }">
          <span
            class="subheading font-weight-light text--darken-3"
            v-text="header.text"
          />
        </template>
        <template v-slot:body="{ items }" ma-5>
          <tbody>
            <tr v-for="item in items" :key="item.name">
              <td class="text-left">
                <vAvatarUser :avatar-url="item.fotoUrl" />
              </td>
              <td>USR-{{ item.id }}</td>
              <td style="min-width: 150px;">
                {{ item.nome }}
              </td>
              <td>{{ item.email }}</td>
              <td style="min-width: 100px;">
                {{ getTypeGroupUser(item.role) }}
              </td>
              <td style="min-width: 200px;">{{ item.agenciaNome || "-" }}</td>
              <td class="text-left">
                <div v-if="getUserRole == 'ADMIN' || getUserRole == 'AGENCIA'">
                  <v-avatar size="45">
                    <v-icon
                      size="35"
                      small
                      color="blue"
                      title="Editar dados do usuário"
                      @click="editarUsuario(item)"
                      >mdi-pencil</v-icon
                    >
                  </v-avatar>
                  <v-avatar size="45">
                    <v-icon
                      size="35"
                      small
                      color="blue"
                      title="Editar dados do usuário"
                      @click="trocarSenha(item)"
                      >mdi-lock-reset</v-icon
                    >
                  </v-avatar>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>

    <vNovoUser
      v-if="modalNovoUsuario"
      @close="closeNovoUsuario"
      @update="atualizaLista"
    />
    <vEditUser
      v-if="modalEditUsuario"
      :user="payloadUser"
      @close="closeEditUsuario"
      @update="atualizaLista"
    />
    <vRedefinir
      v-if="modalTrocarSenha"
      :user="payloadUser"
      @close="closeTrocaSenha"
      @update="atualizaLista"
    />
  </v-container>
</template>

<script>
import vAvatarUser from "@/components/avatar.vue";
import vNovoUser from "./novoUsuario";
import vEditUser from "./editarUsuario";
import vRedefinir from "./redefinirSenha";
import userService from "@/services/userController";

export default {
  components: {
    vAvatarUser,
    vNovoUser,
    vEditUser,
    vRedefinir
  },
  filters: {
    filtraTipoDeConta(val) {
      if (val == "ADMIN") {
        return "Administração";
      }
    }
  },
  data() {
    return {
      valid: false,
      usuarios: [],
      modalNovoUsuario: false,
      modalEditUsuario: false,
      modalTrocarSenha: false,
      filtros: {
        email: "",
        nome: "",
        role: ""
      },
      page: 1,
      pageCount: 3,
      itemsPerPage: 10,
      totalUsuarios: 0,
      options: {},
      headers: [
        { text: "Avatar", value: "fotoUrl", align: "left" },
        { text: "ID", value: "usuarioId", align: "left" },
        { text: "Nome", value: "nome", align: "left" },
        { text: "Email", value: "email", align: "left" },
        { text: "Grupo", value: "role", align: "left" },
        { text: "Agência", value: "agenciaNome", align: "left" },
        { text: "Ações", value: "", align: "left" }
      ],
      editedIndex: -1,
      payloadUser: {
        nome: "",
        email: "",
        roleId: "",
        roleLabel: "",
        fotoUrl: "",
        senha: "",
        agenciaId: null,
        agenciaNome: null
      },
      rules: {
        required: value => !!value || "Campo obrigatório.",
        confirm: value =>
          (!!value && this.novaSenha === value) ||
          "As duas senhas precisam ser iguais!",
        confirmDelete: value =>
          (!!value && this.usuario.email === value) ||
          "O endereço de email precisa ser igual!",
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Endereço de email inválido.";
        }
      },
      loadingFoto: false,
      loading: false,
      loadingPesquisar: false,
      formData: null,
      file: {},
      emailExcluido: ""
    };
  },
  computed: {
    getUserRole() {
      return this.$store.getters["gestao/getUser"].role;
    },
    getTipos() {
      return this.$store.getters["gestao/getRolesManager"];
    },
    formTitle() {
      return this.usuario.id ? "Alterar Usuário" : "Novo Usuário";
    }
  },
  watch: {
    options: {
      handler() {
        this.paginar();
      },
      deep: true
    }
  },
  beforeMount() {
    if (this.getUserRole === "AGENCIA") {
      this.filtros.role = "AGENCIA";
    } else {
      this.filtros.role = "ADMIN";
    }
  },
  methods: {
    getTypeGroupUser(grupo) {
      let find = this.$store.getters["gestao/getRolesManager"].find(
        item => item.id === grupo
      );
      if (find) {
        return find.descricao;
      } else {
        return false;
      }
    },
    reload() {
      this.paginar();
    },
    novoUsuario() {
      this.usuario = {
        nome: "",
        email: "",
        roleId: "",
        roleLabel: "",
        fotoUrl: ""
      };
      this.modalNovoUsuario = true;
    },
    editarUsuario(item) {
      this.modalEditUsuario = true;
      this.payloadUser = item;
    },
    trocarSenha(item) {
      this.modalTrocarSenha = true;
      this.payloadUser = item;
    },
    atualizaLista() {
      this.paginar();
    },
    closeTrocaSenha() {
      this.modalTrocarSenha = false;
    },
    closeEditUsuario() {
      this.modalEditUsuario = false;
    },
    closeNovoUsuario() {
      this.modalNovoUsuario = false;
    },
    pesquisar() {
      this.loadingPesquisar = true;
      this.paginar();
    },
    paginar() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      userService
        .getPaginationUsers(page ? page - 1 : 0, itemsPerPage, this.filtros)
        .then(resp => {
          this.usuarios = resp.data.content;
          this.totalUsuarios = resp.data.totalElements;
        })
        .finally(() => {
          this.loadingPesquisar = false;
          this.loading = false;
        })
        .catch(err => {
          console.error(err);
        });
    },
    alterarSenha() {
      this.loading = true;
    }
  }
};
</script>

<style></style>
