import axios from "axios";
import store from "@/store/index.js";

function tratamentoDeNull(value) {
  if (value) return value;
  else return null;
}

export default {
  getUserConfigNotifications(userId) {
    return axios.get(`/v1/usuario/${userId}/config-notificacao`);
  },
  /**
   * @param {Object} payload - object with formaEnvio, notificacoes and usuarios
   * @param {Object} payload.formaEnvio - object with boolean values for whatsapp, email and sms
   * @param {Array} payload.notificacoes - array of objects with id, whatsapp, email and sms
   * @param {Array} payload.usuarios - array of id
   */
  registrarConfigNotificacao(payload) {
    return axios.post("/v1/usuario/config-notificacao", {
      formaEnvio: payload.formaEnvio,
      notificacoes: payload.notificacoes,
      usuarios: payload.usuarios
    });
  },
  paginationUserNotification(page, pageSize, filtros) {
    return axios.post("/v1/usuario/pagination-alunos-notificacao", {
      descending: true,
      direction: "ASC",
      filtros: {
        agenciaId: filtros.agenciaId,
        alunoId: filtros.alunoId,
        alunoStatus: filtros.alunoStatus,
        conviteStatus: filtros.conviteStatus,
        cpf: filtros.cpf,
        email: filtros.email,
        endereco: {
          bairro: filtros.endereco.bairro,
          cep: filtros.endereco.cep,
          cidade: filtros.endereco.cidade,
          complemento: filtros.endereco.complemento,
          ibge: filtros.endereco.ibge,
          localidade: filtros.endereco.localidade,
          logradouro: filtros.endereco.logradouro,
          uf: filtros.endereco.uf
        },
        id: filtros.id,
        mae: filtros.mae,
        nome: filtros.nome,
        nomeTurma: filtros.nomeTurma,
        pai: filtros.pai,
        permissao: filtros.permissao,
        rg: filtros.rg,
        telefone: filtros.telefone
      },
      pageNo: page,
      pageSize: pageSize,
      sortBy: "string"
    });
  },
  uploadImagem(file) {
    return axios.post(`/v1/usuario-manager/upload-foto-sem-usuario`, file, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  },
  updateUserPhoto(file) {
    let id = store.getters["gestao/getUser"].id;
    return axios.post(`/v1/usuario-manager/${id}/upload-foto`, file, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  },
  updatePasswordUser(payload) {
    return axios.post(`/v1/usuario-manager/update-senha`, {
      email: payload.email,
      fotoUrl: payload.fotoUrl,
      id: payload.id,
      nome: payload.nome,
      role: payload.role,
      senha: payload.senha
    });
  },
  loginGestao(mail, pass) {
    return axios.post("/v1/auth/login", { username: mail, password: pass });
  },
  getUser() {
    return axios.get(`/v1/usuario-manager/me`);
  },
  getPaginationUsers(pageNumb, pageSize, filtros) {
    return axios.post("/v1/usuario-manager/pagination", {
      descending: true,
      filtros: {
        email: filtros.email,
        nome: filtros.nome,
        role: filtros.role
      },
      pageNo: pageNumb,
      pageSize: pageSize,
      sortBy: "string"
    });
  },
  setNovoUsuarioAdministrativo(payload) {
    return axios.post("/v1/usuario-manager", {
      email: payload.email,
      fotoUrl: payload.fotoUrl,
      nome: payload.nome,
      role: payload.role,
      senha: payload.senha,
      agenciaId: payload.agenciaId,
      agenciaNome: payload.agenciaNome
    });
  },
  updateUsuarioAdministrativo(payload) {
    return axios.post(`/v1/usuario-manager/${payload.id}`, {
      email: payload.email,
      fotoUrl: payload.fotoUrl,
      id: payload.id,
      nome: payload.nome,
      role: payload.role,
      senha: payload.senha,
      agenciaId: payload.agenciaId,
      agenciaNome: payload.agenciaNome
    });
  }
};
