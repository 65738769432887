export default {
  path: "/error",
  name: "error",
  component: () => import("@/views/erros/index.vue"),
  redirect: "/error/404",
  children: [
    {
      path: "404",
      name: "404",
      component: () => import("@/views/erros/404.vue")
    },
    {
      path: "401",
      name: "401",
      component: () => import("@/views/erros/401.vue")
    }
  ]
};
