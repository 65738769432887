<template>
  <div>
    <div class="d-grid grid-col-1-auto pa-3 py-5 grey lighten-3">
      <div class="pl-6 text-h6 font-weight-bold grey--text text--darken-4">
        Sala de atendimento
      </div>
      <div>
        <v-btn text @click="$emit('close')">
          <v-icon color="black">
            mdi-close
          </v-icon>
        </v-btn>
      </div>
    </div>

    <div v-if="loading" class="text-center pa-4">
      <v-progress-circular
        size="55"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-else class="pa-0">
      <div
        class="text-center pa-1 font-weight-bold"
        :class="{
          'purple darken-2 white--text': data.status == 'EM_ANDAMENTO',
          'amber accent-4 white--text': data.status == 'PENDENTE',
          'green darken-2 white--text': data.status == 'CONCLUIDO',
          'red darken-4 white--text': data.status == 'CANCELADO'
        }"
      >
        {{ data.status.toUpperCase() | statusNominal }}
      </div>
      <div class="scopeChamado grey lighten-5 pa-3">
        <center>
          <v-icon size="44" color="black" class="mt-n0"
            >mdi-format-quote-close-outline</v-icon
          >
        </center>
        <div
          class="grey--text text--darken-3 text-center font-weight-bold text-subtitle-1 pa-3"
        >
          {{ data.titulo }}
        </div>
        <div
          class="grey--text text--darken-3 px-4 text-center text-subtitle-2 font-weight-light"
        >
          {{ data.descricao }}
        </div>
        <div class="options text-center pa-2">
          <v-btn
            v-if="data.status == 'EM_ANDAMENTO' || data.status == 'PENDENTE'"
            text
            color="green"
            @click="concluirAtendimento()"
          >
            Concluir atendimento
          </v-btn>
        </div>
      </div>

      <div class="bodyChat white">
        <div class="pa-2 py-2 text-center">
          <div class="font-weight-black pa-2">
            Mensagens
          </div>
        </div>

        <div class="lock">
          <v-card
            v-for="(msg, index) in data.mensagens"
            :key="index"
            class="item pa-2 pt-2 ma-4 white elevation-0"
          >
            <v-row justify="center">
              <v-col cols="3" sm="2" class="text-center">
                <v-avatar
                  v-if="msg.origem == 'ATENDENTE'"
                  size="55"
                  color="grey lighten-3"
                >
                  <v-icon size="38" color="primary">
                    mdi-school
                  </v-icon>
                  <!--<v-img contain src="@/assets/logo.png"></v-img>-->
                </v-avatar>
                <v-avatar
                  v-if="msg.origem == 'ALUNO'"
                  size="55"
                  color="grey lighten-3"
                >
                  <v-icon size="38" color="primary">
                    mdi-account
                  </v-icon>
                </v-avatar>
                <!--
                    EU TENHO O ID DO ALUNO ?
                    <v-img v-if="getUser.fotoUrl" :src="getUser.fotoUrl"></v-img>
                  <v-img
                    v-if="!getUser.fotoUrl"
                    src="@/assets/sem-foto-perfil.png"
                  ></v-img>-->
              </v-col>
              <v-col cols="9" sm="10">
                <div class="pb-3">
                  <span class="d-inline-block text-left font-weight-bold">
                    {{ msg.nome }}
                  </span>
                  <span
                    class="d-inline-block font-weight-light grey--text text-body-2 ml-3 text-left"
                    >{{ msg.data | dataFormate }}</span
                  >
                </div>
                <div class="text-left font-weight-light text-body-2">
                  {{ msg.comentario }}
                </div>
              </v-col>
            </v-row>
          </v-card>
          <div
            v-if="data.status == 'PENDENTE' || data.status == 'EM_ANDAMENTO'"
            class="pa-3 px-5"
          >
            <v-row justify="center">
              <v-col cols="12" sm="9">
                <v-text-field
                  ref="inputMensagem"
                  v-model="include.mensagem"
                  label="Mensagem"
                  hide-details
                  maxlength="120"
                  counter
                  outlined
                  class="elevation-0"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" class="text-center">
                <v-btn
                  large
                  color="primaryDash"
                  dark
                  depressed
                  block
                  :loading="loadingBtn"
                  @click="enviarMensagem()"
                  >ENVIAR</v-btn
                >
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import serviceAtendimento from "@/services/atendimento";
import moment from "moment";
export default {
  filters: {
    statusNominal(val) {
      if (val == "EM_ANDAMENTO") {
        return "Em andamento";
      } else if (val == "CONCLUIDO") {
        return "Concluído";
      } else if (val == "CANCELADO") {
        return "Cancelado";
      }
    },
    dataFormate(val) {
      if (val) {
        return moment(val).format("DD/MM/YYYY");
      } else {
        return "undefined";
      }
    }
  },
  props: ["uid"],
  data() {
    return {
      loadingBtn: false,
      loading: true,
      data: {},
      include: {
        mensagem: ""
      }
    };
  },
  computed: {
    getUser() {
      return this.$store.getters["gestao/getUser"];
    }
  },
  mounted() {
    this.requestAtendimento();
  },
  methods: {
    concluirAtendimento() {
      serviceAtendimento
        .concluirAtendimento(this.data.id)
        .then(() => {
          this.requestAtendimento();
        })
        .catch(err => {
          this.showError(err.data);
        });
    },
    enviarMensagem() {
      this.loadingBtn = true;
      if (this.include.mensagem && this.include.mensagem.length > 1) {
        let mensagem = {
          atendimentoId: this.data.id,
          comentario: this.include.mensagem
        };
        serviceAtendimento
          .novaMensagem(mensagem)
          .then(() => {
            //para carregar novamente as mensagens
            this.requestAtendimento();
          })
          .catch(err => {
            this.$root.showError(err);
          })
          .finally(() => {
            this.include.mensagem = "";
            this.loadingBtn = false;
          });
      } else {
        this.loadingBtn = false;
        this.$root.showError("Desculpe não pode ser enviado mensagem vazia");
      }
    },
    requestNoLoad() {
      serviceAtendimento
        .getAtendimento(this.uid)
        .then(resp => {
          this.data = resp.data;
        })
        .finally(() => {
          this.loading = false;
        })
        .catch(err => {
          this.$root.showError();
          console.log(err.data);
        });
    },
    requestAtendimento() {
      this.loading = true;
      serviceAtendimento
        .getAtendimento(this.uid)
        .then(resp => {
          console.log(resp.data);
          this.data = resp.data;
        })
        .finally(() => {
          this.loading = false;
        })
        .catch(err => {
          console.log(err.data);
        });
    }
  }
};
</script>
