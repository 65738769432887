<template>
  <v-chip
    :disabled="disabled"
    small
    :value="computedConfigBol"
    :outlined="!computedConfigBol"
    :color="primary"
    @click="handlerClick"
  >
    <div class="contentChip">
      <v-icon size="15" :color="computedConfigBol ? secondary : primary">
        {{ icon }}
      </v-icon>
      <div
        style="margin-left: 5px; font-size: 12px"
        :style="{ color: getStyleColorText }"
      >
        {{ text }}
      </div>
    </div>
  </v-chip>
</template>

<script>
export default {
  name: "VDinamicChip",
  props: {
    icon: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    configBol: {
      type: Boolean,
      default: false,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      primary: "rgb(40, 120, 200)",
      secondary: "rgb(255,255,255)",
    };
  },
  computed: {
    computedConfigBol() {
      return this.configBol;
    },
    getStyleColorText() {
      if (!this.configBol) {
        return this.primary;
      } else {
        return this.secondary;
      }
    },
  },
  methods: {
    handlerClick() {
      this.$emit("action", !this.configBol);
    },
  },
};
</script>

<style lang="scss" scoped>
.contentChip {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
</style>
