<template>
  <v-container fluid class="text-center">
    <v-card elevation="0" class="px-5 py-5 px-sm-12 mx-auto" max-width="500">
      <div class="logoContain text-center pa-2 my-5">
        <img src="@/assets/logo-completa.png" width="150" class="logo" />
      </div>
      <div class="pa-2 font-weight-normal">
        Carregando dependências
      </div>
      <div class="carregando">
        <v-progress-linear
          color="primary"
          :value="loadingValue"
        ></v-progress-linear>
      </div>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      loadingValue: 0
    };
  },
  watch: {
    loadingValue: function(val) {
      if (val >= 100) {
        setTimeout(() => {
          this.closeLoading();
        }, 500);
      }
    }
  },
  mounted() {
    this.carregarUsuario();
    this.carregarTiposDeUsers();
  },
  methods: {
    carregarTiposDeUsers() {
      this.$store
        .dispatch("gestao/REQUEST_TYPES_USER")
        .then(() => {
          this.loadingValue += 50;
        })
        .catch(err => {
          this.$root.showError(err);
          this.$router.push("/login");
        });
    },
    carregarUsuario() {
      this.$store
        .dispatch("gestao/REQUEST_USER")
        .then(() => {
          this.loadingValue += 50;
        })
        .catch(err => {
          this.$root.showError(err.data);
          this.loadingValue = 100;
          this.$router.push("/login");
        });
    },
    closeLoading() {
      this.$store.commit("gestao/setLoader", false);
    }
  }
};
</script>

<style></style>
