import axios from "axios";

export default {
  novoConcurso(payload) {
    // ==== exemplo de payload ====
    // descricao	string
    // id	integer($int64)
    // imagem	string
    // nome	string
    // nuCertificadoAutorizacao	string
    // organizadorId	integer($int64)
    // percentualCasa	number
    // periodoParticipacaoFinal	Calendar{...}
    // periodoParticipacaoInicial	Calendar{...}
    // periodoPromocaoFinal	Calendar{...}
    // periodoPromocaoInicial	Calendar{...}
    // valorBilhete
    return axios.post("/v1/concurso", payload);
  },
  getConcursoAtual() {
    return axios.get("/v1/concurso/atual");
  },
  getConcursos() {
    return axios.get("/v1/concurso");
  }
};
