import axios from "axios";

export default {
  buscar(payload) {
    return axios.post("/v1/curso/search", { texto: payload });
  },
  includeCurso(payload) {
    //id, nome
    return axios.post("/v1/curso", payload);
  },
  getCursos() {
    return axios.get("/v1/curso");
  },
  getPagination(payload) {
    return axios.post("/v1/curso/pagination", {
      descending: false,
      direction: "ASC",
      filtros: {
        id: null,
        nome: payload.nome
      },
      pageNo: payload.page,
      pageSize: payload.pageSize,
      sortBy: "nome"
    });
  },
  getCursoId(id) {
    return axios.get(`/v1/curso/${id}`);
  },
  setCurso(payload) {
    return axios.post("/v1/curso", {
      id: payload.id,
      nome: payload.nome
    });
  },
  buscaCursoPorNome(texto) {
    return axios.post("/v1/curso/search", texto);
  }
};
