<template>
  <div>
    <vTitulo>INSTITUIÇÕES</vTitulo>
    <div class="mx-4">
      <v-card class="pa-4 transparent elevation-0">
        <v-row justify="center" align="center">
          <v-col cols="6">
            <v-text-field
              v-model="filtros.nome"
              hide-details
              name="name"
              label="Nome da instituição"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="text-right">
            <v-btn depressed color="primary" @click="buscarNome()">
              <v-icon>mdi-magnify</v-icon>
              Buscar
            </v-btn>
            <v-btn class="ml-1" depressed color="primary" @click="novoItem()">
              <v-icon>mdi-plus</v-icon>
              Novo
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <div class="py-2">
      <div class="pa-3 px-5 grey--text text--darken-3 text-body-1">
        Existe <strong>{{ totalItens }}</strong> itens encontrados
      </div>

      <div class="lista">
        <v-card
          v-if="getListaInstituicoes.length == 0"
          class="pa-5 ma-2 elevation-0 transparent font-weight-bold text-center "
        >
          Sem resultado
        </v-card>
        <v-card
          v-for="(item, index) in getListaInstituicoes"
          :key="index"
          class="pa-2 ma-2 lowShadow"
        >
          <v-row justify="center" align="center">
            <v-col cols="8">
              {{ item.nome }}
            </v-col>
            <v-col cols="4" class="text-right">
              <v-btn text color="orange" @click="editarInstituicao(item)">
                <v-icon>
                  mdi-pencil
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </div>
      <div class="uiList pa-5 text-center">
        <v-pagination
          v-if="totalPaginas > 0"
          v-model="page"
          :length="totalPaginas"
          @input="nextPage"
        ></v-pagination>
      </div>
    </div>
    <vNovoItem
      v-if="modalNovoItem"
      :editar="payloadItem"
      @update="nextPage"
      @close="modalNovoItem = false"
    />
  </div>
</template>

<script>
import serviceInstituicoes from "@/services/instituicoes";
import vNovoItem from "./includeEdit.vue";

export default {
  components: {
    vNovoItem
  },
  data() {
    return {
      filtros: {
        nome: "",
        id: null
      },
      totalItens: 0,
      listaInstituicoes: [],
      page: 1,
      totalPaginas: 0,
      pageSize: 20,
      modalNovoItem: false,
      payloadItem: {}
    };
  },
  computed: {
    getListaInstituicoes() {
      return this.listaInstituicoes;
    }
  },
  mounted() {
    this.nextPage();
  },
  methods: {
    editarInstituicao(item) {
      this.payloadItem = item;
      this.modalNovoItem = true;
    },
    buscarNome() {
      this.page = 1;
      let payload = {
        nome: this.filtros.nome,
        page: 0,
        pageSize: this.pageSize
      };
      this.requestPagination(payload);
    },
    requestPagination(payload) {
      serviceInstituicoes
        .getPagination(payload)
        .then(resp => {
          this.totalPaginas = resp.data.totalPages;
          this.listaInstituicoes = resp.data.content;
          this.totalItens = resp.data.totalElements;
          console.log(resp.data);
        })
        .catch(err => {
          this.$root.showError(err.data);
          console.log(err.data);
        });
    },
    nextPage() {
      let payload = {
        nome: this.filtros.nome,
        page: this.page - 1,
        pageSize: this.pageSize
      };
      this.requestPagination(payload);
    },
    novoItem() {
      this.payloadItem = {};
      this.modalNovoItem = true;
    }
  }
};
</script>
