<template>
  <v-dialog v-model="dialog" persistent max-width="700px">
    <v-card class="white">
      <div v-if="modo == 'delete'">
        <div class="text-center">
          Tem certeza que deseja excluir essa agencia?
        </div>
        <div class="grid grid-cols-2">
          <div>
            <v-btn @click="$emit('close')">Não</v-btn>
          </div>
          <div>
            <v-btn @click="deleteAgencia()">Sim</v-btn>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="pa-5 pb-0 font-weight-medium">
          {{ renderTitulo }} Agência
        </div>

        <div class="pa-5 pt-0">
          <div class="sectionLabel">
            Dados da agência
          </div>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="agenciaPayload.nome"
                label="Nome da Agência"
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="agenciaPayload.cnpj"
                v-mask="['##.###.###/####-##']"
                label="CNPJ"
                dense
                hide-details
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="agenciaPayload.responsavel"
                label="Responsável da Agência"
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="agenciaPayload.telefone"
                label="Telefone da Agência"
                dense
                hide-details
              />
            </v-col>
          </v-row>

          <div class="sectionLabel">
            Endereço
          </div>
          <div>
            <v-row>
              <v-col cols="8">
                <v-text-field
                  v-model="agenciaPayload.enderecoCompleto.cep"
                  v-mask="['#####-###']"
                  label="CEP"
                  dense
                  hide-details
                />
              </v-col>
              <v-col cols="4">
                <v-btn
                  color="primary"
                  depressed
                  small
                  hide-details
                  @click="preencherComCep()"
                  >Preencher com CEP</v-btn
                >
              </v-col>
            </v-row>
          </div>
          <v-row>
            <v-col cols="12" sm="6">
              <v-select
                v-model="formSelectEstado"
                :items="listaEstados"
                label="Estado *"
                item-text="nome"
                item-value="id"
                return-object
                hide-details
                required
                :loading="loadingEstado"
                @change="onChangeEstado"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="formSelectCidade"
                :items="listaCidades"
                label="Cidade * "
                item-text="descricao"
                item-value="id"
                return-object
                hide-details
                required
                :loading="loadingCidade"
                @change="onChangeCidade"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="agenciaPayload.enderecoCompleto.logradouro"
                label="Logradouro"
                dense
                required
                hide-details
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="agenciaPayload.enderecoCompleto.bairro"
                label="Bairro"
                dense
                required
                hide-details
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="agenciaPayload.enderecoCompleto.numero"
                label="Numero"
                dense
                required
                hide-details
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="agenciaPayload.enderecoCompleto.complemento"
                label="Complemento"
                dense
                hide-details
              />
            </v-col>
          </v-row>
        </div>
        <div id="actions" class="pa-3 mt-2">
          <div v-if="modo == 'novo'" class="grid grid-cols-2 text-center">
            <div>
              <v-btn color="gray" depressed @click="$emit('close')"
                >Cancelar</v-btn
              >
            </div>
            <div>
              <v-btn color="primary" depressed @click="cadastrarAgencia"
                >Cadastrar</v-btn
              >
            </div>
          </div>
          <div v-if="modo == 'editar'" class="grid grid-cols-2 text-center">
            <div>
              <v-btn color="gray" depressed @click="$emit('close')"
                >Fechar</v-btn
              >
            </div>
            <div>
              <v-btn color="primary" depressed @click="salvarEdicaoAgencia()"
                >Salvar Alterações</v-btn
              >
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import ServiceLocalidade from "@/services/localidade";
import ServiceAgencia from "@/services/agencias";
// modo editar, novo, delete
export default {
  props: {
    agencia: {
      type: Object,
      required: true
    },
    modo: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dialog: true,
      listaEstados: [],
      listaCidades: [],
      formSelectEstado: null,
      formSelectCidade: null,
      loadingEstado: false,
      loadingCidade: false,
      agenciaPayload: {
        enderecoCompleto: {}
      }
    };
  },
  computed: {
    renderTitulo() {
      return this.modo == "novo" ? "Novo" : "Editando";
    }
  },
  async mounted() {
    await this.carregaEstado();
    //agenciaPayload.enderecoCompleto.estado
    if (this.modo === "novo") {
      this.agenciaPayload = {
        enderecoCompleto: {}
      };
    } else {
      this.agenciaPayload = { ...this.agencia };
      this.processaCarregamentoEstado();
    }
  },
  methods: {
    async processaCarregamentoEstado() {
      // !!todo: será usando no modo editar
      const estadoPayload = this.agenciaPayload.enderecoCompleto.estado;
      let estadoFind = this.listaEstados.find(
        item => item.uf === estadoPayload
      );
      if (estadoFind) {
        this.formSelectEstado = estadoFind;
        await this.carregaCidade(estadoFind);
        this.formSelectCidade = this.listaCidades.find(
          item =>
            item.descricao === this.agenciaPayload.enderecoCompleto.cidadeNome
        );
      } else {
        this.formSelectEstado = null;
        console.error("Estado não encontrado");
      }
    },
    async carregaEstado() {
      await ServiceLocalidade.getEstado()
        .then(resp => {
          this.listaEstados = resp.data;
        })
        .catch(err => {
          console.error(err);
          this.$root.showError(err);
        });
    },
    async carregaCidade(estado) {
      const queryEstado = estado.id;
      await ServiceLocalidade.getCidadeForEstado(queryEstado)
        .then(resp => {
          this.listaCidades = resp.data;
        })
        .catch(err => {
          console.error(err);
          this.$root.showError(err);
        });
    },
    onChangeEstado(itemEstado) {
      this.agenciaPayload.enderecoCompleto.estado = itemEstado.uf;
      this.carregaCidade(itemEstado);
    },
    onChangeCidade(val) {
      if (val) {
        const { id } = val;
        this.getCidadeIBGE(id);
      }
    },
    getCidadeIBGE(id) {
      ServiceLocalidade.getCidadeId(id)
        .then(resp => {
          this.agenciaPayload.enderecoCompleto.cidadeIbge = Number(
            resp.data.ibge
          );
        })
        .catch(err => {
          console.error(err);
          this.$root.showError(err);
        });
    },
    validations() {
      if (!this.agenciaPayload.nome) {
        this.$root.showError("Por favor, insira o nome");
        return false;
      }
      if (!this.agenciaPayload.responsavel) {
        this.$root.showError("Por favor, insira o nome do responsavel");
        return false;
      }
      if (!this.$root.validaCNPJ(this.agenciaPayload.cnpj)) {
        this.$root.showError("Por favor, insira um CNPJ válido");
        return false;
      }
      if (
        !this.agenciaPayload.enderecoCompleto.cep ||
        this.agenciaPayload.enderecoCompleto.cep.length < 9
      ) {
        this.$root.showError("Por favor, insira o CEP");
        return false;
      }
      if (!this.agenciaPayload.enderecoCompleto.estado) {
        this.$root.showError("Por favor, insira o Estado");
        return false;
      }
      if (
        !this.agenciaPayload.enderecoCompleto.cidadeNome &&
        !this.agenciaPayload.enderecoCompleto.cidadeIbge
      ) {
        this.$root.showError("Por favor, insira a Cidade");
        return false;
      }
      if (!this.agenciaPayload.enderecoCompleto.logradouro) {
        this.$root.showError("Por favor, insira o Logradouro");
        return false;
      }
      if (!this.agenciaPayload.enderecoCompleto.bairro) {
        this.$root.showError("Por favor, insira o Bairro");
        return false;
      }
      return true;
    },
    salvarEdicaoAgencia() {
      if (!this.validations()) {
        return;
      }
      const payload = { ...this.agenciaPayload };
      ServiceAgencia.alterarAgencia(payload)
        .then(resp => {
          this.$root.showSucesso("Alterado com sucesso");
          this.$emit("update");
          this.$emit("close");
        })
        .catch(err => {
          this.$root.showError(err);
          console.log(err);
        });
    },
    cadastrarAgencia() {
      if (!this.validations()) {
        return;
      }
      const payload = { ...this.agenciaPayload };
      ServiceAgencia.cadastrarAgencia(payload)
        .then(resp => {
          this.$root.showSucesso("Cadastrado com sucesso");
          this.$emit("update");
          this.$emit("close");
        })
        .catch(err => {
          this.$root.showError(err);
        });
    },
    async tratamentoDeDadosViaCep(response) {
      const estadoRecebido = this.listaEstados.find(
        item => item.uf === response.uf
      );

      this.formSelectEstado = estadoRecebido;
      await this.carregaCidade(estadoRecebido);

      this.formSelectCidade = this.listaCidades.find(
        item => item.descricao === response.localidade
      );

      let payloadTratadoCep = {
        bairro: response.bairro,
        cidadeNome: response.localidade,
        cidadeIbge: Number(response.ibge),
        estado: estadoRecebido.uf,
        logradouro: response.logradouro
      };

      this.agenciaPayload.enderecoCompleto = {
        ...this.agenciaPayload.enderecoCompleto,
        ...payloadTratadoCep
      };
    },
    preencherComCep() {
      if (
        this.agenciaPayload.enderecoCompleto.cep &&
        this.agenciaPayload.enderecoCompleto.cep.length == 9
      ) {
        ServiceLocalidade.getCep(this.agenciaPayload.enderecoCompleto.cep)
          .then(resp => {
            console.log(resp);
            this.tratamentoDeDadosViaCep(resp.data);
          })
          .catch(err => {
            console.error(err);
            this.$root.showError(err);
          });
        // continua o processo
      } else {
        // caso não tenha o cep informado, aborta o processo
        this.$root.showError("Por favor informe o CEP corretamente");
        return;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.sectionLabel {
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 0.85rem;
  text-transform: uppercase;
  font-weight: 500;
  color: rgb(50, 50, 70, 1);
  padding: 10px;
  padding-left: 20px;
  border-left: 5px solid rgb(50, 50, 50, 0.2);
}
</style>
