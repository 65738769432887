// designado para auxilio e tratamento de erros

export function ErrorTranslate(err) {
  if (err) {
    if (err.data) {
      if (err.data.message) {
        return `${err.data.message}`;
      }
      return `${err.data}`;
    }
    return `${err}`;
  } else {
    return "Ocorreu um erro inesperado";
  }
}
