<template>
  <div>
    <v-dialog v-model="dialogConfirmar" max-width="500" persistent>
      <v-card class="pa-5 white">
        <div>
          Você confirma que esse número sorteado
          <strong>{{ numLoteriaFederal }}</strong>
          está correto?
        </div>
        <div class="mt-5">
          <v-row>
            <v-col cols="12" sm="6" class="text-center">
              <v-btn text color="red" @click="close()">Cancelar</v-btn>
            </v-col>
            <v-col cols="12" sm="6" class="text-center">
              <v-btn text color="primary" @click="confirmar()">Confirmar</v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// k
export default {
  props: {
    numLoteriaFederal: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dialogConfirmar: true
    };
  },
  methods: {
    confirmar() {
      this.$emit("confirmar");
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style></style>
