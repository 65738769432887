<template>
  <div>
    <compLoading v-if="getLoading" />
    <compDashboard v-else />
  </div>
</template>

<script>
import compDashboard from "./dashboard";
import compLoading from "./loading";

export default {
  components: { compDashboard, compLoading },
  data() {
    return {
      loading: true
    };
  },
  computed: {
    getLoading() {
      return this.$store.getters["gestao/getLoading"];
    }
  }
};
</script>

<style></style>
