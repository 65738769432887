import Vue from "vue";
import VueRouter from "vue-router";
import vGestaoFilhos from "./dashboard";
import vErros from "./erros";
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "root",
      component: () => import("@/root.vue"),
      redirect: "/login"
    },
    {
      path: "/suporte",
      name: "suporte",
      component: () => import("@/views/suporte/index.vue"),
      redirect: "/login"
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/login.vue")
    },
    {
      path: "*",
      name: "sem-rota",
      redirect: "/error/404"
    },
    vErros,
    vGestaoFilhos
  ]
});

export default router;
