<template>
  <v-container
    style="padding: 0; margin: 0; background-color: rgba(0,0,150,0.05); height: 100vh;"
    fluid
    fill-height
  >
    <v-row align="center" no-gutters>
      <v-col cols="12">
        <center>
          <div class="pb-5 mb-5 d-block">
            <div>
              <img src="../assets/logo-completa.png" class="logo" />
            </div>
            <div>
              <div class="text-subtitle-2 grey--text text--darken-4">
                <div class="chipCustom">Gestão</div>
              </div>
            </div>
          </div>

          <div class="cardCustom">
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              @submit.prevent="validateBeforeSubmit"
            >
              <div>
                <div class="titleCardCustom">
                  Acesso
                </div>
                <div>
                  <div>
                    <v-text-field
                      v-model.trim="usuario"
                      :rules="[rules.required, rules.email]"
                      label="Email"
                      @keydown.space.prevent
                    ></v-text-field>
                  </div>
                  <div>
                    <v-text-field
                      v-model="senha"
                      :rules="[rules.required]"
                      label="Senha"
                      type="password"
                    ></v-text-field>
                  </div>
                  <div class="actionButtons">
                    <v-btn
                      :disabled="!valid"
                      :loading="loading"
                      depressed
                      class="text-capitalize"
                      color="primary"
                      type="submit"
                      large
                      min-width="200"
                    >
                      Entrar
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-form>
          </div>
        </center>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import userController from "@/services/userController";
export default {
  data() {
    return {
      valid: false,
      loading: false,
      usuario: "",
      senha: "",
      rules: {
        required: value => !!value || "Campo obrigatório.",
        counter: value => value.length <= 80 || "Max 80 caracteres",
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Endereço de email inválido.";
        }
      }
    };
  },
  methods: {
    //validaCampos() {},
    verificaPermissaoUser() {
      /*if (!this.validaCampos()) {
        return;
      }*/
      userController
        .getUser()
        .then(() => {
          this.$root.showSucesso("Autenticado com sucesso!");
          this.$router.push("/dashboard");
        })
        .finally(() => {
          this.loading = false;
        })
        .catch(err => {
          if (err.data.message == "Forbidden") {
            this.$root.showError(
              "Você pode está logando com a conta errada, você não tem acesso ao administrativo"
            );
          } else {
            this.$root.showError(err);
          }
        });
    },
    logarUser() {
      localStorage.removeItem("APT-ADMTOKEN");
      this.loading = true;
      let email = this.usuario;
      let pass = this.senha;
      userController
        .loginGestao(email, pass)
        .then(resp => {
          let tokenCompleto = `${resp.data.tokenType} ${resp.data.accessToken}`;
          localStorage.setItem("APT-ADMTOKEN", tokenCompleto);
          this.verificaPermissaoUser();
        })
        .finally(() => {
          this.loading = false;
        })
        .catch(err => {
          localStorage.clear();
          this.$root.showError(err);
        });
    },
    validateBeforeSubmit() {
      if (this.$refs.form.validate()) {
        this.logarUser();
      } else {
        this.$root.showError("Informe um e-mail e uma senha");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.chipCustom {
  padding: 4px 25px;
  font-size: 14px;
  border-radius: 4px;
  background-color: rgb(175, 175, 175, 0.2);
  color: rgb(0, 0, 0);
  font-weight: 300;
  display: inline-block;
}
.cardCustom {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  display: inline-block;
  max-width: 400px;
  min-width: 400px;
  box-shadow: 1px 1px 3px rgba(50, 50, 50, 0.2);

  @media screen and (max-width: 410px) {
    min-width: 100%;
  }

  .titleCardCustom {
    font-weight: 400;
    font-size: 18px;
    color: rgb(77, 77, 77);
    margin-bottom: 10px;
    font-family: "Poppins", sans-serif;
  }
  .actionButtons {
    margin-top: 25px;
  }
}
.logo {
  width: 150px;
}
.img-login {
  position: absolute;
  height: 100vh;
  top: 0;
  right: 50%;
}
.img-login-mobile {
  position: fixed;
  height: 50vh;
  top: 0;
  left: 0;
}
</style>
