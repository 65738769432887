<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="700px">
      <v-card class="pa-4 py-2">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="cadastrar"
        >
          <v-card-title>
            <span class="headline">Novo Usuário</span>
          </v-card-title>
          <div class="pa-2">
            <v-row>
              <v-col align="center" cols="12">
                <avatar
                  large
                  :avatar-url="include.fotoUrl"
                  :loading="loadingFoto"
                />
                <br />
                <v-form enctype="multipart/form-data">
                  <label
                    for="foto"
                    style="cursor: pointer"
                    class="caption"
                    title="avatar"
                  >
                    {{ formData ? formData.get("name") : "Troque o avatar" }}
                    <v-icon color="dark" for="foto">mdi-camera</v-icon>
                    <input
                      id="foto"
                      style="display: none"
                      type="file"
                      accept=".jpg,.png,.jpeg"
                      @change="uploadAvatar($event)"
                    />
                  </label>
                </v-form>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-text-field
                  v-model="include.nome"
                  label="Nome*"
                  required
                  hide-details
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col v-if="getUser.role !== 'AGENCIA'" cols="12" md="6" sm="12">
                <v-select
                  v-model="include.role"
                  :items="getTiposDeUser"
                  item-text="descricao"
                  item-value="id"
                  label="Tipo de conta"
                  required
                  hide-details
                  :rules="[rules.required]"
                ></v-select>
              </v-col>
              <v-col
                v-if="include.role === 'AGENCIA' && getUser.role !== 'AGENCIA'"
                cols="12"
                md="6"
                sm="12"
              >
                <v-autocomplete
                  :items="listaDeAgencias"
                  :search-input.sync="searchAgencia"
                  search="timeSearch"
                  color="primary"
                  label="Agência"
                  item-text="nome"
                  item-value="id"
                  placeholder=""
                  prepend-inner-icon="mdi-domain"
                  return-object
                  hide-details
                  class="text-truncate"
                  :disabled="loading"
                  @change="changeAgencia($event)"
                >
                  <template v-slot:selection="data">
                    <div>
                      <v-chip>
                        {{ data.item.nome }}
                      </v-chip>
                    </div>
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-icon>
                        <div>
                          <v-icon color="rgba(50,50,50,0.2)">mdi-school</v-icon>
                        </div>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ data.item.nome }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                  <template slot="no-data">
                    <div class="px-2 py-1">
                      Sem dados referente a pesquisa
                    </div>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-text-field
                  v-model="include.email"
                  label="Email*"
                  :rules="[rules.required, rules.email]"
                  hint="esse email será utilizado para entrar no sistema"
                  hide-details
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <template v-if="include.id">
                  <v-btn
                    v-if="!flgAlterarSenha"
                    @click="flgAlterarSenha = true"
                  >
                    Alterar Senha
                  </v-btn>
                  <template v-else>
                    <v-text-field
                      v-model="include.senha"
                      label="Senha*"
                      type="password"
                      hide-details
                      required
                      :rules="[rules.required]"
                    />
                    <v-btn :loading="loading" @click="alterarSenha">
                      Salvar Senha
                    </v-btn>
                  </template>
                </template>
                <template v-else>
                  <v-text-field
                    v-model="include.senha"
                    label="Senha*"
                    type="password"
                    hide-details
                    required
                    :rules="[rules.required]"
                  />
                </template>
              </v-col>
            </v-row>
          </div>
          <div class="pa-2 mt-5">
            <v-row justify="center" align="center">
              <v-col cols="12" sm="6" class="text-center">
                <v-btn color="primary" outlined @click="close()">
                  Cancelar
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6" class="text-center">
                <v-btn
                  :disabled="!valid"
                  color="primary"
                  type="submit"
                  :loading="loading"
                >
                  Cadastrar
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <!--
          <v-card-actions class="pa-2">
            <v-spacer></v-spacer>
          </v-card-actions>-->
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import controllerUser from "@/services/userController";
import ServiceAgencia from "@/services/agencias";
import avatar from "@/components/avatar";

export default {
  name: "NovoUser",
  components: {
    avatar
  },
  data() {
    return {
      timeSearch: 500,
      listaDeAgencias: [],
      clockSearch: null,
      searchAgencia: "",
      loadingFoto: false,
      valid: false,
      dialog: true,
      loading: false,
      roles: [],
      include: {
        email: "",
        fotoUrl: "",
        id: null,
        nome: "",
        role: "ADMIN",
        senha: "",
        agenciaId: null,
        agenciaNome: null
      },
      formData: "",
      rules: {
        required: value => !!value || "Campo obrigatório.",
        /*confirm: value =>
          (!!value && this.novaSenha === value) ||
          "As duas senhas precisam ser iguais!",*/
        confirmDelete: value =>
          (!!value && this.include.email === value) ||
          "O endereço de email precisa ser igual!",
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Endereço de email inválido.";
        }
      }
    };
  },
  computed: {
    getTiposDeUser() {
      return this.$store.getters["gestao/getRolesManager"];
    },
    getUser() {
      return this.$store.getters["gestao/getUser"];
    }
  },
  watch: {
    "include.role": function(val) {
      if (val !== "AGENCIA") {
        this.include.agenciaId = null;
        this.include.agenciaNome = null;
      }
    },
    searchAgencia: function(newValue) {
      this.debounceSearchAgencia(newValue);
    }
  },
  beforeMount() {
    const user = this.getUser;
    if (user.role === "AGENCIA") {
      this.include.role = "AGENCIA";
      this.include.agenciaId = user.agenciaId;
      this.include.agenciaNome = user.agenciaNome;
    }
  },
  methods: {
    debounceSearchAgencia() {
      clearTimeout(this.clockSearch);
      const search = this.searchAgencia;
      const self = this;
      this.clockSearch = setTimeout(() => {
        if (!search || search === "" || search.length <= 2) {
          return false;
        }
        self.buscarAgencia(search);
      }, self.timeSearch);
    },
    buscarAgencia(search) {
      ServiceAgencia.searchAgencia(search)
        .then(resp => {
          this.listaDeAgencias = resp.data;
        })
        .catch(err => {
          console.error(err);
        });
    },
    changeAgencia(ev) {
      this.include.agenciaId = ev.id;
      this.include.agenciaNome = ev.nome;
    },
    close() {
      this.$emit("close");
    },
    uploadAvatar(event) {
      if (event && event.target && event.target.files.length > 0) {
        this.file = event.target.files[0];
        this.formData = new FormData();
        this.formData.append("file", this.file);
        this.formData.append("name", this.file.name);
        this.loadingFoto = true;
        controllerUser
          .uploadImagem(this.formData)
          .then(resp => {
            this.loadingFoto = false;
            this.include.fotoUrl = resp.data;
          })
          .catch(err => {
            this.$root.showError(err.data);
          })
          .finally(() => {
            this.loadingFoto = false;
          });
      }
    },
    validadores() {
      var regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
      if (this.include.nome === null && this.include.nome === "") {
        this.$root.showError("Nome e obrigatorio");
        return false;
      }
      if (!regex.test(this.include.senha)) {
        this.$root.showError(
          "Senha deve conter 8 digitos sendo numeros e letras"
        );
        return false;
      }
      // validar se existe agencia em caso de agencia
      if (this.include.role === "AGENCIA") {
        if (!this.include.agenciaId || !this.include.agenciaNome) {
          this.$root.showError("Selecione uma agência");
          return false;
        }
      }
      return true;
    },
    cadastrar() {
      if (!this.validadores()) {
        return false;
      }
      this.loading = true;
      let payload = this.include;
      controllerUser
        .setNovoUsuarioAdministrativo(payload)
        .then(resp => {
          if (resp.status == 200) {
            this.$root.showSucesso("Cadastrado com sucesso!");
            this.$emit("update");
            this.$emit("close");
          }
        })
        .finally(() => {
          this.loading = false;
        })
        .catch(err => {
          this.$root.showError(err.data);
          this.$emit("close");
        });
    }
  }
};
</script>

<style></style>
