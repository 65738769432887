<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    transition="dialog-transition"
  >
    <v-card class="pa-4">
      <div class="pb-2">
        <v-row justify="center" align="center">
          <v-col cols="10" class="primary--text font-weight-bold">
            Editando item
          </v-col>
          <v-col cols="2">
            <v-btn text @click="$emit('close')">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="px-4">
        <div class="py-2">
          <v-text-field
            v-model="itemPlano.nome"
            label="Nome do item"
            outlined
            hide-details
          ></v-text-field>
        </div>
        <div class="py-2">
          <v-text-field
            v-model="itemPlano.sigla"
            label="Sigla do item"
            outlined
            hide-details
          ></v-text-field>
        </div>
        <div class="py-2">
          <v-text-field
            v-model="itemPlano.qtdItem"
            type="number"
            label="Quantidade padrão do item"
            outlined
            hide-details
          ></v-text-field>
        </div>
        <div class="py-2">
          <v-textarea
            v-model="itemPlano.descricao"
            label="Descrição do item"
            outlined
          >
          </v-textarea>
        </div>
        <div class="py-2">
          <div class="pa-2 pb-3 text-center">
            Valor padrão do item
          </div>
          <div class="text-center">
            <div class="d-inline-block px-3 text-h6 font-weight-bold">
              R$
            </div>
            <div class="d-inline-block">
              <money
                v-model="itemPlano.valor"
                v-money="$root.money"
                class="borderSafe grey-300 pa-2 "
              ></money>
            </div>
          </div>
        </div>
        <v-checkbox
          v-model="itemPlano.ativo"
          label="Item para uso"
          color="primary"
        ></v-checkbox>

        <v-checkbox
          v-model="itemPlano.calcularPorFormando"
          label="Calculado por participante"
          on-icon="mdi-check-all"
          color="green"
        ></v-checkbox>

        <div
          v-if="itemPlano.calcularPorFormando"
          class="pa-3 mb-4 font-weight-normal text-body-1 grey lighten-4"
        >
          <div class="grey--text text--darken-1">
            Quando é calculado por participante, a calculadora no plano
            detalhado usará a seguinte formula:
          </div>
          <div class="black--text mt-2">
            (Participante * Quantidade Item * Valor Item)
          </div>
        </div>
        <v-row justify="center" align="center" class="text-center">
          <v-col cols="6">
            <v-btn color="red" text @click="$emit('close')">FECHAR</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              :loading="loading"
              color="primary"
              text
              @click="updateDados()"
            >
              SALVAR ALTERAÇÃO
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { Money } from "v-money";
import servicoController from "@/services/servicoController";

export default {
  components: {
    Money
  },
  props: ["item"],
  data() {
    return {
      dialog: true,
      loading: false,
      itemPlano: {
        calcularPorFormando: false,
        id: null,
        imgUrl: null,
        nome: "",
        qtdItem: 1,
        sigla: "",
        valor: 50.0,
        ativo: true,
        descricao: null
      }
    };
  },
  mounted() {
    this.itemPlano = { ...this.item };
  },
  methods: {
    updateDados() {
      var item = this.itemPlano;
      item.qtdItem = Number(item.qtdItem);
      if (!item.nome || item.nome.length < 3) {
        this.$root.showError("Nome deve conter pelo menos 3 letras");
        return false;
      }
      if (item.sigla < 3) {
        this.$root.showError("Sigla deve conter pelo menos 3 letras");
        return false;
      }
      if (item.qtdItem < 1) {
        this.$root.showError("Quantidade do item não pode ser menor que 1");
        return false;
      }
      if (item.valor < 0.01) {
        this.$root.showError("O valor do item não pode ser menor que R$ 0.01");
        return false;
      }
      servicoController
        .setItemPlano(item)
        .then(() => {
          this.$emit("update");
          this.$emit("close");
          this.$root.showInfo("Atualizado com sucesso");
        })
        .catch(err => {
          this.$root.showError(err.data);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style></style>
