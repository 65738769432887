import axios from "axios";
export default {
  getPagination(page, pageSize, filtros) {
    return axios.post("/v1/central-atendimento/pagination", {
      descending: true,
      filtros: {
        descricao: null,
        id: null,
        setor: filtros.setor,
        status: filtros.status,
        titulo: filtros.titulo
      },
      pageNo: page,
      pageSize: pageSize,
      sortBy: "string"
    });
  },
  getAtendimento(id) {
    return axios.get(`/v1/central-atendimento/atendimento/${id}`);
  },
  cancelarAtendimento(id) {
    return axios.post(`/v1/central-atendimento/cancelar-atendimento/${id}`);
  },
  concluirAtendimento(id) {
    return axios.post(`/v1/central-atendimento/concluir-atendimento/${id}`);
  },
  novaMensagem(mensagem) {
    return axios.post("/v1/central-atendimento/nova-mensagem", {
      atendimentoId: mensagem.atendimentoId,
      comentario: mensagem.comentario
    });
  }
};
