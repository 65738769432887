import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        accent: "#8c9eff",
        error: "#b71c1c",
        primary: "#37447E",
        secondary: "#7275FF",
        third: "#C2CFE0",
        textColor: "#5E5873"
      }
    }
  }
});
