<template>
  <div>
    <div v-if="mounted === null">
      <vTitulo>
        Relatórios
      </vTitulo>
      <div class="customFlex">
        <CardItemMenu
          v-for="item in listaMenu"
          :key="item.name"
          :item="item"
          @selectItem="mountRoute"
        />
      </div>
    </div>
    <div v-else>
      <ExtratoPorEvento
        v-if="mounted === 'ExtratoPorEvento'"
        @close="closeRoute"
      />
      <RelatorioContabilidade
        v-if="mounted === 'RelatorioContabilidade'"
        @close="closeRoute"
      />
      <RelatorioExtratoFCZporAluno
        v-if="mounted === 'RelatorioExtratoFCZporAluno'"
        @close="closeRoute"
      />
      <RelatorioContabilidadeFCZPorTurma
        v-if="mounted === 'RelatorioContabilidadeFCZPorTurma'"
        @close="closeRoute"
      />
    </div>
  </div>
</template>

<script>
import ExtratoPorEvento from "./ExtratoPorEvento.vue";
import RelatorioContabilidade from "./RelatorioContabilidade.vue";
import RelatorioExtratoFCZporAluno from "./RelatorioExtratoFCZporAluno.vue";
import RelatorioContabilidadeFCZPorTurma from "./RelatorioContabilidadeFCZPorTurma.vue";
import CardItemMenu from "./CardItemMenu.vue";

export default {
  name: "MenuRelatorios",
  components: {
    ExtratoPorEvento,
    RelatorioContabilidade,
    RelatorioExtratoFCZporAluno,
    RelatorioContabilidadeFCZPorTurma,
    CardItemMenu
  },
  data: () => {
    return {
      mounted: null,
      listaMenu: [
        {
          name: "Extrato Eventos",
          mount: "ExtratoPorEvento"
        },
        {
          name: "Contabilidade",
          mount: "RelatorioContabilidade"
        },
        {
          name: "Contabilidade FCZ por Turma",
          mount: "RelatorioContabilidadeFCZPorTurma"
        },
        {
          name: "Extrato FCZ por Aluno",
          mount: "RelatorioExtratoFCZporAluno"
        }
      ]
    };
  },
  methods: {
    mountRoute(name) {
      this.mounted = name;
    },
    closeRoute() {
      this.mounted = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.customFlex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>
