<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
      transition="dialog-transition"
    >
      <v-card class="pa-3">
        <div class="pa-2">
          <div class="d-grid grid-col-1-auto pb-2">
            <div class="text-left text-h6">
              Novo pacote
            </div>
            <div class="text-center">
              <v-btn text @click="$emit('close')">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
          <div class="pa-2">
            <div>
              <div>
                <v-text-field v-model="include.titulo" label="Nome do pacote" />
              </div>
              <div>
                <v-text-field v-model="include.descricao" label="Descrição" />
              </div>
              <div>
                <v-text-field
                  v-model.lazy="includeValue"
                  v-money="$root.money"
                  prefix="R$"
                  label="Valor do Serviço ou Produto"
                />
              </div>
              <div>
                <v-text-field
                  v-model.number="include.diasCarencia"
                  :value="include.diasCarencia"
                  min="0"
                  max="9999"
                  type="number"
                  suffix="Dias"
                  label="Dias de carência"
                  @change="changeDiasCarencia"
                />
              </div>
              <div>
                <v-select
                  v-model="include.tipoCobranca"
                  label="Tipo de cobrança"
                  :items="tipoCobranca"
                />
              </div>
            </div>
            <div class="py-3">
              <div class="text-center mb-5 font-weight-bold">
                Selecione a cor do pacote
              </div>
              <v-row justify="center" align="center">
                <v-col cols="12">
                  <v-color-picker
                    v-model="include.color"
                    class="mx-auto"
                    hide-canvas
                  ></v-color-picker>
                </v-col>
              </v-row>
            </div>
            <div class="text-center mt-10">
              <v-btn
                depressed
                color="primary"
                :loading="loading"
                @click="tratarValoresParaInclude()"
                >Adicionar pacote</v-btn
              >
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import servicePacotes from "@/services/pacotes";
export default {
  data() {
    return {
      dialog: true,
      loading: false,
      resposta: "",
      includeValue: null,
      include: {
        titulo: "",
        descricao: "",
        valor: 0,
        tipoCobranca: "MENSAL",
        ativo: true,
        color: "#4527A0FF",
        diasCarencia: 0
      },
      tipoCobranca: [
        { value: "MENSAL", text: "Mensal" },
        { value: "POR_TRANSACAO", text: "Por Transação" }
      ]
    };
  },
  watch: {
    // "include.diasCarencia": function(val) {
    //   console.log(val);
    //   if (val < 1) {
    //     this.include.diasCarencia = 0;
    //   }
    // }
  },
  methods: {
    changeDiasCarencia(value) {
      console.log("change", value);
      if (value < 1) {
        this.$nextTick(() => (this.include.diasCarencia = 0));
      }
    },
    salvarDados(pacote) {
      this.loading = true;
      servicePacotes
        .setPacote(pacote)
        .then(resp => {
          this.$root.showInfo(resp.data);
          this.$emit("update");
          this.$emit("close");
        })
        .finally(() => {
          this.loading = false;
        })
        .catch(err => {
          console.log(err.data);
        });
    },
    verificaValorPositivo(valor) {
      return valor >= 0;
    },
    transformValueCurrencyStringToInt() {
      let val = this.include.valor;
      if (val.indexOf(".") !== -1) {
        val = val.replaceAll(".", "");
      }
      if (val.indexOf(",") !== -1) {
        val = val.replace(",", ".");
      }
      return parseFloat(val);
    },
    transformVal(payload) {
      let pay = payload;
      console.log(pay);
      if (payload.indexOf(".") !== -1) {
        pay = pay.replaceAll(".", "");
        console.log(pay, "before replace .");
      }
      if (payload.indexOf(",") !== -1) {
        pay = pay.replace(",", ".");
        console.log(pay, "before replace , to .");
      }
      pay = Number(pay);
      console.log(pay, "before Number");
      //console.log(pay);
      return pay;
    },
    tratarValoresParaInclude() {
      //let self = this;
      var pacote = {
        ...this.include
      };
      if (pacote.titulo.length < 3) {
        this.$root.showError("O titulo deve conter pelo menos 3 letras");
        return false;
      }
      if (pacote.descricao.length < 3) {
        this.$root.showError("A descrição deve conter pelo menos 3 letras");
        return false;
      }

      pacote.diasCarencia = Number(pacote.diasCarencia).toFixed(0);
      console.log(pacote.diasCarencia);

      if (pacote.diasCarencia < 0) {
        this.$root.showError(
          "Erro, dias de carencia não podem estar negativos"
        );
        return false;
      }

      pacote.valor = this.transformVal(this.includeValue);

      if (!this.verificaValorPositivo(pacote.valor)) {
        this.$root.showError("Erro, valores não podem estar negativos");
        return false;
      } else {
        this.$nextTick(() => {
          console.log(pacote);
        });
        this.salvarDados(pacote);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.inputCustom {
  background-color: rgba(100, 100, 100, 0.1);
  padding: 8px 12px;
  max-width: 90%;
}
</style>
