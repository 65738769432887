<template>
  <div class="mx-auto">
    <v-progress-circular
      v-if="loading"
      indeterminate
      color="primary"
    ></v-progress-circular>
    <v-avatar v-else :size="large ? '100px' : '35px'">
      <v-img v-if="getAvatarURL" :src="getAvatarURL"></v-img>
      <!-- <v-img src="@/assets/sem-foto-perfil.png"></v-img> -->
      <v-icon v-else :size="large ? '100px' : '35px'" color="rgb(50,50,50)"
        >mdi-account-circle-outline</v-icon
      >
    </v-avatar>
  </div>
</template>

<script>
export default {
  props: {
    avatarUrl: {
      type: String,
      required: false,
      default: ""
    },
    large: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getAvatarURL() {
      return this.avatarUrl;
    }
  }
};
</script>
