<template>
  <div class="card lowShadow">
    <div class="action">
      <v-btn text color="primary" @click="selectItem()">{{ item.name }}</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item"],
  methods: {
    selectItem() {
      this.$emit("selectItem", this.item.mount);
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  padding: 20px;
  background-color: white;
  margin: 10px;
  display: inline-flex;
  flex-direction: column;
  min-width: 200px;
  text-align: center;
  min-height: 120px;
  border-radius: 5px;
  justify-content: center;
}
.titulo {
  font-weight: medium;
  font-size: 16px;
  flex: 1;
  color: rgba(50, 50, 70, 1);
}
.action {
  flex: none;
}
</style>
