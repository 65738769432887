<template>
  <div>
    <vTitulo class="text-center">
      Meu perfil
    </vTitulo>
    <v-card shaped class="pa-4 mx-auto text-center lowShadow" max-width="500">
      <div class="my-2">
        <div v-if="editable">
          <a @click="selectImagem()">
            <vAvatarUser :large="true" :avatar-url="getUser.fotoUrl" />
          </a>
          <div class="font-weight-light text-body-2 py-2">
            Clique na imagem para trocar de foto
          </div>
        </div>

        <vAvatarUser
          v-if="!editable"
          :large="true"
          :avatar-url="getUser.fotoUrl"
        />
        <input
          v-show="false"
          id="file"
          ref="file"
          type="file"
          accept=".png,.jpg,.jpeg,.svg"
          @change="defineImagem()"
        />
      </div>
      <div class="text-h6 px-5">
        <div v-if="!editable">
          {{ getUser.nome }}
        </div>
        <div v-else>
          <v-text-field
            v-model="include.nome"
            :disabled="lockforms"
            label="Nome"
          ></v-text-field>
        </div>
      </div>
      <div class="text-caption grey--text">
        <v-chip small>
          {{ getUser.role }}
        </v-chip>
      </div>
      <div class="text-body-1">
        {{ getUser.email }}
      </div>
      <v-row class="pa-2 mt-4">
        <v-col cols="6">
          <v-btn v-if="!editable" text color="primary" @click="openNovaSenha()">
            Trocar senha <v-icon class="ml-2">mdi-lock-outline</v-icon>
          </v-btn>
          <v-btn
            v-if="editable"
            :disabled="lockforms"
            color="red"
            text
            @click="editable = false"
            >CANCELAR</v-btn
          >
        </v-col>
        <v-col cols="6">
          <v-btn v-if="!editable" text color="primary" @click="editable = true"
            >EDITAR <v-icon class="ml-2">mdi-pencil</v-icon></v-btn
          >
          <v-btn
            v-if="editable"
            :loading="loadingSave"
            :disabled="lockforms"
            text
            color="primary"
            @click="salvarUser()"
            >Salvar</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
    <vRedefinir
      v-if="modalNovaSenha"
      :user="payloadUser"
      @atualizar="updateUser"
      @close="closeNovaSenha"
    />
  </div>
</template>

<script>
import vAvatarUser from "@/components/avatar.vue";
import vRedefinir from "./novaSenha";
import serviceUser from "@/services/userController";

export default {
  components: {
    vAvatarUser,
    vRedefinir
  },
  data() {
    return {
      editable: false,
      lockforms: false,
      loadingSave: false,
      payloadUser: {},
      modalNovaSenha: false,
      include: {
        nome: ""
      }
    };
  },
  computed: {
    getUser() {
      return this.$store.getters["gestao/getUser"];
    }
  },
  mounted() {
    this.makeInclude();
  },
  methods: {
    uploadImagem() {
      let imagem = this.file;
      serviceUser
        .uploadImagem(imagem)
        .then(resp => {
          const payloadUser = {
            email: this.getUser.email,
            fotoUrl: resp.data,
            id: this.getUser.id,
            nome: this.getUser.nome,
            role: this.getUser.role,
            senha: this.getUser.senha
          };
          serviceUser
            .updateUsuarioAdministrativo(payloadUser)
            .then(resp2 => {
              this.$root.showInfo(resp2.data);
              this.$store.dispatch("gestao/REQUEST_USER");
            })
            .catch(err2 => {
              this.$root.showError(err2);
            });
        })
        .catch(err => {
          console.log(err);
        });
    },
    defineImagem() {
      let makefile = document.querySelector("#file").files[0];
      let formData = new FormData();
      formData.append("file", makefile);
      this.file = formData;
      this.uploadImagem();
    },
    selectImagem() {
      document.getElementById("file").click();
    },
    makeInclude() {
      this.include = { ...this.getUser };
    },
    updateUser() {
      this.$store.dispatch("gestao/REQUEST_USER");
      this.makeInclude();
    },
    openNovaSenha() {
      let user = this.$store.getters["gestao/getUser"];
      this.payloadUser = {
        email: user.email,
        fotoUrl: user.fotoUrl,
        id: user.id,
        nome: user.nome,
        role: user.role,
        senha: ""
      };
      this.modalNovaSenha = true;
    },
    closeNovaSenha() {
      this.modalNovaSenha = false;
    },
    salvarUser() {
      this.loadingSave = true;
      this.lockforms = true;
      let user = this.include;
      let userData = {
        email: user.email,
        fotoUrl: user.fotoUrl,
        id: user.id,
        nome: user.nome,
        role: user.role,
        senha: ""
      };
      serviceUser
        .updateUsuarioAdministrativo(userData)
        .then(resp => {
          this.$root.showInfo(resp.data);
          this.$store.dispatch("gestao/REQUEST_USER");
        })
        .catch(err => {
          this.$root.showError(err.data);
        })
        .finally(() => {
          this.loadingSave = false;
          this.editable = false;
          this.lockforms = false;
        });
    },
    closeTrocaSenha() {
      this.modalSenhaNova = false;
    },
    editarTrocaSenha() {
      this.modalSenhaNova = true;
    }
  }
};
</script>
