<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500px"
    transition="dialog-transition"
  >
    <v-card class="pa-3">
      <v-row>
        <v-col cols="8" class="text-left">
          <div class="pa-2 primary--text">
            <span v-if="editar.id">Editando curso</span>
            <span v-else>Novo curso</span>
          </div>
        </v-col>
        <v-col cols="4" class="text-right">
          <v-btn text color="black" @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <div class="pa-2">
        <v-text-field
          v-model="include.nome"
          label="Nome"
          outlined
        ></v-text-field>
      </div>
      <div class="text-center">
        <v-btn text color="primary" @click="acrescentarCurso()">
          <span v-if="editar.id">Salvar edição</span>
          <span v-else>Incluir na lista</span>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import serviceCursos from "@/services/cursos";
export default {
  props: {
    editar: {
      require: false,
      type: Object,
      default: () => {
        return Object;
      }
    }
  },
  data() {
    return {
      dialog: true,
      include: {
        nome: "",
        id: null
      }
    };
  },
  mounted() {
    if (this.editar.id) {
      this.include = { ...this.editar };
    }
  },
  methods: {
    acrescentarCurso() {
      serviceCursos
        .setCurso(this.include)
        .then(() => {
          this.$emit("update");
          this.$root.showInfo("Salvo com sucesso!");
          this.$emit("close");
        })
        .catch(err => {
          this.$root.showError(err.data);
        });
    }
  }
};
</script>
