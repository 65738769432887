<template>
  <div style="height: 100%">
    <v-titulo>Configurações</v-titulo>
    <v-form ref="form" v-model="valid" lazy-validation>
      <div class="ma-4 justify-start">
        <div class="d-flex flex-wrap">
          <v-card class="mx-auto mt-4 pa-4 lowShadow" max-width="344" outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="text-center text-h5 mb-1"
                  >Dias de Carência</v-list-item-title
                >
                <v-text-field
                  v-model="configuracoes.diasCarencia"
                  hint="Período de carência para novas turmas, após será solicitado para o usuário selecionar um pacote."
                  persistent-hint
                  class="cardInput"
                  type="number"
                  :rules="[rules.numeroInteiro]"
                ></v-text-field>
              </v-list-item-content>
            </v-list-item>
          </v-card>
          <v-card class="mx-auto mt-4 pa-4 lowShadow" max-width="344" outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="text-center text-h5 mb-1"
                  >Multa</v-list-item-title
                >
                <v-text-field
                  v-model="configuracoes.peMulta"
                  suffix="%"
                  class="cardInput"
                  :rules="[rules.numero]"
                  type="number"
                  :min="0"
                ></v-text-field>
              </v-list-item-content>
            </v-list-item>
          </v-card>
          <v-card class="mx-auto mt-4 pa-4 lowShadow" max-width="344" outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="text-center text-h5 mb-1"
                  >Juros</v-list-item-title
                >
                <v-text-field
                  v-model="configuracoes.peJuros"
                  suffix="%"
                  class="cardInput"
                  type="number"
                  :rules="[rules.numero]"
                ></v-text-field>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </div>
        <div class="my-3 justify-center align-center d-flex flex-wrap">
          <v-card class="ma-3 pa-4 lowShadow" outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="text-center text-h5 mb-1"
                  >Atendimento Urgente</v-list-item-title
                >
                <div class="my-3 d-flex flex-column flex-wrap">
                  <vue-editor
                    v-model="configuracoes.mensagem"
                    class="cardInput"
                    placeholder="Digite uma mensagem"
                    :rules="[rules.mensagem]"
                    :disabled="!configuracoes.atendimentoUrgente"
                    :data-vv-name="configuracoes.mensagem"
                  ></vue-editor>
                  <v-text-field
                    v-if="text_field_telefone"
                    v-model="telefone"
                    v-mask="'(##) #####-####'"
                    label="Número"
                    prefix="+55"
                    placeholder="(11) 99999-9999"
                    class="cardInput"
                    type="text"
                    :disabled="!configuracoes.atendimentoUrgente"
                  ></v-text-field>
                  <v-switch
                    v-model="configuracoes.atendimentoUrgente"
                    class="mx-3"
                    inset
                    label="Atendimento Urgente"
                  ></v-switch>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </div>
      </div>
      <v-row>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="mr-5 mt-5"
          :disabled="!valid"
          @click="update()"
        >
          Salvar
        </v-btn>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor";

import serviceConfiguracoes from "@/services/configuracoes";
export default {
  components: {
    VueEditor
  },
  data() {
    return {
      configuracoes: {
        telefone: "",
        mensagem: "",
        peJuros: 0,
        peMulta: 0,
        diasCarencia: 0,
        atendimentoUrgente: false
      },
      rules: {
        numero: value => {
          var pattern = /[0-9]/;
          return (pattern.test(value) && value >= 0) || "Insira apenas números";
        },
        numeroInteiro: value => {
          var pattern = /^(0|-?[1-9][0-9]*)$/;
          return (
            (pattern.test(value) && value >= 0) || "Insira um número inteiro"
          );
        },
        numeroTelefone: value => {
          return value.length == 15 || "Insira um telefone valido";
        }
      },
      valid: true,
      telefone: "",
      text_field_telefone: false
    };
  },
  beforeMount() {
    this.requestConfiguracoes();
  },
  methods: {
    requestConfiguracoes() {
      serviceConfiguracoes
        .getConfiguracoes()
        .then(resp => {
          this.configuracoes = resp.data;
          if (resp.data.telefone) {
            this.telefone = resp.data.telefone;
          }
          this.$nextTick(() => (this.text_field_telefone = true));
        })
        .catch(err => {
          this.$root.showError(err.data);
        });
    },
    update() {
      if (!this.formatarTelefone()) {
        this.$root.showError("Telefone invalido");
        return false;
      }
      if (this.configuracoes.mensagem.length == 0) {
        this.$root.showError("Por favor insira uma mensagem");
        return false;
      }
      console.log(this.configuracoes.mensagem.length);
      serviceConfiguracoes
        .update(this.configuracoes)
        .then(resp => {
          this.$root.showSucesso(resp.data);
        })
        .catch(err => {
          this.$root.showError(err.data);
        });
    },
    formatarTelefone() {
      let telefone = this.telefone;
      if (telefone.includes("(")) {
        telefone = telefone.replace("(", "");
      }
      if (telefone.includes(")")) {
        telefone = telefone.replace(")", "");
      }
      if (telefone.includes("-")) {
        telefone = telefone.replace("-", "");
      }
      if (telefone.includes(" ")) {
        telefone = telefone.replaceAll(" ", "");
      }

      if (telefone.length == 11) {
        this.configuracoes.telefone = telefone;
        return true;
      }
      if (telefone.length != 11) {
        return false;
      }
    }
  }
};
</script>
<style>
.cardInput input {
  text-align: center;
  font-size: 25px;
}
.btnCard {
  justify-content: center;
}
</style>
