<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <v-card class="pa-4 lowShadow">
      <div class="text-right d-grid grid-col-1-auto">
        <div class="text-left text-h6 pa-2">
          Arquivar turma
        </div>
      </div>
      <div class="text-left mx-auto inline-block text-body-1 pa-4">
        <div>Tem certeza que deseja arquivar esta turma ?</div>
        <div>Não será possivel desfazer essa ação.</div>
      </div>
      <div class="containActions">
        <div class="flex-1 text-center">
          <v-btn :loading="loading" text color="primary" @click="$emit('close')"
            >Não</v-btn
          >
        </div>
        <div class="flex-1 text-center">
          <v-btn
            :loading="loading"
            text
            dark
            color="red"
            @click="confirmarArquivar()"
            >Sim, arquivar</v-btn
          >
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import serviceTurma from "@/services/turma";

export default {
  name: "ConfirmarArquivarTurma",
  props: {
    turmaId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    dialog: true,
    loading: false
  }),
  methods: {
    closeModal() {
      this.$emit("close");
    },
    confirmarArquivar() {
      this.loading = true;
      serviceTurma
        .arquivarTurma(this.turmaId)
        .then(() => {
          this.$root.showInfo("Turma arquivada!");
          this.$emit("confirm");
        })
        .finally(() => {
          this.loading = false;
        })
        .catch(err => {
          this.$root.showError(err);
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped lang="scss">
.containActions {
  display: flex;
  flex-direction: row;
  justify-items: center;
  margin-top: 10px;
}
</style>
