import axios from "axios";

export default {
  getBilhetesPorVenda(vendaId) {
    return axios.get(`/v1/vendabilhete/bilhetes/${vendaId}`);
  },
  pagination(options, page, pageSize) {
    return axios.post("/v1/vendabilhete/pagination", {
      descending: true,
      direction: "ASC",
      filtros: {
        cpfComprador: options.cpfComprador,
        id: options.id,
        nomeComprador: options.nomeComprador
      },
      pageNo: page,
      pageSize: pageSize,
      sortBy: "string"
    });
  }
};
