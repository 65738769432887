<template>
  <div>
    <v-titulo>
      Agência
    </v-titulo>
    <div class="text-right px-5">
      <v-btn color="primary" depressed @click="cadastrarAgencia()">
        <v-icon>mdi-plus</v-icon> Nova agência
      </v-btn>
    </div>
    <div class="mockDesign">
      <v-data-table
        :headers="headers"
        :items="listaAgencias"
        class="elevation-1"
        item-key="id"
        loading="true"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.id }}</td>
            <td>{{ item.nome }}</td>
            <td>{{ item.cnpj }}</td>
            <td>{{ item.telefone }}</td>
            <td>{{ item.responsavel }}</td>
            <td>
              <div class="containAction">
                <div>
                  <v-btn icon @click="abrirEditar(item)">
                    <v-icon>mdi-pencil-circle</v-icon>
                  </v-btn>
                </div>
              </div>
            </td>
          </tr>
        </template>
        <template v-slot:no-data>
          Sem dados
        </template>
      </v-data-table>
    </div>
    <vModelAgencia
      v-if="modalAgencia"
      :agencia="modalPayload"
      :modo="modalMode"
      @close="closeModal"
      @update="closeAndUpdate"
    />
  </div>
</template>

<script>
import ServiceAgencia from "@/services/agencias";
import vModelAgencia from "./vModalAgencia.vue";

export default {
  components: {
    vModelAgencia: vModelAgencia
  },
  data() {
    return {
      modalPayload: {},
      modalMode: "novo",
      modalAgencia: false,
      listaAgencias: [],
      headers: [
        { text: "Id", value: "id", align: "left", sortable: false },
        { text: "Nome", value: "nome", align: "left", sortable: false },
        { text: "CNPJ", value: "cnpj", align: "left", sortable: false },
        { text: "Telefone", value: "telefone", align: "left", sortable: false },
        {
          text: "Responsável",
          value: "responsavel",
          align: "left",
          sortable: false
        },
        {
          text: "Opções",
          value: "responsavel",
          align: "left",
          sortable: false
        }
      ]
    };
  },
  mounted() {
    this.buscarAgencias();
  },
  methods: {
    closeAndUpdate() {
      this.closeModal();
      this.buscarAgencias();
    },
    closeModal() {
      this.modalAgencia = false;
      this.modalMode = null;
      this.modalPayload = {};
    },
    abrirEditar(item) {
      this.modalMode = "editar";
      this.modalAgencia = true;
      this.modalPayload = item;
    },
    cadastrarAgencia() {
      this.modalMode = "novo";
      this.modalPayload = {};
      this.modalAgencia = true;
    },
    buscarAgencias() {
      ServiceAgencia.getAgencias()
        .then(resp => {
          console.log(resp.data);
          this.listaAgencias = resp.data;
        })
        .catch(err => {
          console.error(err);
        });
    },
    teste() {
      ServiceAgencia.alterarAgencia()
        .then(resp => {
          console.log("resp", resp);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.containAction {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
}
.mockDesign {
  border-radius: 10px;
  margin: 20px;
}
</style>
