<template>
  <div>
    <v-dialog
      v-model="dialog"
      scrollable
      persistent
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card class="pa-4">
        <div>
          <div class="text-h6 primary--text pb-2">
            Redefinir senha
          </div>
          <v-card flat max-width="200" class="mx-auto">
            <div class="pa-1 desc d-grid grid-col-auto-1 centralizaTudo">
              <div class="mx-3">
                <avatar large :avatar-url="include.fotoUrl" />
              </div>
              <div>
                <div
                  class="pa-0 pl-1 text-h6 grey--text text--darken-4 font-weight-bold"
                >
                  {{ include.nome }}
                </div>
                <div
                  class="pa-0 pl-1 text-subtitle-2 font-weight-light grey--text text--darken-1"
                >
                  {{ include.role }}
                </div>
              </div>
            </div>
          </v-card>

          <div class="ma-2 mb-4">
            <form autocomplete="off">
              <v-text-field
                v-model="include.senha"
                :type="typeField"
                solo
                label="Nova senha"
                class="justify-center align-center"
                hide-details
                autocomplete="new"
              >
                <template v-slot:append>
                  <v-btn
                    v-if="typeField == 'password'"
                    text
                    @click="typeField = 'text'"
                  >
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="typeField == 'text'"
                    text
                    @click="typeField = 'password'"
                  >
                    <v-icon>mdi-eye-off</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </form>
          </div>
          <v-row class="text-center">
            <v-col>
              <v-btn text color="red" @click="close()">FECHAR</v-btn>
            </v-col>
            <v-col>
              <v-btn
                text
                :loading="loading"
                color="primary"
                @click="alterarSenha()"
                >SALVAR</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import avatar from "@/components/avatar";
import userService from "@/services/userController";
export default {
  components: { avatar },
  props: {
    user: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      dialog: true,
      loading: false,
      typeField: "password",
      include: {
        email: "",
        fotoUrl: "",
        id: 0,
        nome: "",
        role: "",
        senha: ""
      }
    };
  },
  mounted() {
    this.include = { ...this.user };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    alterarSenha() {
      var regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
      if (!regex.test(this.include.senha)) {
        this.$root.showError(
          "Senha deve conter 8 digitos sendo numeros e letras"
        );
        return false;
      }
      this.loading = true;
      userService
        .updatePasswordUser(this.include)
        .then(resp => {
          this.$root.showSucesso(resp.data);
          this.$emit("atualizaLista");
          this.loading = false;
          this.$emit("close");
        })
        .catch(err => {
          this.$root.showError(err.data);
          this.loading = false;
        });
    }
  }
};
</script>

<style></style>
