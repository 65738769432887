import axios from "axios";

export default {
  roles() {
    return axios.get("/v1/tipos/roles");
  },
  rolesManager() {
    return axios.get("/v1/tipos/roles-manager");
  },
  tiposCalculo() {
    return axios.get("/v1/tipos/tipo-calculo");
  },
  tipsPacotes() {
    return axios.get("/v1/tipos/itens-pacote");
  }
};
