<template>
  <!-- pa-4 pa-sm-8 -->
  <div class="containConvite">
    <!-- primaryDash--text text-h6 pa-2 -->
    <div class="conviteHead text-slate-800">
      Você pode convidar novos integrantes através do link abaixo
    </div>
    <div class="text-center">
      <div class="mb-5">
        <v-text-field
          id="codigoTurmaCopy"
          readonly
          :value="getLink"
          color="grey"
          hide-details
          class="inputCodigo"
        ></v-text-field>
      </div>
      <div>
        <v-btn
          class="my-3 btnTextColorCustom"
          color="rgba(114, 117, 255, 0.15)"
          depressed
          @click="copiarLink()"
          >Copiar link</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import { URL_APP } from "@/constantes/base";
export default {
  props: {
    convite: {
      type: String,
      required: true
    }
  },
  computed: {
    getLink() {
      return `${URL_APP}/site/cadastro/${this.convite}`;
    }
  },
  methods: {
    copiarLink() {
      let copyTextarea = document.querySelector("#codigoTurmaCopy");
      copyTextarea.focus();
      copyTextarea.select();
      try {
        var successful = document.execCommand("copy");
        if (successful) {
          this.$root.showInfo("Copiado com sucesso!");
        } else {
          this.$root.showError("Não foi possível copiar");
        }
      } catch (err) {
        this.$root.showError("Não foi possível copiar");
        console.log(err);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.inputCodigo {
  font-size: 13px;
  font-weight: 300;
  max-width: 80%;
  margin: 0 auto;
}
.containConvite {
  padding: 20px;
  padding-bottom: 15px;
}
.conviteHead {
  margin-top: 10px;
  margin-bottom: 8px;
  padding: 0px 16px;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
}
.btnTextColorCustom {
  color: #7275ff !important;
}
</style>
