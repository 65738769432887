<template>
  <div>
    <vReturnToolbar @close="close()" />
    <vTitulo>
      Contabilidade
    </vTitulo>
    <vFiltroPesquisa
      relatorio="CONTABILIDADE"
      :loading="loading"
      @request="RequestContabilidade"
    />
  </div>
</template>

<script>
import serviceRelatorio from "@/services/relatorios";
import filtroPesquisa from "./FiltroPesquisa.vue";

export default {
  components: {
    vFiltroPesquisa: filtroPesquisa
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    RequestContabilidade(payload) {
      this.loading = true;
      delete payload.situacao;
      serviceRelatorio
        .relatorioDeContabilidade(payload)
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            response.headers["content-disposition"].split(";")[1].split("=")[1]
          );
          document.body.appendChild(link);
          link.click();
          this.$root.showInfo("Relatório gerado com sucesso!");
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          this.$root.showError(err.data);
          console.error(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.containID {
  font-size: 15px;
  font-weight: 400;
  padding: 5px 10px;
  background-color: rgb(200, 200, 200, 0.5);
  display: inline-block;
  border-radius: 6px;
}
.radioCustom {
  //teste
  cursor: pointer;
  padding: 6px 12px;
  border: 1px solid rgba(150, 150, 150, 0.3);
  border-radius: 5px;
  margin: 0 2px;
  &.ativo {
    border: 1px solid rgb(10, 85, 170);
  }
}
</style>
