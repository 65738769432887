export default {
  data() {
    return {
      modalConfirmarArquivar: false
    };
  },
  methods: {
    abrirModalArquivarTurma() {
      this.modalConfirmarArquivar = true;
    },
    closeModalArquivarTurma() {
      this.modalConfirmarArquivar = false;
    },
    concluirProcessoArquivarTurma() {
      this.modalConfirmarArquivar = false;
      this.$emit("close");
    }
  }
};
