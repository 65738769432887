<template>
  <div>
    <v-titulo>
      Arquivo Retorno Banco
    </v-titulo>

    <v-card class="pa-5 ma-3 lowShadow text-center">
      <div v-if="ultimo.codigo" class="infoUltimo mb-3 ">
        Último retorno de PAGAMENTOS número
        <span class="font-weight-bold">{{ ultimo.codigo }}</span> gerado em
        {{ ultimo.dataCadastro | momentFilter }}, baixar ultima remessa:
        <v-icon @click="openArquivo()">mdi-download-circle</v-icon>
      </div>
      <div class="uiInput pa-3 mx-auto" style="max-width: 500px;">
        <div class="font-weight-bold text-subtitle-1">
          Selecione o arquivo remessa para ser enviado
        </div>
        <div class="py-4 px-5">
          <!--accept="image/*"-->
          <v-file-input
            id="file"
            label="Clique para selecionar o arquivo retorno"
            color="primary"
          ></v-file-input>
        </div>
        <div class="d-block text-center">
          <v-btn
            :loading="loading"
            color="primary"
            class="px-5"
            @click="enviarArquivo()"
            >Enviar</v-btn
          >
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import serviceBank from "@/services/banco";
import moment from "moment";
export default {
  filters: {
    momentFilter(val) {
      return moment(val).format("DD/MM/YYYY HH:mm");
    }
  },
  data() {
    return {
      ultimo: {},
      loading: false
    };
  },
  mounted() {
    this.requestUltimoRetorno();
  },
  methods: {
    openArquivo() {
      window.open(`${this.ultimo.url}`, `openBoleto${this.ultimo.codigo}`);
    },
    requestUltimoRetorno() {
      serviceBank.getUltimoRetorno().then(resp => {
        //console.log(resp);
        this.ultimo = resp.data;
      });
    },
    enviarArquivo() {
      let makefile = document.querySelector("#file").files[0];
      if (!makefile) {
        this.$root.showError("Você deve selecionar o arquivo para envio antes");
        return false;
      }
      this.loading = true;
      let formData = new FormData();
      formData.append("file", makefile);

      serviceBank
        .uploadNovaRemessa(formData)
        .then(resp => {
          this.$root.showInfo(resp.data);
        })
        .catch(err => {
          this.$root.showError(err.data);
        })
        .finally(() => {
          this.requestUltimoRetorno();
          this.loading = false;
        });
    }
  }
};
</script>

<style></style>
