<template>
  <div class="pa-4">
    <div>
      <v-btn small text @click="voltar()">
        <v-icon>mdi-arrow-left</v-icon>Voltar para lista</v-btn
      >
    </div>
    <div class="containHeader">
      <vTitulo>
        Participantes do Evento
      </vTitulo>
      <div class="pl-5 pb-4" style="margin-top: -10px">
        <div class="black--text">
          <code>EVT-{{ turma.id }} | {{ turma.nome }}</code>
        </div>
        <div class="black--text"></div>
      </div>
    </div>
    <div class="structMenu lowShadow">
      <div
        class="font-weight-medium mb-2 ml-2 pb-2"
        style="border-bottom: 1px solid rgba(50,50,50,0.1);"
      >
        Filtros
      </div>
      <div class="menuFilters">
        <div>
          <v-text-field
            v-model="search"
            label="Pesquisar por nome, email, e etc..."
            outlined
            prepend-inner-icon="mdi-magnify"
            dense
            hide-details
          ></v-text-field>
        </div>
      </div>
    </div>
    <div>
      <v-data-table
        :headers="headers"
        :items="getListaParticipantes"
        :items-per-page="10000"
        :loading="loading"
        :hide-default-footer="true"
        class="lowShadow"
        loading-text="Carregando..."
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.name">
              <td style="min-width: 80px;">USR-{{ item.usuarioId }}</td>
              <td>{{ item.nome + " " }} {{ item.sobreNome }}</td>
              <td>{{ item.email }}</td>
              <td>{{ item.permissaoTurma }}</td>
              <td>{{ item.situacaoCadastro }}</td>
              <td class="containMenuItem">
                <v-btn icon @click="abrirDetalhesUser(item)">
                  <v-avatar class="clique" size="45">
                    <v-icon size="35" small color="blue" title="Detalhes"
                      >mdi-account-details</v-icon
                    >
                  </v-avatar>
                </v-btn>

                <vMenuDots
                  :participante="item"
                  @abrirContribuicoes="abrirContribuicoes(item)"
                  @update-users="atualizaParticipantesDaLista()"
                />
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>
    <vDetalhesParticipante
      v-if="modalDetalhes"
      :user="itemSelected"
      @close="closeDetalhesUser()"
    />
  </div>
</template>

<script>
import serviceTurma from "@/services/turma";
import MenuDots from "./MenuDots.vue";
import vDetalhesParticipante from "@/components/DetalhesDoParticipante.vue";

export default {
  components: {
    vMenuDots: MenuDots,
    vDetalhesParticipante: vDetalhesParticipante
  },
  data() {
    return {
      itemSelected: null,
      modalDetalhes: false,
      loading: false,
      turmaId: null,
      turma: null,
      participantes: [],
      search: "",
      searchPermissaoTurma: "",
      headers: [
        {
          text: "ID",
          align: "left",
          sortable: false
        },
        {
          text: "Nome",
          align: "left",
          sortable: false
        },
        {
          text: "Email",
          align: "left",
          sortable: false
        },
        {
          text: "Permissão",
          align: "left",
          sortable: false
        },
        {
          text: "Situação",
          align: "left",
          sortable: false
        },
        {
          text: "Opções",
          align: "right",
          sortable: false
        }
      ],
      niveisDePermissao: [
        { text: "Todos", code: null },
        { text: "Administrador", code: "ADMIN" },
        { text: "Comissão", code: "COMISSAO" },
        { text: "Participante", code: "PARTICIPANTE" }
      ],
      statusDeCadastro: [
        { text: "Todos", code: null },
        { text: "Incompleto", code: "INCOMPLETO" },
        { text: "Completo", code: "COMPLETO" }
      ]
    };
  },
  computed: {
    getListaParticipantes() {
      return this.participantes.filter(item => {
        if (!this.search || this.search == "") return true;
        let userName = item.nome.toUpperCase();
        let userSobreNome = item.sobreNome.toUpperCase();
        let userNomeFormando = item.nomeFormando.toUpperCase();
        let userEmail = item.email.toUpperCase();
        let userId = String(item.usuarioId);
        let search = this.search.toUpperCase();

        return (
          userName.match(search) ||
          userSobreNome.match(search) ||
          userNomeFormando.match(search) ||
          userId.match(search) ||
          userEmail.match(search)
        );
      });
    }
  },
  beforeMount() {
    const turmaId = this.$route.params.id;
    if (!turmaId) {
      this.$router.replace("/dashboard/turmas/lista");
    } else {
      this.turmaId = turmaId;
      this.getTurmaData();
      this.getUsersTurma();
    }
  },
  methods: {
    atualizaParticipantesDaLista() {
      this.loading = true;
      this.getUsersTurma();
    },
    abrirContribuicoes() {
      console.log("abrir contribuicoes função nao implementada");
    },
    voltar() {
      this.$router.replace("/dashboard/turmas/lista");
    },
    abrirDetalhesUser(item) {
      this.itemSelected = item;
      this.modalDetalhes = true;
    },
    closeDetalhesUser() {
      this.modalDetalhes = false;
    },
    getTurmaData() {
      this.turma = this.$store.getters["gestao/getDataTemp"];
      if (!this.turma) {
        console.error("Turma não está carregada");
        this.$router.replace("/dashboard/turmas/lista");
      }
    },
    getUsersTurma() {
      this.loading = true;
      serviceTurma
        .getParticipantesByEvent(this.turmaId)
        .then(resp => {
          console.log(resp);
          this.participantes = resp.data;
        })
        .finally(() => {
          this.loading = false;
        })
        .catch(err => {
          console.error(err);
        });
    }
  }
};
</script>

<style lang="css" scoped>
.containMenuItem {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
}
.structMenu {
  background-color: white;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
}
.menuFilters {
  display: flex;
  flex-direction: row;
}
</style>
