import axios from "axios";

export default {
  relatorioDeContabilidadeFCZ(tipoRel) {
    return axios.post("/v1/relatorio-fcz/contabilidade-turma", {
      tipoRel: tipoRel
    },
    {
      responseType: "blob"
    });
  },
  relatorioDeContabilidade(payload) {
    return axios.post(
      "/v1/relatorio-turma/contabilidade",
      {
        dataFinal: payload.dataFinal,
        dataInicial: payload.dataInicial,
        situacao: payload.situacao,
        tipoRel: payload.tipoRel,
        turmaId: payload.turmaId
      },
      {
        responseType: "blob"
      }
    );
  },
  extratoMovimentacao(filtros) {
    return axios.post(
      "/v1/relatorio-turma/extrato-movimentacao",
      {
        turmaId: filtros.turma ? filtros.turma : null,
        tipoRel: filtros.tipoRel ? filtros.tipoRel : null,
        dataFinal: filtros.dataFinal ? filtros.dataFinal : null,
        dataInicial: filtros.dataInicial ? filtros.dataInicial : null,
        situacao: filtros.situacao ? filtros.situacao : null
      },
      {
        responseType: "blob"
      }
    );
  },
  extratoFCZporAluno(tipoRel, concursoId) {
    return axios.post(
      "/v1/relatorio-fcz/contabilidade",
      {
        tipoRel: tipoRel,
        concursoId: concursoId
      },
      {
        responseType: "blob"
      }
    );
  }
};
