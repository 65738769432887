export default {
  path: "/dashboard",
  name: "dashboard",
  component: () => import("@/views/dashboard/index.vue"),
  redirect: "/dashboard/home",
  children: [
    {
      path: "contas-a-receber",
      component: () =>
        import("@/views/dashboard/contasAReceber/RouterContasAReceber.vue")
    },
    {
      path: "premios",
      component: () => import("@/views/dashboard/premios/vRouterPremios.vue")
    },
    {
      path: "agencia",
      component: () => import("@/views/dashboard/agencias/vRouterAgencias.vue")
    },
    {
      path: "relatorios",
      component: () => import("@/views/dashboard/relatorios/MenuRelatorios.vue")
    },
    {
      path: "home",
      component: () => import("@/views/dashboard/home/index.vue")
    },
    {
      path: "bilhetes-fcz",
      component: () =>
        import("@/views/dashboard/bilhetesFCZ/RouterBilhetes.vue")
    },
    {
      path: "estatistica-fcz",
      component: () =>
        import("@/views/dashboard/estatisticaFCZ/RouterEstatisticaFCZ.vue")
    },
    {
      path: "config-notificacoes",
      component: () =>
        import(
          "@/views/dashboard/config-notificacoes/RouteConfigNotificacoes.vue"
        )
    },
    {
      path: "arquivo-retorno",
      component: () => import("@/views/dashboard/arquivo-retorno/index.vue")
    },
    {
      path: "boletos",
      component: () => import("@/views/dashboard/boletos/index.vue")
    },
    {
      path: "configuracoes",
      component: () => import("@/views/dashboard/configuracoes/index")
    },
    {
      path: "pagamentos",
      component: () => import("@/views/dashboard/pagamentos/index")
    },
    {
      path: "pacotes",
      component: () => import("@/views/dashboard/pacotes/index")
    },
    {
      path: "perfil",
      component: () => import("@/views/dashboard/perfil/index.vue")
    },
    {
      path: "usuarios",
      component: () => import("@/views/dashboard/usuarios/index.vue")
    },
    {
      path: "participantes",
      component: () => import("@/views/dashboard/participantes/index")
    },
    {
      path: "turmas",
      component: () => import("@/views/dashboard/turmas/RouterBaseTurmas.vue"),
      children: [
        {
          path: "detalhes/:id",
          component: () =>
            import(
              "@/views/dashboard/turmas/eventoDetalhado/EventoDetalhado.vue"
            )
        },
        {
          path: "lista",
          component: () => import("@/views/dashboard/turmas/ListaTurmas.vue")
        },
        {
          path: "participantes/:id",
          component: () =>
            import(
              "@/views/dashboard/turmas/participantesDaTurma/ListarParticipantes.vue"
            )
        }
      ]
    },
    {
      path: "atendimento",
      component: () => import("@/views/dashboard/atendimento/index")
    },
    {
      path: "itens-plano",
      component: () => import("@/views/dashboard/itens-plano/index")
    },
    {
      path: "cursos",
      component: () => import("@/views/dashboard/cursos/index")
    },
    {
      path: "instituicoes",
      component: () => import("@/views/dashboard/instituicoes/index")
    }
  ]
};
