<template>
  <div class="containerScreen">
    <div class="boxCard">
      <div class="mainHeader">
        <div class="headerDash">
          <div
            class="text-subtitle-2 primaryDash--text font-weight-bold text-left d-inline-block ml-3"
          >
            Visão Geral
          </div>
          <div class="textColorDash--text d-inline-block ml-5">
            <span class="text-body-2">
              Participantes ativos:
            </span>
            <span class="text-body-2 font-weight-bold">
              {{ visaoGeral.alunos }}
            </span>
          </div>
        </div>
        <div class="pa-2">
          <v-btn
            :color="agenciaContas.disableNotification ? '' : 'secondary'"
            text
            title="Habilita/Desabilita o envio de notificação para os integrantes da turma"
            small
            @click="toggleNotification()"
          >
            <div
              v-if="agenciaContas.disableNotification"
              style="justify-items: center; align-items: center; display: flex; flex-direction: row;"
            >
              <v-icon size="15"> mdi-bell-off </v-icon>
              <span class="ml-1" style="font-size: 12px"
                >Desativar notificação</span
              >
            </div>
            <div
              v-else
              style="justify-items: center; align-items: center; display: flex; flex-direction: row;"
            >
              <v-icon size="15">mdi-bell</v-icon>
              <span class="ml-1" style="font-size: 12px"
                >Ativar Notificação</span
              >
            </div>
          </v-btn>
          <v-btn
            color="secondary"
            text
            small
            title="Configurações"
            @click="openConfigModal()"
          >
            <div
              style="justify-items: center; align-items: center; display: flex; flex-direction: row;"
            >
              <v-icon size="15">mdi-cog-outline</v-icon>
              <span class="ml-1" style="font-size: 12px;">
                Configurações
              </span>
            </div>
          </v-btn>
          <v-btn
            color="secondary"
            text
            small
            title="Acessar turma/evento"
            @click="acessoAdmin()"
          >
            <div
              style="justify-items: center; align-items: center; display: flex; flex-direction: row;"
            >
              <v-icon size="15">mdi-school-outline</v-icon>
              <span class="ml-1" style="font-size: 12px;">
                Acessar
              </span>
            </div>
          </v-btn>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="headerBox">
        <div class="headerSaqueAndValue">
          <v-card class="visaoGeral elevation-0">
            <div class="pa-5">
              <v-row justify="center">
                <v-col cols="12" sm="6" md="6" lg="6">
                  <div class="d-grid grid-col-auto-1">
                    <div class="text-center">
                      <v-avatar color="rgba(115, 103, 240, 0.12)">
                        <v-icon color="#7367F0">mdi-trending-up</v-icon>
                      </v-avatar>
                    </div>
                    <div class="textColorDash--text  pl-2">
                      <div class="text-body-2 font-weight-bold">
                        {{ visaoGeral.arrecadado | currency }}
                      </div>
                      <div class="text-body-2">
                        Total contribuições
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <div class="d-grid grid-col-auto-1">
                    <div class="text-center">
                      <v-avatar color="rgba(115, 103, 240, 0.12)">
                        <v-icon color="#7367F0">mdi-piggy-bank-outline</v-icon>
                      </v-avatar>
                    </div>
                    <div class="textColorDash--text  pl-2">
                      <div class="text-body-2 font-weight-bold">
                        {{ visaoGeral.totalDeposito | currency }}
                      </div>
                      <div class="text-body-2">
                        Total depositado
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <div class="d-grid grid-col-auto-1">
                    <div class="text-center">
                      <v-avatar color="rgba(234, 84, 85, 0.12)">
                        <v-icon color="#EA5455">mdi-trending-down</v-icon>
                      </v-avatar>
                    </div>
                    <div class="textColorDash--text  pl-2">
                      <div class="text-body-2 font-weight-bold">
                        {{ visaoGeral.totalGasto | currency }}
                      </div>
                      <div class="text-body-2">
                        Total gasto
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <div class="d-grid grid-col-auto-1">
                    <div class="text-center">
                      <v-avatar color="rgba(40, 199, 111, 0.12)">
                        <v-icon color="#28C76F">mdi-currency-usd</v-icon>
                      </v-avatar>
                    </div>
                    <div class="textColorDash--text pl-2">
                      <div class="text-body-2 font-weight-bold">
                        {{ visaoGeral.saldoAtual | currency }}
                      </div>
                      <div class="text-body-2">
                        Saldo atual
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </div>
        <div id="headerMenu">
          <div class="headerMenuButtons"></div>
          <div v-if="agenciaContas.codigoConvite">
            <ConviteTurmaVue :convite="agenciaContas.codigoConvite" />
          </div>
        </div>
      </div>
      <div id="filtros" class="pa-2 px-5 ">
        <v-row>
          <v-col cols="12" sm="6" md="3">
            <v-text-field
              v-model="filtros.dataVencimentoInicio"
              dense
              type="date"
              label="Data vencimento inicial"
              clearable
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-text-field
              v-model="filtros.dataVencimentoFim"
              dense
              type="date"
              label="Data vencimento final"
              clearable
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-select
              v-model="filtros.status"
              dense
              :items="listaStatusPagamento"
              label="Status"
              item-value="value"
              item-text="text"
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-select
              v-model="filtros.tipoDespesa"
              dense
              :items="listaTipoDespesa"
              label="Tipo de Despesa"
              item-value="value"
              item-text="text"
              :disabled="disableTipoDespesa"
              hide-details
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="text-right">
              <v-btn color="primary" depressed @click="getTabSearch">
                <v-icon>mdi-magnify</v-icon>
                Pesquisar
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
      <div id="tabs">
        <v-tabs v-model="tabSelect" @change="changeTab">
          <v-tab key="recebimentos" tab-value="recebimentos"
            >Recebimentos</v-tab
          >
          <v-tab key="despesas" tab-value="despesas">Despesas</v-tab>
          <v-tab key="depositos" tab-value="depositos">Depositos</v-tab>
        </v-tabs>
        <v-divider></v-divider>
      </div>
      <div id="datatable">
        <div v-if="tabSelect == 'recebimentos'" id="recebimentos">
          <v-data-table
            :headers="headersRecebimentos"
            :items="listaRecebimentos"
            hide-default-footer
            :loading="loadingTab"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ formatDate(item.dataVencimento) }}</td>
                <td>{{ item.descricao }}</td>
                <td>{{ item.valor | currency }}</td>
                <td>{{ item.usuarioNome }}</td>
                <td>{{ item.usuarioCPF }}</td>
                <td>{{ item.usuarioId }}</td>
                <td>{{ renderNameStatus(item.status) }}</td>
                <td>
                  <!-- <v-btn icon @click="acaoEditar(item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon @click="acaoExcluir(item)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn> -->
                </td>
              </tr>
            </template>
          </v-data-table>
          <div class="text-center py-4">
            <v-pagination
              :value="page > 0 ? page + 1 : 1"
              :length="totalPages"
              :total-visible="7"
              @input="changePage"
            ></v-pagination>
          </div>
        </div>
        <div v-if="tabSelect == 'despesas'" id="despesas">
          <v-data-table
            :headers="headersDespesas"
            :items="listaDespesas"
            hide-default-footer
            :loading="loadingTab"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ formatDate(item.dataVencimento) }}</td>
                <td>{{ item.valor | currency }}</td>
                <td>{{ item.descricao }}</td>
                <td>{{ renderTipoDespesa(item.tipoDespesa) }}</td>
                <td>{{ item.tipoPagamento }}</td>
                <td>{{ renderNameStatus(item.status) }}</td>
                <td>
                  <!-- <v-btn icon @click="acaoEditar(item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon @click="acaoExcluir(item)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn> -->
                </td>
              </tr>
            </template>
          </v-data-table>
          <div class="text-center py-4">
            <v-pagination
              :value="page > 0 ? page + 1 : 1"
              :length="totalPages"
              :total-visible="7"
              @input="changePage"
            ></v-pagination>
          </div>
        </div>
        <div v-if="tabSelect == 'depositos'" id="depositos">
          <!-- listaDepositos -->
          <v-data-table
            :headers="headersDepositos"
            :items="listaDepositos"
            hide-default-footer
            :loading="loadingTab"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ formatDate(item.dataVencimento) }}</td>
                <td>{{ item.valor | currency }}</td>
                <td>{{ item.descricao }}</td>
                <td>{{ item.tipoPagamento }}</td>
                <td>{{ renderNameStatus(item.status) }}</td>
                <td></td>
              </tr>
            </template>
          </v-data-table>
          <div class="text-center py-4">
            <v-pagination
              :value="page > 0 ? page + 1 : 1"
              :length="totalPages"
              :total-visible="7"
              @input="changePage"
            ></v-pagination>
          </div>
        </div>
      </div>
    </div>
    <ConfiguracaoEvento
      v-if="configuracaoModal"
      :turma="agenciaContas"
      @close="closeConfigModal"
    />
  </div>
</template>

<script>
import agenciasService from "@/services/agencias";
import serviceTurma from "@/services/turma";
import ConviteTurmaVue from "../turmas/eventoDetalhado/ConviteTurma.vue";
import ConfiguracaoEvento from "../turmas/eventoDetalhado/ConfiguracaoEvento.vue";
import moment from "moment";
import { getList, statusName } from "@/constantes/statusContasAReceber";
import { ListaTiposDespesas, getNames } from "@/constantes/tiposDespesas";
import { URL_APP } from "@/constantes/base";

export default {
  name: "RouterContasAReceber",
  components: {
    ConviteTurmaVue,
    ConfiguracaoEvento
  },
  data() {
    return {
      tabSelect: null,
      tabsMenu: [],
      anosList: [],
      mesesList: [],
      filtros: {
        id: null,
        agenciaId: null,
        status: null,
        tipoDespesa: null,
        turmaId: null,
        dataVencimentoInicio: null,
        dataVencimentoFim: null
      },
      disableTipoDespesa: false,
      listaTipoDespesa: [],
      listaStatusPagamento: [],
      listaRecebimentos: [],
      listaDespesas: [],
      listaDepositos: [],
      configuracaoModal: false,
      loadingAgencia: true,
      visaoGeral: {},
      page: 0,
      pageSize: 30,
      totalPages: 0,
      loadingTab: false,
      agenciaContas: {
        disableNotification: false
      },
      headersDepositos: [
        {
          text: "Vencimento",
          align: "start",
          sortable: true,
          value: "vencimento"
        },
        {
          text: "Valor",
          align: "start",
          sortable: true,
          value: "valor"
        },
        {
          text: "Descrição",
          align: "start",
          sortable: true,
          value: "descricao"
        },
        {
          text: "Tipo Pagamento",
          align: "start",
          sortable: true,
          value: "tipoPagamento"
        },
        {
          text: "Status do Pagamento",
          align: "start",
          sortable: true,
          value: "statusPagamento"
        },
        {
          text: "Ações",
          align: "start",
          sortable: false,
          value: ""
        }
      ],
      headersDespesas: [
        {
          text: "Vencimento",
          align: "start",
          sortable: true,
          value: "vencimento"
        },
        {
          text: "Valor",
          align: "start",
          sortable: true,
          value: "valor"
        },
        {
          text: "Descrição",
          align: "start",
          sortable: true,
          value: "descricao"
        },
        {
          text: "Tipo despesa",
          align: "start",
          sortable: true,
          value: "tipoDespesa"
        },
        {
          text: "Tipo Pagamento",
          align: "start",
          sortable: true,
          value: "tipoPagamento"
        },
        {
          text: "Status do Pagamento",
          align: "start",
          sortable: true,
          value: "statusPagamento"
        },
        {
          text: "Ações",
          align: "start",
          sortable: false,
          value: ""
        }
      ],
      headersRecebimentos: [
        {
          text: "Vencimento",
          align: "start",
          sortable: true,
          value: "vencimento"
        },
        {
          text: "Descrição",
          align: "start",
          sortable: true,
          value: "descricao"
        },
        {
          text: "Valor",
          align: "start",
          sortable: true,
          value: "valor"
        },
        {
          text: "Nome do Participante",
          align: "start",
          sortable: true,
          value: "usuarioNome"
        },
        {
          text: "CPF do Participante",
          align: "start",
          sortable: true,
          value: "usuarioCPF"
        },
        {
          text: "ID do Participante",
          align: "start",
          sortable: true,
          value: "usuarioId"
        },
        {
          text: "Status do Pagamento",
          align: "start",
          sortable: true,
          value: "statusPagamento"
        },
        {
          text: "Ações",
          align: "end",
          sortable: false,
          value: ""
        }
      ]
    };
  },
  computed: {
    getValuePrice() {
      return this.agenciaContas.saldo
        ? Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL"
          }).format(this.agenciaContas.saldo)
        : "R$ 0,00";
    }
  },
  beforeMount() {
    let listaStatus = getList.unshift({
      value: null,
      text: "Todos"
    });
    let listaDesp = ListaTiposDespesas.unshift({
      value: null,
      text: "Todos"
    });
    console.log(listaStatus, listaDesp);
    this.listaStatusPagamento = getList;
    this.listaTipoDespesa = ListaTiposDespesas;
  },
  mounted() {
    this.generateDateFilters();
    this.getAgencia();
  },
  methods: {
    generateDateFilters() {
      this.filtros.dataVencimentoInicio = moment()
        .startOf("month")
        .format("YYYY-MM-DD");
      this.filtros.dataVencimentoFim = moment().format("YYYY-MM-DD");
    },
    redirectCallApi(goto) {
      let goto_tmp = goto ? goto : this.tabSelect;
      if (goto_tmp == "recebimentos") {
        this.disableTipoDespesa = true;
        this.filtros.tipoDespesa = null;
        this.loadTabRecebimentos();
      } else if (goto_tmp == "despesas") {
        this.disableTipoDespesa = false;
        this.loadTabDespesas();
      } else if (goto_tmp == "depositos") {
        this.disableTipoDespesa = true;
        this.filtros.tipoDespesa = null;
        this.loadTabDepositos();
      }
    },
    changePage(value) {
      console.log("changePage", value);
      this.page = value > 0 ? value - 1 : 0;
      this.loadingTab = true;
      this.redirectCallApi();
    },
    renderTipoDespesa(tipo) {
      return getNames[tipo] ? getNames[tipo] : "Undefined";
    },
    renderNameStatus(tipo) {
      return statusName[tipo] ? statusName[tipo] : "Undefined";
    },
    changeTab(goto) {
      console.log("change tab", goto, this.tabSelect);
      this.loadingTab = true;
      this.page = 0;
      this.totalPages = 0;
      this.redirectCallApi(goto);
    },
    loadTabRecebimentos() {
      if (!this.loadingAgencia) {
        const filtros = {
          ...this.filtros
        };
        serviceTurma
          .getRecebimentos(filtros, this.page, this.pageSize)
          .then(resp => {
            console.log(resp);
            this.listaRecebimentos = resp.data.content;
            this.totalPages = resp.data.totalPages;
            this.loadingTab = false;
          })
          .catch(err => {
            console.error(err);
            this.listaRecebimentos = [];
            this.loadingTab = false;
            this.$root.showError(err?.data?.message);
          });
      } else {
        setTimeout(() => {
          this.loadTabRecebimentos();
        }, 1000);
      }
    },
    loadTabDespesas() {
      if (!this.loadingAgencia) {
        const filtros = {
          ...this.filtros
        };
        serviceTurma
          .getDespesas(filtros, this.page, this.pageSize)
          .then(resp => {
            console.log(resp);
            this.listaDespesas = resp.data.content;
            this.totalPages = resp.data.totalPages;
            this.loadingTab = false;
          })
          .catch(err => {
            console.error(err);
            this.listaDespesas = [];
            this.loadingTab = false;
            this.$root.showError(err?.data?.message);
          });
      } else {
        setTimeout(() => {
          this.loadTabRecebimentos();
        }, 1000);
      }
    },
    loadTabDepositos() {
      if (!this.loadingAgencia) {
        const filtros = {
          ...this.filtros
        };
        serviceTurma
          .getDepositos(filtros, this.page, this.pageSize)
          .then(resp => {
            console.log(resp);
            this.listaDepositos = resp.data.content;
            this.totalPages = resp.data.totalPages;
            this.loadingTab = false;
          })
          .catch(err => {
            console.error(err);
            this.listaDepositos = [];
            this.loadingTab = false;
            this.$root.showError(err?.data?.message);
          });
      } else {
        setTimeout(() => {
          this.loadTabRecebimentos();
        }, 1000);
      }
    },
    carregarVisaoGeral() {
      const id = this.agenciaContas.id;
      const ano = moment().year();
      serviceTurma
        .getDadosDashboard(id, ano)
        .then(resp => {
          console.log(resp);
          this.visaoGeral.alunos = resp.data.qtdAlunos;
          this.visaoGeral.arrecadado = resp.data.totalArrecadado;
          this.visaoGeral.totalGasto = resp.data.totalGasto;
          this.visaoGeral.totalDeposito = resp.data.totalDeposito;
          this.visaoGeral.saldoAtual = resp.data.saldoAtual;
        })
        .catch(err => {
          console.error(err);
        });
    },
    formatDate(date) {
      console.log(date);
      return moment(date).format("DD/MM/YYYY");
    },
    getTabSearch() {
      this.loadingTab = true;
      this.redirectCallApi();
    },
    toggleNotification() {
      this.loading = true;
      serviceTurma
        .postDisableNotification(
          this.agenciaContas.id,
          !this.agenciaContas.disableNotification
        )
        .then(resp => {
          this.$root.showSucesso(resp.data);
          this.getAgencia(true);
        })
        .catch(err => {
          this.$root.showError(err);
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getAgencia(isUpdate = false) {
      if (!isUpdate) {
        this.loadingAgencia = true;
      }
      const agencia_id = this.$store.getters["gestao/getAgencia"].agenciaId;
      agenciasService
        .contasAReceber(agencia_id)
        .then(response => {
          console.log(response);
          this.agenciaContas = response.data;
          this.filtros.agenciaId = response.data.agenciaId;
          this.filtros.turmaId = response.data.id;
          this.loadingAgencia = false;
          this.carregarVisaoGeral();
        })
        .catch(err => {
          console.error(err);
        });
    },
    acessoAdmin() {
      const turmaID = this.agenciaContas.id;
      const token = localStorage.getItem("APT-ADMTOKEN");
      window.open(
        `${URL_APP}/redirect-auth?token=${token}&turma=${turmaID}`,
        "_black"
      );
    },
    openConfigModal() {
      this.configuracaoModal = true;
      console.log("openConfigModal");
    },
    closeConfigModal() {
      this.configuracaoModal = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.mainHeader {
  display: grid;
  grid-template-columns: 1fr auto;
}
$breakpoint-mobile: 925px;
.headerDash {
  width: 100%;
  padding: 10px;
}
.headerMenuButtons {
  padding: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: $breakpoint-mobile) {
    border-bottom-left-radius: 0px;
  }
}
.filtros {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  align-content: center;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    grid-template-columns: 1fr;
    text-align: center;
    gap: 10px;
  }
}
.headerBox {
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: $breakpoint-mobile) {
    grid-template-columns: 1fr;
    order: 1;
  }
}
.boxCard {
  margin: 12px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid rgba(200, 200, 200, 0.3);
}
.containerScreen {
  width: 100%;
  height: 100vh;
}
</style>
