<template>
  <div>
    <div class="px-0">
      <v-titulo>Prêmios</v-titulo>
    </div>
    <div class="py-3 px-5">
      <v-row>
        <v-col cols="12" sm="6">
          <v-select
            v-model="concursoSelecionado"
            label="Concurso"
            :items="concursos"
            item-text="nome"
            return-object
            depressed
            class="d-inline"
            :loading="loadingConcursos"
            @change="onChangeConcurso"
          />
        </v-col>
      </v-row>
    </div>
    <div class="px-5">
      <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-1"
        :loading="loadingTable"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.concursoId }}</td>
            <td>{{ momentParseDate(item.dataSorteio) }}</td>
            <td>{{ momentParseDate(item.dataCriacao) }}</td>
            <td>{{ item.descricao }}</td>
            <td>{{ item.numerosSorteados }}</td>
            <td>
              {{ renderComprador(item.bilhetePremiado) }}
            </td>
            <td>
              {{ renderNumeroPremiado(item.bilhetePremiado) }}
            </td>
            <td class="text-right">
              <v-btn
                v-if="!item.vencedor"
                color="primary"
                small
                depressed
                :disabled="item.numerosSorteados"
                @click="novoSorteio(item)"
                >Registrar sorteio</v-btn
              >
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
    <vModalNovoSorteio
      v-if="modalNovoSorteio"
      :payload="modalPayload"
      @update="buscarPremios"
      @close="closeModalNovoSorteio"
    />
  </div>
</template>

<script>
import vModalNovoSorteio from "./vModelNovoPremio.vue";
import serviceConcurso from "@/services/concurso";
import servicePremios from "@/services/premio";
import moment from "moment";
export default {
  components: {
    vModalNovoSorteio
  },
  filters: {
    isNull(value) {
      if (!value) {
        return "-";
      }
      return value;
    }
  },
  data() {
    return {
      modalNovoSorteio: false,
      loadingConcursos: false,
      loadingTable: false,
      concursos: [],
      concursoSelecionado: null,
      headers: [
        {
          text: "Concurso ID",
          value: "concursoId",
          align: "left",
          sortable: false
        },
        {
          text: "Data Sorteio",
          value: "dataSorteio",
          align: "left",
          sortable: false
        },
        {
          text: "Data Criação",
          value: "dataCriacao",
          align: "left",
          sortable: false
        },
        {
          text: "Prêmio",
          value: "premio.descricao",
          align: "left",
          sortable: false
        },
        {
          text: "Número sorteado",
          value: "vencedor.bilhete",
          align: "left",
          sortable: false
        },
        {
          text: "Vencedor",
          value: "vencedor.nome",
          align: "left",
          sortable: false
        },
        {
          text: "Bilhete Vencedor",
          value: "vencedor.bilhete",
          align: "left",
          sortable: false
        },
        {
          text: "Opções",
          value: "",
          align: "right",
          sortable: false
        }
      ],
      items: []
    };
  },
  beforeMount() {
    this.getConcursos();
  },
  methods: {
    renderNumeroPremiado(bilhetePremiado) {
      if (bilhetePremiado && bilhetePremiado.numero) {
        return bilhetePremiado.numero;
      }
      return "-";
    },
    renderComprador(bilhetePremiado) {
      if (
        bilhetePremiado &&
        bilhetePremiado.comprador &&
        bilhetePremiado.comprador.nome
      ) {
        return bilhetePremiado.comprador.nome;
      }
      return "-";
    },
    momentParseDate(value) {
      if (value === null) return "";
      return moment(value).format("DD/MM/YYYY");
    },
    onChangeConcurso(value) {
      console.log(value);
      this.concursoSelecionado = { ...value };
      this.buscarPremios();
    },
    buscarPremios() {
      this.loadingTable = true;
      servicePremios
        .premiosPorConcurso(this.concursoSelecionado.id)
        .then(response => {
          this.items = response.data;
        })
        .finally(() => {
          this.loadingTable = false;
        })
        .catch(err => {
          this.$root.showError(err);
          console.error(err);
        });
    },
    getConcursos() {
      this.loadingConcursos = true;
      serviceConcurso
        .getConcursos()
        .then(response => {
          this.concursos = response.data;
          this.concursoSelecionado = this.concursos[0];
          this.buscarPremios();
        })
        .finally(() => {
          this.loadingConcursos = false;
        })
        .catch(err => {
          console.error(err);
        });
    },
    getVencedorBilhete(vencedor) {
      if (vencedor && vencedor.bilhete) {
        return vencedor.bilhete;
      } else {
        return "-";
      }
    },
    getVencedorNome(vencedor) {
      if (vencedor && vencedor.nome) {
        return vencedor.nome;
      } else {
        return "-";
      }
    },
    novoSorteio(item) {
      this.modalPayload = item;
      this.modalNovoSorteio = true;
    },
    closeModalNovoSorteio() {
      this.modalNovoSorteio = false;
      this.modalPayload = null;
    }
  }
};
</script>
