<template>
  <v-dialog v-model="modelDialog" persistent max-width="600px">
    <v-card class="pa-4">
      <div
        class="text-h6 font-weight-medium py-4 primaryDash--text text-center"
      >
        Selecione seu plano
      </div>

      <div v-if="pacotes.length > 0">
        <div class="d-flex flex-wrap justify-center align-top pa-2">
          <v-card
            v-for="(pac, index) in getListPackages"
            :key="index"
            class="
            d-flex
            flex-column
            flex-wrap
            itemCardPacote
            ma-2
            primaryDash--text
            text-center
          "
            :class="{ selecionado: pac.id == pacoteSelecionado }"
          >
            <div
              class="itemHeader"
              :style="{
                backgroundColor: pac.color
              }"
            >
              {{ pac.titulo }}
            </div>
            <div class="itemBody">
              <div class="itemDescricao">
                {{ pac.descricao }}
              </div>
              <div
                v-if="pac.diasCarencia && pac.diasCarencia > 0"
                class="itemCarencia"
              >
                <div class="itemCarenciaLabel">
                  Assine agora e ganhe os primeiros {{ pac.diasCarencia }} dias
                  grátis!
                </div>
              </div>
              <div class="itemContentTipoPag ">
                <div class="itemValor">
                  {{ pac.valor | currency }}
                </div>
                <div
                  v-if="pac.tipoCobranca === 'MENSAL'"
                  class="itemTipoPagamento"
                >
                  por aluno mês
                </div>
                <div
                  v-if="pac.tipoCobranca === 'POR_TRANSACAO'"
                  class="itemTipoPagamento"
                >
                  por cada pagamento
                </div>
              </div>
            </div>
            <div class="btnSelectArea">
              <v-btn
                :color="pac.color"
                class="white--text"
                depressed
                @click="selecionaPacote(pac.id)"
                >Selecionar</v-btn
              >
            </div>
          </v-card>
        </div>
        <div class="text-center">
          <div
            class="red--text text--darken-1 text-center font-weight-medium blockWarning"
          >
            Atenção: não será possível alterar o pacote da turma.
          </div>
        </div>
        <div id="cadastrar" class="text-center mt-5">
          <v-btn
            color="primary"
            large
            depressed
            :disabled="!pacoteSelecionado"
            style="width: 70%"
            @click="assinarPacote()"
            >Assinar</v-btn
          >
        </div>
      </div>
      <div v-else class="text-center py-4 px-2 grey--text text--darken-2">
        <div class="text-center pa-2">Carregando pacotes...</div>
        <div>
          <v-progress-circular indeterminate></v-progress-circular>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import servicePacotes from "@/services/pacotes";

export default {
  props: {
    turmaId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      pacoteSelecionado: null,
      modelDialog: true,
      pacotes: []
    };
  },
  computed: {
    getListPackages() {
      return this.pacotes.filter(item => {
        if (item.ativo) {
          return true;
        } else {
          return false;
        }
      });
    }
  },
  mounted() {
    this.getPacotes();
  },
  methods: {
    selecionaPacote(id) {
      this.pacoteSelecionado = id;
    },
    assinarPacote() {
      let pacoteId = this.pacoteSelecionado;
      let turmaId = this.turmaId;
      servicePacotes
        .definePacoteParaTurma(pacoteId, turmaId)
        .then(resp => {
          this.$root.showInfo(resp.data);
          this.$emit("confirm");
        })
        .catch(err => {
          console.log(err.data);
          this.$root.showError(err.data);
        });
    },
    getPacotes() {
      servicePacotes.getPacotes().then(resp => {
        let pacs = resp.data;
        this.pacotes = pacs;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.blockWarning {
  background-color: rgba(150, 0, 0, 0.1);
  display: inline-block;
  padding: 10px 20px;
  margin: 0 auto;
  border-radius: 6px;
}
.itemCarenciaLabel {
  font-size: 13px;
  color: black;
  font-weight: 600;
  padding: 0px 15px;
}
.itemCarencia {
  padding: 20px 0px;
}
.itemBody {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}
.btnSelectArea {
  padding: 20px 0px;
  text-align: center;
  flex: 0;
}
.itemHeader {
  border-radius: 6px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  flex: 0;
  color: white;
  font-weight: 300;
  padding: 10px 5px;
}
.itemValor {
  font-size: 18px;
  font-weight: 500;
}
.itemTipoPagamento {
  color: rgba(130, 130, 150, 1);
}
.itemDescricao {
  flex: 1;
  padding: 8px;
  padding-left: 14px;
  padding-right: 14px;
  margin-top: 4px;
  font-size: 14px;
  text-align: center;
  color: rgba(70, 70, 100, 1);
  font-weight: 300;
}
.itemContentTipoPag {
  color: rgb(30, 30, 30);
  flex: 0;
  font-size: 12px;
}
.itemCardPacote {
  min-width: 250px;
  max-width: 250px;
  min-height: 150px;
  border-radius: 6px;
  transition: 0.1s all;
  box-sizing: content-box;
  background-color: white;
  box-shadow: 0px 1px 4px #64748b !important;
  &.selecionado {
    outline: 4px solid rgba(0, 80, 177, 0.5) !important;
  }
}
</style>
